import { gql } from "apollo-angular";

export const Queries = {
  getProjects: gql`
    query getProjects(
      $limit: Int
      $offset: Int
      $where: task_manager_projects_bool_exp! = {}
    ) {
      task_manager_projects(
        limit: $limit
        offset: $offset
        where: $where
        order_by: { updated_at: desc }
      ) {
        IsRejected
        client_id
        created_at
        isAproved
        type
        id
        production_release
        title
        priority
        status
        end_date
        description
        lead: mailing_lists(where: { member_role: { _eq: "lead" } }) {
          user {
            name
          }
        }
        team: mailing_lists(distinct_on: user_id) {
          user {
            name
            profile_pic_url
            external_id
            role
            client_id
          }
        }
        completedTasks: tasks_aggregate(where: { status: { _eq: Completed } }) {
          aggregate {
            count
          }
        }
        activeTasks: tasks_aggregate(where: { status: { _eq: active } }) {
          aggregate {
            count
          }
        }
        allTasks: tasks_aggregate {
          aggregate {
            count
          }
        }
        openTasks: tasks_aggregate(
          where: { status: { _nin: [Completed, IssueNotFound, OnHold] } }
        ) {
          aggregate {
            count
          }
        }
        user {
          name
        }
        user_id
        updated_at
        openTasks: tasks_aggregate(
          where: { status: { _nin: [Completed, IssueNotFound, OnHold] } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,

  getProjectsCounts: gql`
    query getProjectsCounts($spaceId: uuid) {
      activeProjects: task_manager_projects_aggregate(
        where: { status: { _eq: active }, space_id: { _eq: $spaceId } }
      ) {
        aggregate {
          count
        }
      }
      notStartedProjects: task_manager_projects_aggregate(
        where: { status: { _eq: not_started }, space_id: { _eq: $spaceId } }
      ) {
        aggregate {
          count
        }
      }
      pendingProjects: task_manager_projects_aggregate(
        where: { status: { _eq: pending }, space_id: { _eq: $spaceId } }
      ) {
        aggregate {
          count
        }
      }
      completedProjects: task_manager_projects_aggregate(
        where: { status: { _eq: completed }, space_id: { _eq: $spaceId } }
      ) {
        aggregate {
          count
        }
      }
    }
  `,

  getEmployees: gql`
    query getEmployees(
      $limit: Int
      $offset: Int
      $where: task_manager_users_bool_exp! = {}
    ) {
      task_manager_users(
        limit: $limit
        offset: $offset
        where: $where
        order_by: { employee_id: asc }
      ) {
        address
        blood_group
        education
        department
        designation
        email
        joined_at
        phone_number
        name
        external_id
        employee_id
        is_active
      }
      totalEmployees: task_manager_users_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getClients: gql`
    query getClients(
      $where: task_manager_clients_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_clients(
        limit: $limit
        offset: $offset
        order_by: { created_at: desc }
        where: $where
      ) {
        created_at
        id
        isActive
        isDeleted
        name
      }
      task_manager_clients_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getUsers: gql`
    query getUsers($where: task_manager_users_bool_exp = {}) {
      task_manager_users(where: $where) {
        external_id
        name
        lname
      }
    }
  `,
  getUser: gql`
    query getUser($userId: String) {
      task_manager_users(where: { external_id: { _eq: $userId } }) {
        address
        blood_group
        education
        department
        designation
        email
        joined_at
        phone_number
        name
        external_id
        client_id
        date_of_birth
        role
        is_active
        password
        employee_id
        employee_role
        lname
        profile_pic_url
        extension_limit
      }
      task_manager_documents(where: { user_id: { _eq: $userId } }) {
        title
        id
        type
        created_at
        url
      }
    }
  `,
  getProjectTypes: gql`
    query getProjectTypes(
      $where: task_manager_project_type_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_project_type(limit: $limit, offset: $offset, where: $where) {
        type
        name
      }
      count: task_manager_project_type_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
  getClientUsers: gql`
    query getClientUsers(
      $where: task_manager_users_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_users(where: $where, limit: $limit, offset: $offset) {
        email
        phone_number
        name
        is_active
        external_id
        client {
          name
          id
        }
      }
      task_manager_users_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getBreak: gql`
    query getBreak($where: task_manager_break_logs_bool_exp = {}) {
      task_manager_break_logs(where: $where, order_by: { start: desc }) {
        on_break
        start
        stop
        created_at
        attendance_id
        user_id
      }
    }
  `,

  fetchAttendanceData: gql`
    query fetchAttendanceData(
      $where: task_manager_attendance_bool_exp = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_attendance(
        limit: $limit
        offset: $offset
        order_by: { created_at: desc }
        where: $where
      ) {
        id
        created_at
        clock_in
        clock_out
        status
        location
      }
      task_manager_attendance_aggregate(where: $where) {
        aggregate {
          count
        }
      }
      firstDate: task_manager_attendance(
        where: $where
        limit: 1
        order_by: { clock_in: asc }
      ) {
        clock_in
      }
    }
  `,

  fetchAttendanceWithUserData: gql`
    query fetchAttendanceWithUserData(
      $user: task_manager_users_bool_exp! = {}
      $attendance: task_manager_attendance_bool_exp! = {}
      $leave: task_manager_leaves_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_users(
        where: $user
        order_by: { name: asc }
        limit: $limit
        offset: $offset
      ) {
        lname
        name
        external_id
        profile_pic_url
        employee_id
        department
        email
        designation
        attendanceData: attendances(where: $attendance) {
          created_at
          clock_in
          clock_out
          location
          user_id
          status
          break_logs {
            on_break
            start
            stop
          }
        }
        leavesData: leaves(where: $leave) {
          from
          to
          type
        }
      }
      usersCount: task_manager_users_aggregate(where: $user) {
        aggregate {
          count
        }
      }
    }
  `,

  getAttendanceStatus: gql`
    query getAttendanceStatus {
      task_manager_attendance_status {
        status
      }
    }
  `,

  fetchEmployeeLeavesCount: gql`
    query fetchEmployeeLeavesCount($user_id: String!) {
      task_manager_employee_leaves(where: { user_id: { _eq: $user_id } }) {
        vacation
        sick_leave
        maternity_leave
        loss_of_pay
        comp_leave
        casual_leave
      }
    }
  `,
  getPins: gql`
    query getPins($userId: String, $projectId: uuid) {
      task_manager_project_pin_mapping(
        where: { user_id: { _eq: $userId }, project_id: { _eq: $projectId } }
      ) {
        id
        project_id
      }
      pinnedCount: task_manager_project_pin_mapping_aggregate(
        where: { user_id: { _eq: $userId } }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  getLeaves: gql`
    query getLeaves(
      $limit: Int
      $offset: Int
      $where: task_manager_leaves_bool_exp = {}
    ) {
      task_manager_leaves(
        limit: $limit
        offset: $offset
        where: $where
        order_by: { created_at: desc }
      ) {
        created_at
        from
        days
        id
        reason
        status
        to
        type
        half_day
        attendance_status {
          title
          status
        }
        user {
          name
          lname
          designation
          external_id
          profile_pic_url
        }
      }
      task_manager_leaves_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getLeavesTakenCount: gql`
    query leavesTaken(
      $user_id: String
      $first_day: date
      $last_day: date
      $first_day_month: date
      $last_day_month: date
    ) {
      casualLeaves: task_manager_leaves_aggregate(
        where: {
          user_id: { _eq: $user_id }
          status: { _eq: "approved" }
          type: { _eq: CASUAL_LEAVE }
          from: { _gte: $first_day }
          to: { _lte: $last_day }
        }
      ) {
        aggregate {
          sum {
            days
          }
        }
      }
      compLeaves: task_manager_leaves_aggregate(
        where: {
          user_id: { _eq: $user_id }
          status: { _eq: "approved" }
          type: { _eq: COMP_LEAVE }
          from: { _gte: $first_day }
          to: { _lte: $last_day }
        }
      ) {
        aggregate {
          sum {
            days
          }
        }
      }
      loa: task_manager_leaves_aggregate(
        where: {
          user_id: { _eq: $user_id }
          status: { _eq: "approved" }
          type: { _eq: LOA }
          from: { _gte: $first_day }
          to: { _lte: $last_day }
        }
      ) {
        aggregate {
          sum {
            days
          }
        }
      }
      maternityLeaves: task_manager_leaves_aggregate(
        where: {
          user_id: { _eq: $user_id }
          status: { _eq: "approved" }
          type: { _eq: MATERNITY_LEAVE }
          from: { _gte: $first_day }
          to: { _lte: $last_day }
        }
      ) {
        aggregate {
          sum {
            days
          }
        }
      }
      paternityLeaves: task_manager_leaves_aggregate(
        where: {
          user_id: { _eq: $user_id }
          status: { _eq: "approved" }
          type: { _eq: PATERNITY_LEAVE }
          from: { _gte: $first_day }
          to: { _lte: $last_day }
        }
      ) {
        aggregate {
          sum {
            days
          }
        }
      }
      sickLeaves: task_manager_leaves_aggregate(
        where: {
          user_id: { _eq: $user_id }
          status: { _eq: "approved" }
          type: { _eq: SICK_LEAVE }
          from: { _gte: $first_day }
          to: { _lte: $last_day }
        }
      ) {
        aggregate {
          sum {
            days
          }
        }
      }
      wfh: task_manager_leaves_aggregate(
        where: {
          user_id: { _eq: $user_id }
          status: { _eq: "approved" }
          type: { _eq: WFH }
          from: { _gte: $first_day_month }
          to: { _lte: $last_day_month }
        }
      ) {
        aggregate {
          sum {
            days
          }
        }
      }
      vacations: task_manager_leaves_aggregate(
        where: {
          user_id: { _eq: $user_id }
          status: { _eq: "approved" }
          type: { _eq: VACATION }
          from: { _gte: $first_day }
          to: { _lte: $last_day }
        }
      ) {
        aggregate {
          sum {
            days
          }
        }
      }
    }
  `,
  getAllProjects: gql`
    query getAllProjects(
      $limit: Int
      $offset: Int
      $where: task_manager_projects_bool_exp! = {}
    ) {
      task_manager_projects(
        limit: $limit
        offset: $offset
        where: $where
        order_by: { created_at: desc }
      ) {
        title
        end_date
        created_at
        description

        client {
          name
        }
        id
        start_date
        status
        production_release
        priority
        type
        updated_at
        completedTasks: tasks_aggregate(where: { status: { _eq: Completed } }) {
          aggregate {
            count
          }
        }
        allTasks: tasks_aggregate {
          aggregate {
            count
          }
        }
        openTasks: tasks_aggregate(
          where: { status: { _nin: [Completed, IssueNotFound, OnHold] } }
        ) {
          aggregate {
            count
          }
        }
        team: mailing_lists(distinct_on: user_id) {
          user {
            name
            profile_pic_url
            external_id
            role
            client_id
          }
        }
        lead: mailing_lists(where: { member_role: { _eq: "lead" } }) {
          user {
            name
          }
        }
      }
      task_manager_projects_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getProjectDetails: gql`
    query getProject($projectId: uuid) {
      task_manager_projects(where: { id: { _eq: $projectId } }) {
        id
        title
        status
        updated_at
        created_at
        description
        type
        priority
        user_id
        end_date
        start_date
        client_id
        is_active
        tasks(
          limit: 10
          order_by: { updated_at: desc }
          where: {
            status: {
              _nin: ["Completed", "NotDoable", "OnHold", "IssueNotFound"]
            }
          }
        ) {
          assignee {
            name
          }
          id
          status

          tasks_status_setting {
            color
            icon
            status_settings_status {
              name
              status
            }
          }

          title
          due_date
        }
        mailing_lists(distinct_on: user_id) {
          member_role
          user_id
          user {
            name
            profile_pic_url
            external_id
            role
            client_id
          }
        }
        project_files {
          id
          file_name
          type
          url
        }
        user {
          name
        }
        client {
          name
          projects_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      task_manager_comments(
        where: { task: { project_id: { _eq: $projectId } } }
        limit: 5
        order_by: { created_at: asc }
      ) {
        comment
        task {
          title
          status
          tasks_status_setting {
            status_settings_status {
              name
            }
            status
          }
          updated_at
        }
      }
      task_manager_comments_aggregate(
        where: { task: { project_id: { _eq: $projectId } } }
      ) {
        aggregate {
          count
        }
      }
      completedTasks: task_manager_tasks_aggregate(
        where: {
          project: { id: { _eq: $projectId } }
          status: { _eq: Completed }
        }
      ) {
        aggregate {
          count
        }
      }
      totalTasks: task_manager_tasks_aggregate(
        where: { project: { id: { _eq: $projectId } } }
      ) {
        aggregate {
          count
        }
      }
    }
  `,

  getProject: gql`
    query getProject($projectId: uuid) {
      task_manager_projects(where: { id: { _eq: $projectId } }) {
        tasks {
          assigned_to
          status
          title
          created_at
          due_date
          assignee {
            name
          }
        }
        created_at
        description
        end_date
        id
        isAproved
        IsRejected
        priority
        production_release
        start_date
        status
        project_files {
          file_name
          id
          type
        }
        team: mailing_lists(distinct_on: user_id) {
          user {
            name
            profile_pic_url
          }
        }
        completed: tasks_aggregate(where: { status: { _eq: Completed } }) {
          aggregate {
            count
          }
        }
        active: tasks_aggregate(where: { status: { _eq: InProgress } }) {
          aggregate {
            count
          }
        }
        notStarted: tasks_aggregate(where: { status: { _eq: NotYetStarted } }) {
          aggregate {
            count
          }
        }
        onHold: tasks_aggregate(where: { status: { _eq: OnHold } }) {
          aggregate {
            count
          }
        }
        tasks_aggregate {
          aggregate {
            count
          }
        }
        title
        type
        updated_at
        user {
          name
        }
      }
    }
  `,
  getClientCounts: gql`
    query getClientCounts($clientId: uuid, $userId: String) {
      running_projects: task_manager_projects_aggregate(
        where: {
          status: { _eq: active }
          client_id: { _eq: $clientId }
          is_active: { _eq: true }
        }
      ) {
        aggregate {
          count
        }
      }
      spacesCount: task_manager_spaces_aggregate(
        where: { space_members: { user_id: { _eq: $userId } } }
      ) {
        aggregate {
          count
        }
      }

      totalTasks: task_manager_tasks_aggregate(
        where: {
          project: { client_id: { _eq: $clientId }, is_active: { _eq: true } }
        }
      ) {
        aggregate {
          count
        }
      }

      completed: task_manager_tasks_aggregate(
        where: {
          status: { _eq: Completed }
          project: { client_id: { _eq: $clientId }, is_active: { _eq: true } }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  getClientTasks: gql`
    query getClientTasks(
      $where: task_manager_tasks_bool_exp! = {}
      $filter: task_manager_filters_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_tasks(
        where: $where
        limit: $limit
        offset: $offset
        order_by: { updated_at: desc }
      ) {
        assigned_to
        created_at
        project_id
        status
        title
        updated_at
        priority
        id
        due_date
        description
        is_closed
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        assignee {
          name
        }
        user {
          name
        }
        project {
          title
          id
        }
      }

      task_manager_filters(where: $filter) {
        action
        assigned_to
        client_id
        is_closed
        name
        priority
        project_id
        status
        team_id
        title
        id
      }
      totalTasks: task_manager_tasks_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getProjectList: gql`
    query getProjectList($clientId: uuid) {
      task_manager_projects(where: { client_id: { _eq: $clientId } }) {
        id
        title
      }
    }
  `,

  getTaskForDasboardPin: gql`
    query getTaskForDasboardPin($where: task_manager_tasks_bool_exp! = {}) {
      task_manager_tasks(where: $where) {
        id
        title
        status

        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }
        project {
          client {
            name
          }
        }

        assignee {
          lname
          name
        }
      }
    }
  `,

  getTask: gql`
    query getTask($taskId: uuid, $userId: String) {
      task_manager_tasks(
        where: { id: { _eq: $taskId } }
        order_by: { updated_at: desc }
      ) {
        assigned_to
        billable_time
        created_at
        description
        due_date
        estimated_date
        updated_at
        link
        priority
        assigned_time
        external_id
        action
        is_closed
        type
        recurred_at
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        task_files {
          file_name
          id
          type
        }
        team_id
        team {
          team_members(where: { user_id: { _eq: $userId } }) {
            user_id
            user {
              external_id
            }
          }
        }
        assignee {
          name
          external_id
        }
        project {
          title
          client_id
          type
          mailing_lists {
            user {
              external_id
            }
          }
        }
        status
        title
        user {
          name
          external_id
        }
        commentCount: commentsByTaskId_aggregate {
          aggregate {
            count
          }
        }
        comments: commentsByTaskId(order_by: { created_at: desc }) {
          comment
          client_visibility
          user {
            name
          }
          created_at
          url
          files(order_by: { created_at: desc }) {
            id
            title
            type
            url
          }
        }
        task_logs(
          where: { task_id: { _eq: $taskId } }
          order_by: { start: asc }
        ) {
          end
          is_working
          start
          is_manual_entry
        }

        activity_logs(order_by: { created_at: desc }) {
          activity_name
          created_at
          user {
            name
            external_id
            profile_pic_url
          }
        }
      }
    }
  `,

  getClientTask: gql`
    query getClientTask($taskId: uuid, $userId: String) {
      task_manager_tasks(
        where: { id: { _eq: $taskId } }
        order_by: { updated_at: desc }
      ) {
        assigned_to
        billable_time
        created_at
        description
        due_date
        estimated_date
        updated_at
        link
        priority
        assigned_time
        external_id
        action
        is_closed
        type
        recurred_at
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        task_files {
          file_name
          id
          type
        }
        team_id
        team {
          team_members(where: { user_id: { _eq: $userId } }) {
            user_id
            user {
              external_id
            }
          }
        }
        assignee {
          name
          external_id
        }
        project {
          title
          client_id
          type
          mailing_lists {
            user {
              external_id
            }
          }
        }
        status
        title
        user {
          name
          external_id
        }
        commentCount: commentsByTaskId_aggregate {
          aggregate {
            count
          }
        }
        comments: commentsByTaskId(
          where: { client_visibility: { _eq: true } }
          order_by: { created_at: desc }
        ) {
          comment
          user {
            name
          }
          created_at
          client_visibility
          url
          files(order_by: { created_at: desc }) {
            id
            title
            type
            url
          }
        }
        task_logs(
          where: { task_id: { _eq: $taskId } }
          order_by: { start: asc }
        ) {
          end
          is_working
          start
          is_manual_entry
        }

        activity_logs(order_by: { created_at: desc }) {
          activity_name
          created_at
          user {
            name
            external_id
            profile_pic_url
          }
        }
      }
    }
  `,
  getEmployeeProjects: gql`
    query getEmployeeProjects(
      $limit: Int
      $offset: Int
      $where: task_manager_projects_bool_exp! = {}
    ) {
      task_manager_projects(limit: $limit, offset: $offset, where: $where) {
        client {
          name
        }
        title
        created_at
        end_date
        start_date
        priority
        status
        isAproved
        IsRejected
        id
        createdBy: user {
          name
        }
        totalTask: tasks_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,

  getSpaceProjects: gql`
    query getSpaceProjects(
      $limit: Int
      $offset: Int
      $where: task_manager_projects_bool_exp! = {}
    ) {
      task_manager_projects(limit: $limit, offset: $offset, where: $where) {
        client {
          name
        }
        client_id
        title
        created_at
        end_date
        start_date
        priority
        status
        isAproved
        IsRejected
        id
        createdBy: user {
          name
        }
        totalTask: tasks_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,

  getMyTasks: gql`
    query getMyTasks(
      $where: task_manager_tasks_bool_exp! = {}
      $filter: task_manager_filters_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_tasks(
        where: $where
        order_by: { updated_at: desc }
        limit: $limit
        offset: $offset
      ) {
        status
        action
        title
        updated_at
        due_date
        id
        priority
        created_at
        description
        project {
          title
          id
          client {
            name
          }
          type
        }
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        createdBy: user {
          name
        }
      }
      filters: task_manager_filters(where: $filter) {
        action
        assigned_to
        client_id
        is_closed
        name
        priority
        project_id
        status
        team_id
        title
        id
      }
      totalTasks: task_manager_tasks_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
  getClientDashboard: gql`
    query getClientDashboard(
      $where: task_manager_project_pin_mapping_bool_exp! = {}
      $cardData: task_manager_dashboard_bool_exp! = {}
      $tableData: task_manager_dashboard_bool_exp! = {}
      $projects: task_manager_projects_bool_exp! = {}
      $myTasks: task_manager_tasks_bool_exp! = {}
      $onHold: task_manager_tasks_bool_exp! = {}
      $closedTasks: task_manager_tasks_bool_exp! = {}
    ) {
      task_manager_project_pin_mapping(where: $where) {
        project_id
        project {
          title
        }
      }
      cardData: task_manager_dashboard(where: $cardData) {
        color
        created_by
        filter_id
        id
        title
        type
        dashboard_filter {
          id
          name
          status
          title
          team_id
          user_id
          project_id
          priority
          is_pinned_to_dashboard
          is_closed
          client_id
          assigned_to
          action
        }
      }

      tableData: task_manager_dashboard(where: $tableData) {
        color
        created_by
        filter_id
        id
        title
        type
        dashboard_filter {
          id
          name
          status
          title
          team_id
          user_id
          project_id
          priority
          is_pinned_to_dashboard
          is_closed
          client_id
          assigned_to
          action
        }
      }

      projects: task_manager_projects(
        order_by: { updated_at: desc }
        where: $projects
      ) {
        id
        title
        priority

        openTasks: tasks_aggregate(
          where: {
            status: {
              _nin: [Completed, DateExceeded, IssueNotFound, NotDoable, OnHold]
            }
          }
        ) {
          aggregate {
            count
          }
        }

        completedTasks: tasks_aggregate(where: { status: { _eq: Completed } }) {
          aggregate {
            count
          }
        }
      }

      myTasks: task_manager_tasks(
        where: $myTasks
        order_by: { updated_at: desc }
      ) {
        id
        title
        status
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }
        project {
          id
          title
          client {
            name
          }
        }
        assignee {
          name
          lname
        }
      }
      onHold: task_manager_tasks(
        where: $onHold
        order_by: { updated_at: desc }
      ) {
        id
        title
        status
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }
        project {
          id
          title
          client {
            name
          }
        }
        assignee {
          name
          lname
        }
      }
      closedTasks: task_manager_tasks(
        where: $closedTasks
        order_by: { updated_at: desc }
      ) {
        id
        title
        status
        action
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }
        project {
          id
          title
          client {
            name
          }
        }
        assignee {
          name
          lname
        }
      }
    }
  `,
  getPinnedProjectData: gql`
    query getPinnedProjects($projectId: uuid!) {
      Completed: task_manager_tasks_aggregate(
        where: { status: { _eq: Completed }, project_id: { _eq: $projectId } }
      ) {
        aggregate {
          count
        }
      }
      NotYetStarted: task_manager_tasks_aggregate(
        where: {
          status: { _eq: NotYetStarted }
          project_id: { _eq: $projectId }
        }
      ) {
        aggregate {
          count
        }
      }
      InProgress: task_manager_tasks_aggregate(
        where: { status: { _eq: InProgress }, project_id: { _eq: $projectId } }
      ) {
        aggregate {
          count
        }
      }
      OnHold: task_manager_tasks_aggregate(
        where: { status: { _eq: OnHold }, project_id: { _eq: $projectId } }
      ) {
        aggregate {
          count
        }
      }
      IssueNotFound: task_manager_tasks_aggregate(
        where: {
          status: { _eq: IssueNotFound }
          project_id: { _eq: $projectId }
        }
      ) {
        aggregate {
          count
        }
      }
      DateExceeded: task_manager_tasks_aggregate(
        where: {
          status: { _eq: DateExceeded }
          project_id: { _eq: $projectId }
        }
      ) {
        aggregate {
          count
        }
      }
      NotDoable: task_manager_tasks_aggregate(
        where: { status: { _eq: NotDoable }, project_id: { _eq: $projectId } }
      ) {
        aggregate {
          count
        }
      }
    }
  `,

  getEmployeeRoles: gql`
    query getEmployeeRoles {
      task_manager_employee_role {
        role
        employee_role
      }
    }
  `,

  getAdminTasks: gql`
    query getAdminTasks(
      $where: task_manager_tasks_bool_exp! = {}
      $filter: task_manager_filters_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_tasks(
        where: $where
        limit: $limit
        offset: $offset
        order_by: { updated_at: desc }
      ) {
        id
        title
        action
        updated_at
        due_date
        project {
          client {
            name
          }
          id
          title
          project_type {
            name
          }
        }
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        status
        priority
        assignee {
          name
          profile_pic_url
          external_id
        }
      }
      filters: task_manager_filters(where: $filter) {
        action
        assigned_to
        client_id
        is_closed
        name
        priority
        project_id
        status
        team_id
        title
        id
      }
      totalTasks: task_manager_tasks_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
  getAdminTask: gql`
    query getAdminTask($where: task_manager_tasks_bool_exp! = {}) {
      task_manager_tasks(where: $where) {
        updated_at
        title
        status
        action
        due_date
        priority
        created_at
        description
        link
        is_closed
        is_billable
        billable_time
        estimated_date
        project_id
        assigned_time
        type
        recurred_at
        type
        ticket_id
        task_files {
          file_name
          id
          type
          url
        }
        user {
          name
          external_id
        }

        project {
          title
          type
        }
        team {
          team_members {
            user {
              external_id
            }
          }
        }
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        commentsCount: commentsByTaskId_aggregate {
          aggregate {
            count
          }
        }
        comments: commentsByTaskId(order_by: { created_at: desc }) {
          user {
            name
            external_id
            profile_pic_url
          }
          comment
          created_at
          client_visibility
          url
          files(order_by: { created_at: desc }) {
            id
            title
            type
            url
          }
        }
        assignedTo: assignee {
          external_id
          name
        }
        task_logs(order_by: { start: asc }) {
          end
          start
          is_working
          user {
            external_id
            name
          }
        }

        activity_logs(order_by: { created_at: desc }) {
          activity_name
          created_at
          user {
            name
            external_id
            profile_pic_url
          }
        }
      }
    }
  `,
  getTaskStatus: gql`
    query getTaskStatus($where: task_manager_status_bool_exp! = {}) {
      task_manager_status(where: $where) {
        status
        name
      }
    }
  `,

  listOfProjects: gql`
    query listOfProjects($where: task_manager_projects_bool_exp! = {}) {
      task_manager_projects(where: $where, order_by: { created_at: desc }) {
        id
        title
        type
      }
    }
  `,

  getAdminDashboard: gql`
    query getAdminDashboard(
      $where: task_manager_attendance_bool_exp! = {}
      $myTasks: task_manager_tasks_bool_exp! = {}
      $overDue: task_manager_tasks_bool_exp! = {}
      $cardData: task_manager_dashboard_bool_exp! = {}
      $tableData: task_manager_dashboard_bool_exp! = {}
      $chartData: task_manager_dashboard_bool_exp! = {}
    ) {
      projects: task_manager_projects_aggregate(
        where: { is_active: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }

      clients: task_manager_clients_aggregate(
        where: { isActive: { _eq: true }, isDeleted: { _eq: false } }
      ) {
        aggregate {
          count
        }
      }

      employees: task_manager_users_aggregate(
        where: {
          is_active: { _eq: true }
          role: { _in: [BEETLER, PROJECT_MANAGER, ADMIN, TEAM_LEAD] }
        }
      ) {
        aggregate {
          count
        }
      }

      todaysAttendance: task_manager_attendance_aggregate(where: $where) {
        aggregate {
          count
        }
      }

      task_manager_leaves(
        order_by: { created_at: desc }
        limit: 5
        where: { status: { _eq: "pending" } }
      ) {
        user {
          name
          lname
        }
        type
        from
        to
        status
      }

      projectsList: task_manager_projects(
        limit: 5
        order_by: { updated_at: desc }
      ) {
        id
        title
        mailing_lists_aggregate {
          aggregate {
            count
          }
        }

        mailing_lists(where: { member_role: { _eq: "lead" } }) {
          user {
            name
            lname
          }
        }
        priority

        openTasks: tasks_aggregate(
          where: {
            status: {
              _nin: [Completed, DateExceeded, IssueNotFound, NotDoable]
            }
          }
        ) {
          aggregate {
            count
          }
        }

        completedTasks: tasks_aggregate(where: { status: { _eq: Completed } }) {
          aggregate {
            count
          }
        }
      }

      myTasks: task_manager_tasks(
        order_by: { created_at: desc }
        where: $myTasks
      ) {
        id
        title
        status
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }
        project {
          id
          title
          client {
            name
          }
        }
        assignee {
          name
          lname
        }
      }

      overdueTasks: task_manager_tasks(
        order_by: { created_at: desc }
        where: $overDue
      ) {
        id
        title
        status
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        project {
          id
          title
          client {
            name
          }
        }

        assignee {
          name
          lname
        }
      }

      taskRequests: task_manager_task_requests(
        where: { status: { _eq: "pending" } }
        order_by: { created_at: desc }
      ) {
        id
        requester {
          name
          lname
        }
        status
        task {
          id
          assigned_time
          title
          due_date
          project {
            title
          }
        }
        request
      }

      cardData: task_manager_dashboard(
        where: $cardData
        order_by: { created_at: desc }
      ) {
        color
        created_by
        filter_id
        id
        title
        type
        icon
        dashboard_filter {
          id
          name
          status
          title
          team_id
          user_id
          project_id
          priority
          is_pinned_to_dashboard
          is_closed
          client_id
          assigned_to
          action
        }
      }

      tableData: task_manager_dashboard(
        where: $tableData
        order_by: { created_at: desc }
      ) {
        color
        created_by
        filter_id
        id
        title
        type
        dashboard_filter {
          id
          name
          status
          title
          team_id
          user_id
          project_id
          priority
          is_pinned_to_dashboard
          is_closed
          client_id
          assigned_to
          action
        }
      }
      chartData: task_manager_dashboard(where: $chartData) {
        color
        created_by
        filter_id
        id
        title
        type
        chart_user_id
        dashboard_user {
          name
        }
      }
    }
  `,

  getEmployeeDashboard: gql`
    query getEmployeeDashboard(
      $where: task_manager_attendance_bool_exp = {}
      $filters: task_manager_filters_bool_exp! = {}
      $myTasks: task_manager_tasks_bool_exp! = {}
      $myTeams: task_manager_teams_bool_exp! = {}
      $overdueTasks: task_manager_tasks_bool_exp! = {}
      $projects: task_manager_projects_bool_exp! = {}
      $cardData: task_manager_dashboard_bool_exp! = {}
      $tableData: task_manager_dashboard_bool_exp! = {}
      $chartData: task_manager_dashboard_bool_exp! = {}
    ) {
      task_manager_attendance(where: $where, order_by: { clock_in: desc }) {
        logged
        id
        location
        created_at
        clock_in
        clock_out
        is_report_sent
        break_logs {
          start
          stop
          on_break
        }
      }
      pinnedFilters: task_manager_filters(where: $filters) {
        action
        assigned_to
        client_id
        is_closed
        name
        priority
        project_id
        status
        team_id
        title
        id
      }
      myTasks: task_manager_tasks(
        order_by: { created_at: desc }
        where: $myTasks
      ) {
        id
        title
        status
        type
        due_date
        priority
        project {
          id
          title
          priority

          client {
            name
          }
        }

        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        assignee {
          name
          lname
        }
      }
      task_manager_tasks_aggregate(where: $myTasks) {
        aggregate {
          count
        }
      }

      myTeams: task_manager_teams(where: $myTeams) {
        id
        name
        created_at
        team_members_aggregate {
          aggregate {
            count
          }
        }
      }

      overdueTasks_count: task_manager_tasks_aggregate(where: $overdueTasks) {
        aggregate {
          count
        }
      }

      projects_count: task_manager_projects_aggregate(where: $projects) {
        aggregate {
          count
        }
      }
      cardData: task_manager_dashboard(where: $cardData) {
        color
        created_by
        filter_id
        id
        title
        type
        icon
        dashboard_filter {
          id
          name
          status
          title
          team_id
          user_id
          project_id
          priority
          is_pinned_to_dashboard
          is_closed
          client_id
          assigned_to
          action
        }
      }

      tableData: task_manager_dashboard(where: $tableData) {
        color
        created_by
        filter_id
        id
        title
        type
        dashboard_filter {
          id
          name
          status
          title
          team_id
          user_id
          project_id
          priority
          is_pinned_to_dashboard
          is_closed
          client_id
          assigned_to
          action
        }
      }
      chartData: task_manager_dashboard(where: $chartData) {
        color
        created_by
        filter_id
        id
        title
        type
        dashboard_filter {
          id
          name
          status
          title
          team_id
          user_id
          project_id
          priority
          is_pinned_to_dashboard
          is_closed
          client_id
          assigned_to
          action
        }
      }
    }
  `,

  getTaskLog: gql`
    query getTaskLog($where: task_manager_task_logs_bool_exp! = {}) {
      task_manager_task_logs(where: $where, order_by: { start: desc }) {
        id
        is_working
        task_id
        start
        end
      }
    }
  `,

  getTaskRequest: gql`
    query getTaskRequest(
      $where: task_manager_task_requests_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_task_requests(
        where: $where
        limit: $limit
        offset: $offset
        order_by: { updated_at: desc }
      ) {
        created_at
        created_by
        id
        request
        response
        response_from
        status
        task_id
        updated_at
        available_limits
        requester {
          name
          lname
          extension_limit
        }
        responder {
          name
          lname
        }
        task {
          title
          assigned_time
          id
          due_date
        }
      }
      requestLength: task_manager_task_requests_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  // getEmployeeLeaves: gql`
  //   query MyQuery(
  //     $yearFirst: date!
  //     $monthFirst: date!
  //     $limit: Int
  //     $offset: Int
  //   ) {
  //     task_manager_users(
  //       where: { is_active: { _eq: true } }
  //       limit: $limit
  //       offset: $offset
  //       order_by: { created_at: desc }
  //     ) {
  //       external_id
  //       name
  //       lname
  //       casual_leave
  //       sick_leave
  //       joined_at
  //       rejected: leaves_aggregate(
  //         where: { from: { _gte: $yearFirst }, status: { _eq: "rejected" } }
  //       ) {
  //         aggregate {
  //           count
  //         }
  //       }
  //       CL: leaves_aggregate(
  //         where: {
  //           from: { _gte: $yearFirst }
  //           status: { _eq: "approved" }
  //           type: { _eq: CASUAL_LEAVE }
  //         }
  //       ) {
  //         aggregate {
  //           sum {
  //             days
  //           }
  //         }
  //       }
  //       SL: leaves_aggregate(
  //         where: {
  //           from: { _gte: $yearFirst }
  //           status: { _eq: "approved" }
  //           type: { _eq: SICK_LEAVE }
  //         }
  //       ) {
  //         aggregate {
  //           sum {
  //             days
  //           }
  //         }
  //       }
  //       WFH: leaves_aggregate(
  //         where: {
  //           from: { _gte: $monthFirst }
  //           status: { _eq: "approved" }
  //           type: { _eq: WFH }
  //         }
  //       ) {
  //         aggregate {
  //           sum {
  //             days
  //           }
  //         }
  //       }
  //     }
  //     totalLength: task_manager_users_aggregate(
  //       where: { is_active: { _eq: true } }
  //     ) {
  //       aggregate {
  //         count
  //       }
  //     }
  //   }
  // `,

  getEmployeeLeaves: gql`
    query MyQuery(
      $yearFirst: date!
      $yearLast: date!
      $monthFirst: date!
      $limit: Int
      $offset: Int
    ) {
      task_manager_users(
        where: { is_active: { _eq: true } }
        limit: $limit
        offset: $offset
        order_by: { created_at: desc }
      ) {
        external_id
        name
        lname
        casual_leave
        sick_leave
        joined_at
        rejected: leaves_aggregate(
          where: {
            from: { _gte: $yearFirst, _lte: $yearLast }
            status: { _eq: "rejected" }
          }
        ) {
          aggregate {
            count
          }
        }
        CL: leaves_aggregate(
          where: {
            from: { _gte: $yearFirst, _lte: $yearLast }
            status: { _eq: "approved" }
            type: { _eq: CASUAL_LEAVE }
          }
        ) {
          aggregate {
            sum {
              days
            }
          }
        }
        SL: leaves_aggregate(
          where: {
            from: { _gte: $yearFirst, _lte: $yearLast }
            status: { _eq: "approved" }
            type: { _eq: SICK_LEAVE }
          }
        ) {
          aggregate {
            sum {
              days
            }
          }
        }
        WFH: leaves_aggregate(
          where: {
            from: { _gte: $monthFirst }
            status: { _eq: "approved" }
            type: { _eq: WFH }
          }
        ) {
          aggregate {
            sum {
              days
            }
          }
        }
      }
      totalLength: task_manager_users_aggregate(
        where: { is_active: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }
    }
  `,

  getTeams: gql`
    query GetTeams($where: task_manager_teams_bool_exp! = {}) {
      task_manager_teams(distinct_on: name, where: $where) {
        name
        user_id
        id
        is_active
        user {
          name
          lname
        }
        team_members_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,

  getTeamMembers: gql`
    query getTeamMembers($where: task_manager_team_members_bool_exp! = {}) {
      task_manager_team_members(order_by: { created_at: desc }, where: $where) {
        role
        id
        team {
          name
        }
        user {
          external_id
          lname
          name
          designation
          profile_pic_url
        }
      }
      task_manager_team_members_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getProjectFile: gql`
    query getProjectFile($where: task_manager_project_files_bool_exp! = {}) {
      task_manager_project_files(where: $where) {
        url
        type
        file_name
      }
    }
  `,

  getTaskFile: gql`
    query getTaskFile($where: task_manager_task_files_bool_exp! = {}) {
      task_manager_task_files(where: $where) {
        url
        type
        file_name
      }
    }
  `,

  getDocuments: gql`
    query getDocuments(
      $where: task_manager_documents_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_documents(
        order_by: { created_at: desc }
        limit: $limit
        offset: $offset
        where: $where
      ) {
        created_at
        id
        title
        type
        url
        visibility
      }
      task_manager_documents_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getHolidays: gql`
    query getHolidays(
      $where: task_manager_holidays_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_holidays(
        order_by: { date: asc }
        limit: $limit
        offset: $offset
        where: $where
      ) {
        id
        title
        date
      }
      task_manager_holidays_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getFilter: gql`
    query GetFilters($userId: String) {
      task_manager_filters(where: { user_id: { _eq: $userId } }) {
        id
        name
        user_id
        action
        assigned_to
        client_id
        is_closed
        priority
        project_id
        status
        team_id
        title
        is_pinned_to_dashboard
      }
    }
  `,

  getSpaces: gql`
    query getSpaces(
      $where: task_manager_spaces_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_spaces(
        order_by: { created_at: desc }
        where: $where
        limit: $limit
        offset: $offset
      ) {
        projects(order_by: { updated_at: desc }) {
          id
          title
          description
        }
        name
        id

        created_by
        user {
          name
        }

        space_members {
          id
          space_id
          user_id
          user {
            name
            external_id
          }
        }

        projects_aggregate {
          aggregate {
            count
          }
        }
        space_members_aggregate {
          aggregate {
            count
          }
        }
      }

      task_manager_spaces_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getSpaceMembers: gql`
    query getSpaceMembers(
      $limit: Int
      $offset: Int
      $where: task_manager_space_members_bool_exp! = {}
    ) {
      task_manager_space_members(
        where: $where
        limit: $limit
        offset: $offset
        order_by: { created_at: desc }
      ) {
        space_id
        created_at
        updated_at
        user_id
        user {
          name
          email
        }
        team {
          name
        }
      }
      task_manager_space_members_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getStatusOfType: gql`
    query getStatusOfType(
      $where: task_manager_project_type_status_bool_exp! = {}
    ) {
      task_manager_project_type_status(where: $where) {
        project_type
        status
        status_title
        status_color
        id
        statusSettings {
          color
          icon
          status_settings_status {
            name
            status
          }
        }
      }
    }
  `,

  getTheme: gql`
    query getTheme($where: task_manager_theme_settings_bool_exp! = {}) {
      task_manager_theme_settings(where: $where) {
        logo_bg_color
        sidebar_bg_color
        theme_color
        updated_at
        variant
      }
    }
  `,

  getNotification: gql`
    query getNotification(
      $where: task_manager_notifications_bool_exp! = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_notifications(
        where: $where
        limit: $limit
        offset: $offset
        order_by: { created_at: desc }
      ) {
        id
        creator
        created_at
        content
        is_all_users
        title
        type
        updated_at
        type_id
        recipients {
          recipient_id
        }
      }
      task_manager_notifications_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getTaskLogForReport: gql`
    query getTaskLogForReport($where: task_manager_task_logs_bool_exp! = {}) {
      task_manager_task_logs(where: $where, order_by: { start: desc }) {
        id
        is_working
        user_id
        task {
          id
          title
          status
          assigned_time
          due_date
          project {
            title
          }
          requests {
            request
            response
            responder {
              name
            }
            status
            extra_time
            extension_date
          }
        }
        start
        end
        comment
        is_manual_entry
        attendance {
          is_report_sent
        }
      }
    }
  `,

  fetchAttendanceAndLeavesForReport: gql`
    query fetchAttendanceForReport(
      $where: task_manager_attendance_bool_exp! = {}
      $leaves: task_manager_leaves_bool_exp! = {}
    ) {
      task_manager_attendance(where: $where) {
        clock_in
        clock_out
        is_report_sent
        status
        location
        break_logs {
          on_break
          start
          stop
        }
      }
      task_manager_leaves(where: $leaves) {
        from
        to
        days
      }
    }
  `,

  getTasksForProjectPage: gql`
    query getTasksForProjectPage($where: task_manager_tasks_bool_exp! = {}) {
      task_manager_tasks(
        where: $where
        order_by: { updated_at: desc }
        limit: 10
      ) {
        assignee {
          name
        }
        id
        status
        tasks_status_setting {
          color
          icon
          status_settings_status {
            name
            status
          }
        }

        title
        due_date
      }
    }
  `,

  getTeam: gql`
    query getTeam($where: task_manager_teams_bool_exp! = {}) {
      task_manager_teams(where: $where) {
        name
        team_members {
          user_id
        }
      }
    }
  `,

  getAllStatusFromProjectType: gql`
    query getAllStatusFromProjectType(
      $where: task_manager_project_type_status_bool_exp! = {}
    ) {
      task_manager_project_type_status(where: $where) {
        statusSettings {
          status_settings_status {
            name
            status
          }
        }
      }
    }
  `,

  getUsersLeaves: gql`
    query getUsers($where: task_manager_users_bool_exp = {}) {
      task_manager_users(where: $where) {
        casual_leave
        sick_leave
        joined_at
      }
    }
  `,

  getCommentFile: gql`
    query getCommentFile($where: task_manager_comment_files_bool_exp! = {}) {
      task_manager_comment_files(where: $where) {
        title
        type
        url
      }
    }
  `,

  getDashboardType: gql`
    query getDashboardType($where: task_manager_dashboard_type_bool_exp! = {}) {
      task_manager_dashboard_type(where: $where) {
        name
        type
      }
    }
  `,

  getFilteredTaskCount: gql`
    query getFilteredTaskCount($where: task_manager_tasks_bool_exp! = {}) {
      task_manager_tasks_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,

  getTaskLogsForCharts: gql`
    query getTaskLogsForCharts($where: task_manager_task_logs_bool_exp! = {}) {
      task_manager_task_logs(where: $where, order_by: { start: desc }) {
        task {
          id
          title
          status
        }
        start
        end
      }
    }
  `,

  getTodayAttendanceData: gql`
    query getTodayAttendanceData(
      $where: task_manager_attendance_bool_exp = {}
    ) {
      task_manager_attendance(where: $where) {
        clock_in
        clock_out
        created_at
        id
        is_report_sent
        location
        logged
        status
        user_id
      }
    }
  `,

  loadUniversalTasks: gql`
    query loadUniversalTasks($where: task_manager_tasks_bool_exp) {
      task_manager_tasks(where: $where) {
        description
        priority
        status
        title
        type
        id
        due_date
        project {
          title
        }
        tasks_status_setting {
          color
          icon
          id
          status
        }
      }
    }
  `,

  getAttendanceIds: gql`
    query MyQuery($where: task_manager_attendance_bool_exp! = {}) {
      task_manager_attendance(where: $where) {
        clock_in
        clock_out
        created_at
        id
        is_report_sent
        location
        logged
        status
        user_id
      }
    }
  `,

  getAlertsAndWarning: gql`
    query getAlertsAndWarning($where: task_manager_tickets_bool_exp! = {}) {
      task_manager_tickets(where: $where) {
        created_at
        creator_id
        description
        title
        id
        is_closed
        project_id
        type
        updated_at
        priority
        status
        project {
          title
          client_id
          type
          mailing_lists {
            user {
              external_id
            }
          }
        }
      }
    }
  `,

  getLeaveBalance: gql`
    query getLeaveBalance($where: task_manager_leaves_bool_exp! = {}) {
      task_manager_leaves(where: $where) {
        created_at
        to
        type
        user_id
        status
        reason
        id
        half_day
        from
        days
        user {
          external_id
        }
      }
    }
  `,

  getLeavesData: gql`
    query getLeavesData($where: task_manager_leaves_bool_exp = {}) {
      task_manager_leaves(where: $where, order_by: { created_at: desc }) {
        created_at
        from
        days
        id
        reason
        status
        to
        type
        half_day
        user_id
        attendance_status {
          title
          status
        }
        user {
          name
          lname
          designation
          external_id
        }
      }
      task_manager_leaves_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
};
