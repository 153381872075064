// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphql: "https://devl-ms-middleware.b-tle.com/v1/graphql",
  HASURA_INSTANCE_URL: "devl-ms-middleware.b-tle.com",
  // nodeUrl: "http://localhost:3001/",
  nodeUrl: "https://devl-node-pm.b-tle.com/",
  appUrl: "https://devl-pm.b-tle.com/",
  leave: {
    CL: 6, // annual leaves
    SL: 6, // sick leaves
    WFH: 4, // work from  home per month
    PH: 12, // public holidays per year
    LOA: 30, //leave of absence unpaid
    VL: 7, // vacation leave for hours clocked >2250
    MaTL: 180, //maternity leave
    AoDL: 84, //child adoption leave for women
    PatL: 3, //paternity leave
  },
  oneSignalAppID: "0e6ba94c-ed48-4990-a8c3-0b86ec89026e",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
