import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AwsService } from "src/app/core/service/aws.service";
import { SharedService } from "../../shared.service";
@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
  styleUrls: ["./file-upload.component.css"],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() progress;
  @Input() isMultiple: boolean;
  @Input() isCommentFiles: boolean;
  @Input() accept: string;
  @Output() filesUploaded = new EventEmitter<{ key: string; type: string }[]>();
  @Output() fileDeleted = new EventEmitter<{ key: string }>();
  onChange: Function;
  public file: File | null = null;
  public fileList: File[] | null = [];
  isLoading = false;
  fileName: string;
  fileNameMap = {};
  fileSizeExceeded = false;

  @ViewChild("fileInput") fileInput!: ElementRef<HTMLInputElement>;
  @HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
  //console.log(event);
    this.fileSizeExceeded = false;
    const file = event && event.item(0);
    // this.onChange(file);
    if (event.length > 0) {
      this.fileList = Array.from(event);

      this.fileList.map((file) => {
        if (file.size > 5 * 1024 * 1024) {
          this.fileSizeExceeded = true;
          this.resetForm();
        }
      });
      this.file = file;
      if (!this.fileSizeExceeded) this.uploadFiles();
    }
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private aws: AwsService,
    private snackBar: MatSnackBar,
    private shared: SharedService
  ) {}

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = "";
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}

  uploadFiles() {
    this.isLoading = true;
    const formData: FormData = new FormData();
    for (const file of this.fileList) {
      const fileNameArray = file.name.split(".");
      const extension = fileNameArray[1];
      this.fileName = fileNameArray[0];
         console.log(this.fileList);
      const randomText = this.shared.generateRandomString(15) + "." + extension;
      // console.log("file name is : ", randomText);
      this.fileNameMap[file.name] = randomText;
      formData.append("files", file, randomText);
    }
    const type = this.aws.getFileType(this.fileList[0].type);
    let key: string;
    if (this.isCommentFiles) key = "comment";
    else key = this.aws.getKey(type);
    formData.append("key", key);

    this.aws.uploadFiles(formData).subscribe({
      next: (data) => {    
       // console.log(data);
            
        const keys = data.map((element: { Key: string }) => {
          const uploadKey = element.Key;
          const uploadKeysArray = uploadKey.split("/");
          let fileKey: string;
          if (uploadKeysArray[0] === "media") fileKey = uploadKeysArray[2];
          else fileKey = uploadKeysArray[1];
          const name = this.getFileNameFromKey(fileKey);
          return { key: element.Key, type: type, name: name.split(".")[0] };
        });
        this.isLoading = false;
        this.shared.showNotification(
          "snackbar-success",
          "File uploaded successfully",
          "bottom",
          "center"
        );

        this.filesUploaded.emit(keys);
      },
      error: (error) => {
        console.log(error);
        if (error === "Error: File size must not exceed 5MB")
          this.fileSizeExceeded = true;
        this.isLoading = false;
        this.shared.showNotification(
          "snackbar-danger",
          "File upload failed: " + error,
          "bottom",
          "center"
        );
        this.resetForm();
      },
    });
  }

  getFileNameFromKey(key: string) {
    return Object.keys(this.fileNameMap).find(
      (Key) => this.fileNameMap[Key] === key
    );
  }

  removeFile(index: number) {
    this.isLoading = true;
    const type = this.aws.getFileType(this.fileList[index].type);
    const folderKey = this.aws.getKey(type);
    const name = this.fileList[index].name;
    const fileNameString = this.fileNameMap[name];
    const key = folderKey + "/" + fileNameString;
    this.aws.deleteFile(key).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.shared.showNotification(
          "snackbar-success",
          "File Deleted Successfully",
          "bottom",
          "center"
        );

        this.fileInput.nativeElement.value = null;

        this.fileDeleted.emit({ key });
        this.fileList.splice(index, 1);
        delete this.fileNameMap[name];
      },
      error: (error) => {
        console.error(error);

        this.isLoading = false;
        this.shared.showNotification(
          "snackbar-danger",
          "File Deletion Failed",
          "bottom",
          "center"
        );
      },
    });
  }

  resetForm() {
    this.fileList = null;
    this.file = null;
    this.fileName = null;
    this.fileNameMap = {};
  }
}
