import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UserData } from "src/app/core/models/users";
import { Subscription } from "rxjs";
import { UnsubscribeOnDestroyAdapter } from "../../UnsubscribeOnDestroyAdapter";
import { GraphqlService } from "src/app/core/service/graphql.service";
import { AddFormComponent } from "./dialogs/add-form/add-form.component";
import { MatDialog } from "@angular/material/dialog";
import { breadcrumb } from "src/app/core/interfaces/breadcrumb_url";
import { Router } from "@angular/router";
import { ExtensionLimitComponent } from "./dialogs/extension-limit/extension-limit.component";

@Component({
  selector: "app-task-requests",
  templateUrl: "./task-requests.component.html",
  styleUrls: ["./task-requests.component.css"],
})
export class TaskRequestsComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  dataSource = [];
  displayedColumns = [];
  isLoading = true;
  length: number;
  user: UserData;
  requestsSubscription: Subscription;
  breadcrumb_urls: breadcrumb[];
  requestParams: {
    limit?: number;
    offset?: number;
    where?: {
      task?: { title?: { _ilike?: string } };
      created_by?: {
        _eq?: string;
      };
      status?: { _eq: string };
      requester?: { department?: { _eq?: string } };
    };
  } = { limit: 12, offset: 0 };
  currentExtensionLimit: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("filter", { static: true }) filter: ElementRef;

  constructor(
    private graphql: GraphqlService,
    public dialog: MatDialog,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = <UserData>JSON.parse(localStorage.getItem("user"));
    this.setTableColumns();
    this.fetchRequests();
    this.breadcrumbNavigate();
    // this.getTaskExtensionLimit();
  }

  setTableColumns() {
    if (this.user.dbRole === "admin" || this.user.dbRole === "project-manager")
      this.displayedColumns = [
        "requestDate",
        "responseDate",
        // "responseFrom",
        "requestFrom",
        "status",
        "task",
        "request",
        "response",
        "actions",
      ];
    else if (this.user.dbRole === "employee")
      this.displayedColumns = [
        "requestDate",
        "responseDate",
        "responseFrom",
        // "requestFrom",
        "status",
        "task",
        "request",
        "response",
      ];
  }

  fetchRequests(params = {}) {
    if (Object.keys(params).length > 0) this.requestParams = params;
    else {
      this.requestParams = {
        limit: 12,
        offset: 0,
      };
      if (
        this.user.dbRole !== "admin" &&
        this.user.dbRole !== "project-manager"
      ) {
        this.requestParams.where = {
          created_by: {
            _eq: this.user.external_id,
          },
          status: { _eq: "pending" },
        };
      } else if (this.user.dbRole === "project-manager") {
        this.requestParams.where = {
          requester: { department: { _eq: this.user.department } },
          status: { _eq: "pending" },
        };
      } else {
        this.requestParams.where = {
          status: { _eq: "pending" },
        };
      }
    }

    this.loadData();
  }

  public loadData(limit = 12, offset = 0) {
    this.requestParams.limit = limit;
    this.requestParams.offset = offset;

    this.isLoading = true;
    this.requestsSubscription = this.graphql
      .getTaskRequests(this.requestParams)
      .subscribe({
        next: (res) => {
          this.length = res.length;
          this.dataSource = res.data;
          console.log(this.dataSource);
          

          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
        },
      });
  }

  refresh() {
    this.loadData(12, 0);
  }

  sendResponse(data: any) {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(AddFormComponent, {
      height: "350px",
      width: "400px",
      direction: tempDirection,

      data: {
        reqId: data.id,
        assignedTime: data.task.assigned_time,
        taskId: data.task.id,
        dueDate: data.task.due_date,
        remainCount: data.task.available_limits,
      },
    });
  }

  openExtensionLimit() {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }

    const dialogRef = this.dialog.open(ExtensionLimitComponent, {
      height: "500px",
      width: "400px",
      direction: tempDirection,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  editCall(row) {}

  getNext(event: any) {
    const offset = event.pageSize * event.pageIndex;
    const limit = event.pageSize;
    this.loadData(limit, offset);
  }

  breadcrumbNavigate() {
    if (this.user?.dbRole == "admin") {
      var objects = [
        {
          title: "Home",
          link: "/admin/dashboard/",
        },
      ];
    } else if ((this.user?.dbRole == "employee", "project-manager")) {
      var objects = [
        {
          title: "Home",
          link: "/employee/dashboard/",
        },
      ];
    }
    this.breadcrumb_urls = objects;
  }
  search(event) {
    const title = event.target.value;
    if (
      this.user.dbRole !== "admin" &&
      this.user.dbRole !== "project-manager"
    ) {
      this.requestParams.where = {
        created_by: {
          _eq: this.user.external_id,
        },
        status: { _eq: "pending" },

        task: {
          title: {
            _ilike: `%${title}%`,
          },
        },
      };
    } else
      this.requestParams.where = {
        task: {
          title: {
            _ilike: `%${title}%`,
          },
        },
        status: { _eq: "pending" },
      };

    this.loadData();
  }

  redirectToRequestedTask(reqId: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/admin/tasks/all-tasks/${reqId}`])
    );

    window.open(url, "_blank");
  }

  // getTaskExtensionLimit() {
  //   this.graphql.getUser(this.user.external_id).subscribe(({ data }) => {
  //     this.currentExtensionLimit = data.task_manager_users[0].extension_limit;
  //     console.log(this.currentExtensionLimit);
      
  //   });
  // }

  getAttemptNumber(extension:number,limit: number): number {
    const result = extension - limit;
    return result === 0 ? 1 : result + 1;
  }
}
