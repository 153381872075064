import { Component, OnInit, Input } from "@angular/core";
import { UserData } from "src/app/core/models/users";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.css"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() items: [
    {
      title: string;
      link: string;
    }
  ];
  @Input() active_item: string;
  
  constructor() {}

  ngOnInit(): void {
    
    
    
  }

 
}
