<div class="container filter-model">
  <form [formGroup]="filterForm">
    <h3 mat-dialog-title>Filter</h3>
    <div mat-dialog-content>
      <div class="row">
        <!--Gift Icon-->
        <!-- <div class="col text-center"> <i class="far fa-times-circle fa-6x col-red"></i> </div> -->
        <!--Modal Text-->
        <div class="col-6">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input formControlName="name" matInput />
              <mat-hint>Filter name for saving</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="col-6">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Priority</mat-label>
              <mat-select formControlName="priority">
                <mat-option value="Critical"> Critical </mat-option>
                <mat-option value="High"> High </mat-option>
                <mat-option value="Low"> Low </mat-option>
                <mat-option value="Medium"> Medium </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Project</mat-label>
              <input
                type="text"
                matInput
                formControlName="project_id"
                [matAutocomplete]="auto"
              />
              <mat-icon matSuffix>arrow_drop_down</mat-icon>

              <mat-autocomplete
                [displayWith]="displayFn.bind(this)"
                #auto="matAutocomplete"
              >
                <mat-option
                  *ngFor="let option of filteredProjects | async"
                  [value]="option.id"
                >
                  {{ option.title }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div class="col-6">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option
                  *ngFor="let status of statusList"
                  [value]="status.status"
                >
                  {{ status.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          *ngIf="getRole() !== 'client' && getRole() !== 'employee'"
          class="col-6"
        >
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Assigned To</mat-label>
              <input
                type="text"
                matInput
                formControlName="assigned_to"
                [matAutocomplete]="autoEmp"
              />

              <mat-icon matSuffix>arrow_drop_down</mat-icon>
              <mat-autocomplete
                [displayWith]="displayEmployee.bind(this)"
                #autoEmp="matAutocomplete"
              >
                <mat-option
                  *ngFor="let option of filteredEmployees | async"
                  [value]="option.external_id"
                >
                  {{ option.name }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div class="col-6">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Closed</mat-label>
              <mat-select formControlName="is_closed">
                <mat-option value="true"> True </mat-option>
                <mat-option value="false"> False</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="getRole() !== 'client'" class="col-6">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Action</mat-label>
              <mat-select formControlName="action">
                <mat-option value="Closed"> Closed </mat-option>
                <mat-option value="Rejected"> Rejected</mat-option>
                <mat-option value="Reopened"> Reopened</mat-option>
                <mat-option value="Website_Launch"> Website Launch</mat-option>
                <mat-option value="Phase_2"> Phase 2</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div
          *ngIf="getRole() === 'admin' || getRole() === 'project-manager'"
          class="col-6"
        >
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Teams</mat-label>
              <input
                type="text"
                matInput
                formControlName="team_id"
                [matAutocomplete]="autoTeam"
              />
              <mat-icon matSuffix>arrow_drop_down</mat-icon>

              <mat-autocomplete
                [displayWith]="displayTeams.bind(this)"
                #autoTeam="matAutocomplete"
              >
                <mat-option
                  *ngFor="let option of filteredTeams | async"
                  [value]="option.id"
                >
                  {{ option.name }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="getRole() !== 'client'" class="col-6">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Clients</mat-label>
              <input
                type="text"
                matInput
                formControlName="client_id"
                [matAutocomplete]="autoClient"
              />
              <mat-icon matSuffix>arrow_drop_down</mat-icon>

              <mat-autocomplete
                [displayWith]="displayClients.bind(this)"
                #autoClient="matAutocomplete"
              >
                <mat-option
                  *ngFor="let option of filteredClients | async"
                  [value]="option.id"
                >
                  {{ option.name }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="center" class="mb-1">
      <button
        mat-flat-button
        color="primary"
        (click)="searchParams()"
        type="submit"
        [mat-dialog-close]="searchQuery"
      >
        Search
      </button>
      <button
        mat-flat-button
        class="save-btn green-btn"
        (click)="saveFilter()"
        type="submit"
        [mat-dialog-close]="1"
        [disabled]="!filterForm.controls.name.value"
      >
        Save
      </button>

      <button mat-flat-button (click)="onNoClick()" tabindex="-1" color="warn">
        Cancel
      </button>
    </mat-dialog-actions>
  </form>
</div>
