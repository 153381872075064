import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-elements-example-dialog',
  templateUrl: './dialog-elements-example-dialog.component.html',
  styleUrls: ['./dialog-elements-example-dialog.component.css']
})
export class DialogElementsExampleDialogComponent implements OnInit {
error:any;
  constructor(public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.error=localStorage.getItem('msg')
  }
  close(){

this.dialog.closeAll()
  }
}
