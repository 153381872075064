import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { breadcrumb } from "src/app/core/interfaces/breadcrumb_url";
import { UserData } from "src/app/core/models/users";
import { GraphqlService } from "src/app/core/service/graphql.service";

@Component({
  selector: "app-task-request-history",
  templateUrl: "./task-request-history.component.html",
  styleUrls: ["./task-request-history.component.css"],
})
export class TaskRequestHistoryComponent implements OnInit {
  data: MatTableDataSource<any>;
  displayedColumns: any;

  isLoading = true;
  length: number;
  user: UserData;
  requestsSubscription: Subscription;
  breadcrumb_urls: breadcrumb[];
  requestParams: {
    limit?: number;
    offset?: number;
    where?: {
      task?: { title?: { _ilike?: string } };
      created_by?: {
        _eq?: string;
      };
      status?: { _in: string[] };
      requester?: { department?: { _eq?: string } };
    };
  } = { limit: 12, offset: 0 };

  constructor(private graphql: GraphqlService) {}

  ngOnInit(): void {
    this.user = <UserData>JSON.parse(localStorage.getItem("user"));
    this.getDisplayedColumns();
    this.breadcrumbNavigate();

    this.fetchRequests();
  }

  getDisplayedColumns() {
    if (this.user.dbRole == "employee") {
      this.displayedColumns = [
        "task",
        "request",
        "response",
        "status",
        "requested_at",
      ];
    } else {
      this.displayedColumns = [
        "task",
        "request",
        "response",
        "status",
        "requested_by",
        "requested_at",
      ];
    }
  }

  fetchRequests(params = {}) {
    if (Object.keys(params).length > 0) this.requestParams = params;
    else {
      this.requestParams = {
        limit: 12,
        offset: 0,
      };
      if (
        this.user.dbRole !== "admin" &&
        this.user.dbRole !== "project-manager"
      ) {
        this.requestParams.where = {
          created_by: {
            _eq: this.user.external_id,
          },
          status: { _in: ["approved", "rejected"] },
        };
      } else if (this.user.dbRole === "project-manager") {
        this.requestParams.where = {
          requester: { department: { _eq: this.user.department } },
          status: { _in: ["approved", "rejected"] },
        };
      } else {
        this.requestParams.where = {
          status: { _in: ["approved", "rejected"] },
        };
      }
    }

    this.loadData();
  }

  public loadData(limit = 12, offset = 0) {
    this.requestParams.limit = limit;
    this.requestParams.offset = offset;

    this.isLoading = true;
    this.requestsSubscription = this.graphql
      .getTaskRequests(this.requestParams)
      .subscribe({
        next: (res) => {
          console.log("data", res);

          this.length = res.length;
          this.data = new MatTableDataSource(res.data);
          console.log("new", this.data);

          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
        },
      });
  }

  refresh() {
    this.loadData(12, 0);
  }

  getNext(event: any) {
    const offset = event.pageSize * event.pageIndex;
    const limit = event.pageSize;
    this.loadData(limit, offset);
  }

  search(event) {
    const title = event.target.value;
    if (
      this.user.dbRole !== "admin" &&
      this.user.dbRole !== "project-manager"
    ) {
      this.requestParams.where.task = {
        title: {
          _ilike: `%${title}%`,
        },
      };
    } else
      this.requestParams.where.task = {
        title: {
          _ilike: `%${title}%`,
        },
      };

    this.loadData();
  }

  breadcrumbNavigate() {
    if (this.user?.dbRole == "admin") {
      var objects = [
        {
          title: "Home",
          link: "/admin/dashboard/",
        },
      ];
    } else if ((this.user?.dbRole == "employee", "project-manager")) {
      var objects = [
        {
          title: "Home",
          link: "/employee/dashboard/",
        },
      ];
    }
    this.breadcrumb_urls = objects;
  }

  ngOnDestroy(): void {
    this.requestsSubscription && this.requestsSubscription.unsubscribe();
  }
}
