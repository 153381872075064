import { UserData } from "./../../core/models/users";
import { Router, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { ROUTES } from "./sidebar-items";
import { AuthService } from "src/app/core/service/auth.service";
import { Role } from "src/app/core/models/role";
import { AwsService } from "src/app/core/service/aws.service";
import { GraphqlService } from "src/app/core/service/graphql.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { SharedService } from "src/app/shared/shared.service";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  filters: any;
  public sidebarItems: any[];
  level1Menu = "";
  level2Menu = "";
  level3Menu = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;
  userRole: any;
  user: UserData;
  profilePic: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private awsService: AwsService,
    private graphql: GraphqlService
  ) {
    this.user = this.authService.userData();

    const body = this.elementRef.nativeElement.closest("body");
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const role = ["admin", "employee", "client", "project-manager","team_lead"];
        const currenturl = event.url.split("?")[0];
        const firstString = currenturl.split("/").slice(1)[0];

        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = event.url.split("/")[2];
          this.level2Menu = event.url.split("/")[3];
        } else {
          this.level1Menu = event.url.split("/")[1];
          this.level2Menu = event.url.split("/")[2];
        }

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }

  getProfilePic() {
    const key = { key: this.user.profile_pic_url, isProfile: true };
    if (!key.key) return;
    this.awsService.getSignedURL(key).subscribe((data) => {
      this.profilePic = data;
    });
  }

  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = "0";
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = "0";
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = "0";
    } else {
      this.level3Menu = element;
    }
  }
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // call your getFilter() function here
        this.getFilter();
      }
    });

    this.getFilter();

    this.getProfilePic();

    this.userFullName = this.user.name;
    if (this.user.dbRole == "admin") {
      this.userType = Role.Admin;
      this.userRole = Role.Admin;
    } else if (this.user.dbRole == "client") {
      this.userType = Role.Client;
      this.userRole = Role.Client;
    } else if (this.user.dbRole == "employee") {
      this.userType = Role.Employee;
      this.userRole = Role.Employee;
    } else if (this.user.dbRole == "project-manager") {
      this.userType = Role.PM;
      this.userRole = Role.PM;
    }else if (this.user.dbRole == "team_lead") {
      this.userType = Role.team_lead;
      this.userRole = Role.team_lead;
    }

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }

  getFilter() {
    this.graphql.getFilter(this.user.external_id).subscribe((result) => {
      let path: string;
      switch (this.user.dbRole) {
        case "admin":
          path = "/admin/tasks/all-tasks";
          break;

        case "employee":
          path = "/employee/tasks/mytasks";
          break;

        case "project-manager":
          path = "/employee/tasks/mytasks";
          break;

        case "team_lead":
          path = "/employee/tasks/mytasks";
          break;  

        case "client":
          path = "/client/supports/tickets";
          break;

        default:
          break;
      }
      this.filters = result.map((filter) => {
        return {
          path,
          title: filter.name,
          moduleName: "tasks",
          iconType: "",
          icon: "",
          class: "",
          groupTitle: false,
          badge: "",
          badgeClass: "",
          role: [""],
          submenu: [],
          isFilter: true,
          state: { type: "filter", filter },
          id: filter.id,
        };
      });

      this.setSidebarItems();
    });
  }

  setSidebarItems() {
    this.sidebarItems = ROUTES.filter((x) => {
      if (
        x.role.indexOf(this.userRole) !== -1 ||
        x.role.indexOf("All") !== -1
      ) {
        x.submenu = x.submenu.filter((y, index) => {
          if (y.title === "MENUITEMS.TASKS.LIST.FILTERS") {
            y.submenu = this.filters;
          }

          let showFilterSubmenu: boolean;
          y.title === "MENUITEMS.TASKS.LIST.FILTERS" && this.filters.length == 0
            ? (showFilterSubmenu = false)
            : (showFilterSubmenu = true);

          if (
            showFilterSubmenu &&
            (y.role.indexOf(this.userRole) !== -1 || y.role[0] === "")
          )
            return y;
        });

        return x;
      }
    });
  }
}
