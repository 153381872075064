<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 ms-2 me-2 font-17">
            {{ dialogTitle }}
          </div>
        </div>
      </div>
    </div>
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <form class="register-form m-4" [formGroup]="requestForm">
      <div
        *ngIf="user.dbRole === 'employee' || user.dbRole === 'project-manager' || user.dbRole === 'team_lead'" 
        class="row"
      >
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <h6>
            Available Limits:
            <span style="font-size: 12px; color: green; font-weight: 600">{{
              userLimit
            }}</span>
          </h6>
          <mat-form-field class="example-full-width mb-1" appearance="outline">
            <mat-label>Request</mat-label>
            <textarea matInput formControlName="request"></textarea>
          </mat-form-field>
          <div *ngIf="isPendingRequestExists" style="color: rgb(238, 52, 52);  font-size: 12px;">
            You already have a pending request on this task.
          </div>
          <div *ngIf="userLimit === 0" style="color: rgb(231, 54, 54); margin-top: 0.5rem; font-size: 12px;">
            You have reached your available limits.
          </div>

        </div>
      </div>
      <div *ngIf="user.dbRole === 'admin'" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Response</mat-label>
            <textarea matInput formControlName="response"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="user.dbRole === 'admin'" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Extra Time</mat-label>
            <input matInput formControlName="extension" type="number" />
            <span matSuffix>minutes</span>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="user.dbRole === 'admin'" class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width mb-3" appearance="outline">
            <mat-label>Extended Due Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="extensionDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint
              >Current Due Date:
              {{ this.dueDate | date : "d/MM/YYYY" }}</mat-hint
            >
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <div class="example-button-row" align="end">
            <div class="btn-container" *ngIf="user.dbRole === 'admin'">
              <button
                mat-raised-button
                color="primary"
                [type]="submit"
                [disabled]="!requestForm.valid"
                [mat-dialog-close]="1"
                (click)="approve()"
              >
                Approve
              </button>
              <button
                mat-raised-button
                color="primary"
                [type]="submit"
                [disabled]="!requestForm.valid"
                [mat-dialog-close]="1"
                (click)="reject()"
              >
                Reject
              </button>
              <button
                mat-raised-button
                color="warn"
                (click)="onNoClick()"
                tabindex="-1"
              >
                Cancel
              </button>
            </div>

            <div
              class="btn-container"
              *ngIf="
                user.dbRole === 'employee' || user.dbRole === 'project-manager' || user.dbRole === 'team_lead'
              "
            >
              <button
                mat-raised-button
                color="primary"
                [type]="submit"
                [disabled]="!requestForm.valid || userLimit <= 0 || isPendingRequestExists"
                [mat-dialog-close]="'success'"
                (click)="submit()"
              >
                Send Request
              </button>
              <button
                mat-raised-button
                color="warn"
                (click)="onNoClick()"
                tabindex="-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
