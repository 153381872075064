import { Component } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { OneSignal } from "onesignal-ngx";
import { environment } from "src/environments/environment";
import { UserData } from "./core/models/users";
import { AuthService } from "./core/service/auth.service";
import { Subscription, interval } from "rxjs";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  currentUrl: string;
  user: UserData;
  loggedInSub: Subscription;
  constructor(
    public _router: Router,
    private oneSignal: OneSignal,
    private auth: AuthService
  ) {
    this.initRouterEvent();
    this.initOneSignal();
  }

  initRouterEvent() {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        // location.onPopState(() => {
        //   window.location.reload();
        // });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
      }
      window.scrollTo(0, 0);
    });
  }

  initOneSignal() {
    this.oneSignal
      .init({
        appId: environment.oneSignalAppID,
      })
      .then(() => {
        const source = interval(1000);
        this.loggedInSub = source.subscribe(() => {
          if (this.auth.userData()) {
            this.getUser();
            this.sendRoleTag();
            this.setUserId();
            this.loggedInSub.unsubscribe();
          }
        });
      });
  }

  sendRoleTag() {
    this.oneSignal.sendTag("role", this.user.dbRole).then(() => {
      console.log("one signal tag sent");
    });
  }

  setUserId() {
    this.oneSignal.setExternalUserId(this.user.external_id);
  }

  getUser() {
    this.user = this.auth.userData();
  }
  
}
