import { Injectable } from "@angular/core";
import { InConfiguration } from "../core/models/config.interface";
import { GraphqlService } from "../core/service/graphql.service";
import { AuthService } from "../core/service/auth.service";
import { UserData } from "../core/models/users";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ConfigService {
  public configData = new BehaviorSubject<InConfiguration>({
    layout: {
      rtl: false, // options:  true & false
      variant: "light", // options:  light & dark
      theme_color: "white", // options:  white, black, purple, blue, cyan, green, orange
      logo_bg_color: "white", // options:  white, black, purple, blue, cyan, green, orange
      sidebar: {
        collapsed: false, // options:  true & false
        backgroundColor: "light", // options:  light & dark
      },
    },
  });
  public configData$ = this.configData.asObservable();
  // public configData: BehaviorSubject<InConfiguration>({});
  user: UserData;

  constructor(private graphql: GraphqlService, private auth: AuthService) {
    this.getUser();
    this.getThemeSettings();
    // this.setConfigData();
  }

  getUser() {
    this.user = this.auth.userData();
  }

  getConfigData() {
    return this.configData$;
  }

  setConfigData(newData) {
    this.configData.next(newData);
  }

  getThemeSettings() {
    const queryParams = {
      where: {
        user_id: {
          _eq: this.user.external_id,
        },
      },
    };

    this.graphql.getTheme(queryParams).subscribe({
      next: ({ data }) => {
        const layoutData = data.task_manager_theme_settings;
        if (layoutData.length > 0) {
          this.setConfigData({
            layout: {
              rtl: false,
              variant: layoutData[0].variant,
              theme_color: layoutData[0].theme_color,
              logo_bg_color: layoutData[0].logo_bg_color,
              sidebar: {
                collapsed: false,
                backgroundColor: layoutData[0].sidebar_bg_color,
              },
            },
          });
        }
      },
    });
  }

  // setConfigData(data) {
  //   this.configData = {
  //     layout: data,
  //   };
  //   console.log(this.configData);
  // }
}
