import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import { ProjectStatusPipe } from "./pipes/project-status.pipe";
import { TaskClassPipe } from "./pipes/task-class.pipe";
import { ProjectPriorityClassPipe } from "./pipes/project-priority-class.pipe";
import { FormatTimePipe } from "./pipes/format-time.pipe";
import { AttendanceStatusPipe } from "./pipes/attendance-status.pipe";
import { SetLinkTargetPipe } from "./pipes/set-link-target.pipe";
import { NotificationRouterDirective } from "./directives/notification-router.directive";
import { IconKeyPipe } from "./pipes/icon-key.pipe";
import { ColorPickerModule } from "ngx-color-picker";
import { MatIconModule } from "@angular/material/icon";
@NgModule({
  declarations: [
    ProjectStatusPipe,
    TaskClassPipe,
    ProjectPriorityClassPipe,
    FormatTimePipe,
    AttendanceStatusPipe,
    SetLinkTargetPipe,
    NotificationRouterDirective,
    IconKeyPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    MatIconModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    MaterialModule,
    FeatherIconsModule,
    ProjectStatusPipe,
    TaskClassPipe,
    ProjectPriorityClassPipe,
    FormatTimePipe,
    AttendanceStatusPipe,
    SetLinkTargetPipe,
    NotificationRouterDirective,
    IconKeyPipe,
    ColorPickerModule,
  ],
})
export class SharedModule {}
