<div class="container text-center">
  <h5>Raise a Ticket</h5>
  <div class="form-group">
    <form [formGroup]="ticketForm" (submit)="onSubmit()">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Ticket Type</mat-label>
        <input
          type="text"
          matInput
          [matAutocomplete]="autotype"
          formControlName="type"
        />
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        <mat-autocomplete
          #autotype="matAutocomplete"
          [displayWith]="displayTicketType.bind(this)"
        >
          <mat-option *ngFor="let ticket of ticketTypes" [value]="ticket.value">
            {{ ticket.type }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-radio-group formControlName="priority" class="mt -3">
        <mat-label class="me-5">Priority</mat-label>
        <mat-radio-button
          value="high"
          [checked]="isHighSelected()"
          [disabled]="isHighDisabled()"
          >High</mat-radio-button
        >
        <mat-radio-button
          value="medium"
          [checked]="isWarningSelected()"
          [disabled]="isWarningDisabled()"
          >Medium</mat-radio-button
        >
      </mat-radio-group>

      <span *ngIf="isHighSelected()" class="mb-3">
        <p style="font-size: 11px; color: rgb(28, 54, 2); font-weight: 500">
          Default priority for alert is set to be High.
        </p>
      </span>
      <span *ngIf="isWarningSelected()" class="mb-3">
        <p style="font-size: 11px; color: rgb(28, 54, 2); font-weight: 500">
          Default priority for warning is set to be Medium.
        </p>
      </span>

      <!-- <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Priority</mat-label>

        <mat-select formControlName="priority">
          <mat-option
            value="High"
            *ngIf="ticketForm.get('type').value === 'alert'"
            >High</mat-option
          >
          <mat-option
            value="Medium"
            *ngIf="ticketForm.get('type').value === 'warning'"
            >Medium</mat-option
          >
        </mat-select>
      </mat-form-field> -->
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Title</mat-label>
        <input type="text" matInput formControlName="title" />
      </mat-form-field>

      <!-- New Form Field for Project -->
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Project</mat-label>
        <input
          type="text"
          matInput
          formControlName="project_id"
          [matAutocomplete]="autoProj"
          required
        />
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        <mat-autocomplete
          [displayWith]="displayProject.bind(this)"
          #autoProj="matAutocomplete"
        >
          <mat-option
            *ngFor="let project of filteredProjects | async"
            [value]="project.id"
          >
            {{ project.title }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- New Text Field for Description -->
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit">Raise</button>
    </form>
  </div>
</div>
