import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "iconKey",
})
export class IconKeyPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    let icon: string;
    switch (value) {
      case "task":
        icon = "task";
        break;
      case "space":
        icon = "public";
        break;
      case "hr":
        icon = "picture_as_pdf";
        break;
      case "employee":
        icon = "person";
        break;
      case "team":
        icon = "groups";
        break;
      case "report":
        icon = "description";
        break;
      case "project":
        icon = "work_history";
        break;
      case "leave":
        icon = "holiday_village";
        break;
      case "attendance":
        icon = "logout";
        break;

      default:
        break;
    }
    return icon;
  }
}
