import { NgModule } from "@angular/core";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { SharedModule } from "../shared.module";
import { MatSpinnerOverlayComponent } from "./mat-spinner-overlay/mat-spinner-overlay.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TaskRequestsComponent } from "./task-requests/task-requests.component";
import { MatTableModule } from "@angular/material/table";
import { MatTableExporterModule } from "mat-table-exporter";
import { MatPaginatorModule } from "@angular/material/paginator";
import { AddFormComponent } from "./task-requests/dialogs/add-form/add-form.component";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { FilterDialogComponent } from "./filter-dialog/filter-dialog.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { PromptDialogComponent } from "./prompt-dialog/prompt-dialog.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MatIconModule } from "@angular/material/icon";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { DashboardItemDialogComponent } from "./dashboard-item-dialog/dashboard-item-dialog.component";
import { TaskRequestHistoryComponent } from "./task-request-history/task-request-history.component";
import { ExtensionLimitComponent } from "./task-requests/dialogs/extension-limit/extension-limit.component";
import { TicketsDialogComponent } from "./tickets-dialog/tickets-dialog.component";
import { CommonModule } from "@angular/common";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { TicketStatusDialogComponent } from "./ticket-status-dialog/ticket-status-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TicketInfoComponent } from "./ticket-info/ticket-info.component";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({
  declarations: [
    FileUploadComponent,
    BreadcrumbComponent,
    MatSpinnerOverlayComponent,
    TaskRequestsComponent,
    AddFormComponent,
    FilterDialogComponent,
    PromptDialogComponent,
    DashboardItemDialogComponent,
    TaskRequestHistoryComponent,
    ExtensionLimitComponent,
    TicketsDialogComponent,
    AlertDialogComponent,
    TicketStatusDialogComponent,
    TicketInfoComponent,
  ],
  imports: [
    SharedModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatTableExporterModule,
    MatSelectModule,
    MatDialogModule,
    MatAutocompleteModule,
    CKEditorModule,
    MatIconModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
  ],
  exports: [
    FileUploadComponent,
    BreadcrumbComponent,
    MatSpinnerOverlayComponent,
    TaskRequestsComponent,
    AddFormComponent,
  ],
})
export class ComponentsModule {}
