import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../service/auth.service";

@Injectable({
  providedIn: "root",
})
export class ClientGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(["/authentication/signin"]);
      return false;
    } else {
      if (this.authService.loggedUser() == "client") {
        return true;
      }
      this.router.navigate([`/client/dashboard`]);

      return false;
    }
  }
  constructor(private authService: AuthService, private router: Router) {}
}
