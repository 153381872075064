<section class="content">
  <div class="content-block">
    <div class="block-header">
      <!-- breadcrumb -->
      <app-breadcrumb
        [title]="'All Time Extension Requests'"
        [items]="breadcrumb_urls"
        [active_item]="'All Time Extension Requests'"
      >
      </app-breadcrumb>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="body">
            <div class="table-responsive">
              <div class="materialTableHeader">
                <div class="row">
                  <div class="col-8">
                    <ul class="header-buttons-left ms-0">
                      <li class="dropdown">
                        <h2>All Time Extension Requests</h2>
                      </li>
                      <li class="dropdown m-l-20">
                        <label for="search-input"
                          ><i class="material-icons search-icon"
                            >search</i
                          ></label
                        >
                        <input
                          (input)="search($event)"
                          placeholder="Search"
                          type="text"
                          #filter
                          class="browser-default search-field"
                          aria-label="Search box"
                        />
                      </li>
                      <li></li>
                      <li>
                        <div class="m-l-10" matTooltip="REFRESH">
                          <button
                            mat-mini-fab
                            color="primary"
                            class="cta--gradient"
                            (click)="refresh()"
                          >
                            <mat-icon class="col-white">refresh</mat-icon>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="container">
                <mat-table
                  #table
                  matTableExporter
                  [dataSource]="data"
                  matSort
                  class="mat-cell"
                  #exporter="matTableExporter"
                >
                  <ng-container matColumnDef="task">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                      Task
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row">
                      <div>{{ row.task.title }}</div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="request">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Request</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row">
                      <div>{{ row.request }}</div>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="response">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Response</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" [routerLink]="row?.id">
                      {{ row.response }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Status</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row">{{ row.status }} </mat-cell>
                  </ng-container>

                  <ng-container
                    matColumnDef="requested_by"
                    *ngIf="
                      user.dbRole === 'project-manager' ||
                      user.dbRole === 'admin'
                    "
                  >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                      Requested By
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row">
                      {{ row.requester.name }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="requested_at">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                      >Requested at</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row">
                      {{ row.created_at | date : "dd/MM/yyyy" : "dateOffset" }}
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [style.cursor]="'pointer'"
                    matRipple
                  >
                  </mat-row>
                </mat-table>
                <!-- Loading spinner -->
                <div *ngIf="isLoading" class="tbl-spinner">
                  <mat-progress-spinner
                    color="primary"
                    [diameter]="40"
                    mode="indeterminate"
                  >
                  </mat-progress-spinner>
                </div>
                <div
                  *ngIf="!isLoading"
                  class="no-results"
                  [style.display]="length == 0 ? '' : 'none'"
                >
                  No results
                </div>
                <mat-paginator
                  #paginator
                  [length]="length"
                  [pageIndex]="0"
                  [pageSize]="12"
                  [pageSizeOptions]="[6, 12, 24, 102]"
                  (page)="getNext($event)"
                >
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
