import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, map, startWith } from "rxjs";
import { UserData } from "src/app/core/models/users";
import { GraphqlService } from "src/app/core/service/graphql.service";
import { PushNotificationService } from "src/app/core/service/push-notification.service";
import { SharedService } from "../../shared.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialogRef } from "@angular/material/dialog";

export interface ticketTypes {
  type: string;
  value: string;
}
export interface TicketStatusTypes {
  type: string;
  value: string;
}

@Component({
  selector: "app-tickets-dialog",
  templateUrl: "./tickets-dialog.component.html",
  styleUrls: ["./tickets-dialog.component.sass"],
})
export class TicketsDialogComponent implements OnInit {
  ticketTypes: ticketTypes[] = [
    { type: "Alert", value: "alert" },
    { type: "Warning", value: "warning" },
  ];

  ticketStatusTypes: TicketStatusTypes[] = [
    { type: "Open", value: "open" },
    { type: "In Progress", value: "in_progress" },
    { type: "Resolved", value: "resolved" },
    { type: "Closed", value: "closed" },
  ];

  filteredProjects: Observable<{ id: string; title: string }[]>;
  projectsList = [];
  ticketForm: FormGroup;
  user: UserData;
  selectedPriority: string = "";
  showPriorityOption = false;
  constructor(
    public dialogRef: MatDialogRef<TicketsDialogComponent>,

    private fb: FormBuilder,
    private graphql: GraphqlService,
    private pushNotificationService: PushNotificationService,
    private shared: SharedService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.createTicketForm();
    this.getAllProjects();
  }

  displayTicketType(item) {
    if (!item || !this.ticketTypes) return undefined;
    const ticketType = this.ticketTypes.find((type) => type.value === item);
    return ticketType ? ticketType.type : undefined;
  }

  displayProject(item) {
    if (!item) return;

    return this.projectsList?.find((project) => project.id === item)?.title;
  }

  getUser() {
    this.user = <UserData>JSON.parse(localStorage.getItem("user"));
  }

  createTicketForm() {
    this.ticketForm = this.fb.group({
      type: [""],
      title: ["", [Validators.required]],
      project_id: [null, [Validators.required]],
      priority: ["", [Validators.required]],
      description: ["", [Validators.required]],
      creator_id: this.user.external_id,
    });
  }

  getAllProjects() {
    const projectParams = {
      where: {
        is_active: { _eq: true },
      },
    };

    this.graphql.listOfProjects(projectParams).subscribe((data) => {
      this.projectsList = data;
      this.getFilteredProjects();
    });
  }

  filterProjects(
    value: string | { id: string; title: string }
  ): { id: string; title: string }[] {
    const filteredVal =
      typeof value === "string" ? value?.toLowerCase() : value.title;
    return this.projectsList.filter((option) =>
      option.title.toLowerCase().includes(filteredVal)
    );
  }

  getFilteredProjects() {
    this.filteredProjects =
      this.ticketForm.controls.project_id.valueChanges.pipe(
        startWith(""),
        map((value) => this.filterProjects(value || ""))
      );
  }

  onSubmit() {
    try {
      this.setPriorityBasedOnType();

      const ticketData = {
        type: this.ticketForm.controls.type.value,
        project_id: this.ticketForm.controls.project_id.value,
        title: this.ticketForm.controls.title.value,
        priority: this.ticketForm.controls.priority.value,
        description: this.ticketForm.controls.description.value,
        creator_id: this.ticketForm.controls.creator_id.value,
        status: "open",
      };

      this.graphql.createTicket(ticketData).subscribe(
        (data) => {
          const ticketId =
            data.data.insert_task_manager_tickets.returning[0].id;

          const taskData = {
            description: this.ticketForm.controls.description.value,
            dueDate: null,
            userId: this.user.external_id,
            link: null,
            priority: "High",
            type: "ticket",
            projectId: this.ticketForm.controls.project_id.value,
            title: this.ticketForm.controls.title.value,
            ticket_id: ticketId,
          };

          this.graphql.addTicketTask(taskData).subscribe((data) => {
            this.shared.showNotification(
              "snackbar-success",
              "Corresponding Task created...!!!",
              "bottom",
              "center"
            );
          });

          this.shared.showNotification(
            "snackbar-success",
            "Ticket Created Successfully...!!!",
            "bottom",
            "center"
          );

          this.dialogRef.close();
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) {
      console.error(error);
      // Handle error here if needed
    }
  }

  showNotification(
    colorName: string,
    text: string,
    placementFrom: any,
    placementAlign: any
  ) {
    this.snackBar.open(text, "", {
      duration: 3500,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  isHighSelected(): boolean {
    const typeValue = this.ticketForm.get("type").value;
    return typeValue !== "warning"; // "High" is selected if type is not "warning"
  }

  isWarningSelected(): boolean {
    const typeValue = this.ticketForm.get("type").value;
    return typeValue === "warning"; // "Warning" is selected if type is "warning"
  }

  isWarningDisabled(): boolean {
    const typeValue = this.ticketForm.get("type").value;
    return typeValue === "alert";
  }

  isHighDisabled(): boolean {
    const typeValue = this.ticketForm.get("type").value;
    return typeValue === "warning";
  }

  setPriorityBasedOnType() {
    const typeValue = this.ticketForm.get("type").value;
    if (typeValue === "alert") {
      this.ticketForm.get("priority").setValue("High");
    } else if (typeValue === "warning") {
      this.ticketForm.get("priority").setValue("Medium");
    }
  }
}
