import { Directive, Input, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { UserData } from "src/app/core/models/users";
import { AuthService } from "src/app/core/service/auth.service";
import { GraphqlService } from "src/app/core/service/graphql.service";

@Directive({
  selector: "[appNotificationRouter]",
})
export class NotificationRouterDirective {
  @Input("appNotificationRouter") type: string;
  @Input("typeId") typeId: string;
  @Input("notificationId") notificationId: string;
  user: UserData;

  constructor(
    private router: Router,
    private auth: AuthService,
    private graphql: GraphqlService
  ) {
    this.getUser();
  }

  @HostListener("click")
  onClick() {
    this.readNotification();
    this.redirectToNotification(this.type, this.typeId);
  }

  getUser() {
    this.user = this.auth.userData();
  }

  redirectToNotification(type: string, typeId: string) {
    // if (this.user.dbRole === "admin") redirectUrl = ``;
    // else if (this.user.dbRole === "client") redirectUrl = ``;
    // else redirectUrl = ``;
    if (!["leave"].includes(type) && !typeId) {
      return;
    }

    let redirectUrl: string;
    switch (type) {
      case "task":
        if (this.user.dbRole === "admin")
          redirectUrl = `/admin/tasks/all-tasks/${typeId}`;
        else if (this.user.dbRole === "client")
          redirectUrl = `/client/supports/tickets/${typeId}`;
        else redirectUrl = `/employee/tasks/${typeId}`;
        break;

      case "team":
        if (this.user.dbRole === "admin")
          redirectUrl = `/admin/teams/${typeId}`;
        else if (
          this.user.dbRole === "employee" ||
          this.user.dbRole === "project-manager"
        )
          redirectUrl = `/employee/teams/${typeId}`;
        break;

      case "project":
        if (this.user.dbRole === "admin")
          redirectUrl = `/admin/projects/view/${typeId}`;
        else if (this.user.dbRole === "client")
          redirectUrl = `client/projects/view/${typeId}`;
        else redirectUrl = `/employee/myprojects/view/${typeId}`;
        break;

      case "leave":
        if (this.user.dbRole === "admin")
          redirectUrl = `/admin/leaves/leave-requests`;
        else if (
          this.user.dbRole === "employee" ||
          this.user.dbRole === "project-manager"
        )
          redirectUrl = `/employee/myleaves`;
        break;

      case "hr":
        if (this.user.dbRole === "admin")
          redirectUrl = `/admin/hr/view-doc/${typeId}`;
        else if (this.user.dbRole === "client")
          redirectUrl = `/client/docs/view-doc/${typeId}`;
        else redirectUrl = `/employee/hr/view-doc/${typeId}`;
        break;

      case "employee":
        if (this.user.dbRole === "admin")
          redirectUrl = `/admin/employees/allEmployees/${typeId}`;
        break;

      case "space":
        if (this.user.dbRole === "admin")
          redirectUrl = `/admin/spaces/all-spaces/${typeId}`;
        else if (this.user.dbRole === "client")
          redirectUrl = `/client/spaces/all-spaces/${typeId}`;
        else redirectUrl = `/employee/spaces/all-spaces/${typeId}`;
        break;

      case "report":
        if (this.user.dbRole === "admin")
          redirectUrl = `/admin/reports/${typeId}`;
        break;

      default:
        break;
    }

    if (redirectUrl) this.router.navigateByUrl(redirectUrl);
  }

  readNotification() {
    if (!this.notificationId) {
      return;
    }
    const setParams = {
      where: {
        notification_id: {
          _eq: this.notificationId,
        },
        recipient_id: { _eq: this.user.external_id },
      },
      _set: {
        is_read: true,
      },
    };

    this.graphql
      .readNotification(setParams)
      .subscribe({ next: ({ data }) => {} });
  }
}
