import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "projectPriorityClass",
})
export class ProjectPriorityClassPipe implements PipeTransform {
  transform(value: string): string {
    let priorityClass: string;
    switch (value) {
      case "Low":
        priorityClass = "col-green";
        break;
      case "Medium":
        priorityClass = "col-blue";
        break;
      case "High":
        priorityClass = "col-orange";
        break;
      case "Critical":
        priorityClass = "col-red";
        break;

      default:
        priorityClass = " ";

        break;
    }

    return priorityClass;
  }
}
