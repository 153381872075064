<div class="container">
  <h3 mat-dialog-title>{{ dialogTitle }}</h3>
<div mat-dialog-content>
  <div class="row align-items-center">
    <!-- Modal Text -->
    <div class="col-auto">
      <mat-icon class="col-red">{{ icon }}</mat-icon>
    </div>
    <div class="col">
      <p class="text-center">{{ content }}</p>
    </div>
  </div>
</div>


  <mat-dialog-actions align="end" class="mb-1">
    <button mat-flat-button color="primary" (click)="submit()">
      {{ submitButtonTitle }}
    </button>
    <button color="warn" mat-flat-button (click)="onNoClick()" tabindex="-1">
      {{ cancelButtonTitle }}
    </button>
  </mat-dialog-actions>
</div>
