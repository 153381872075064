<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a
        href="#"
        onClick="return false;"
        class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false"
      ></a>
      <a
        href="#"
        onClick="return false;"
        class="bars"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
      ></a>
      <a class="navbar-brand logo--theme-light" routerLink="{{ homePage }}">
        <div class="navbar-brand--logo">
          <img src="assets/images/logo new.png" alt="" />
        </div>
        <div class="navbar-brand--icon">
          <img src="assets/images/slim-nav-logo.png" alt="" />
        </div>
        <!-- <span class="logo-name">Kuber</span> -->
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button
            mat-icon-button
            (click)="callSidemenuCollapse()"
            class="sidemenu-collapse"
          >
            <app-feather-icons
              [icon]="'menu'"
              [class]="'header-icon'"
            ></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li
          class="fullscreen"
          *ngIf="user.dbRole === 'project-manager' || user.dbRole === 'admin'"
        >
          <button class="btn cta--primary" (click)="openTicketDialog()">
            Raise Ticket
          </button>
        </li>
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button
            mat-icon-button
            (click)="callFullscreen()"
            class="nav-notification-icons"
          >
            <app-feather-icons
              [icon]="'maximize'"
              [class]="'header-icon'"
            ></app-feather-icons>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
        <!-- <li  class="nav-item langSelItem" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li> -->
        <!-- #START# Notifications-->
        <li class="nav-item btnNotification" ngbDropdown>
          <button
            mat-icon-button
            ngbDropdownToggle
            class="nav-notification-icons"
          >
            <app-feather-icons
              [icon]="'bell'"
              [class]="'header-icon'"
            ></app-feather-icons>

            <span class="label-count bg-red notificationcount">{{
              notificationCount
            }}</span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div
              class="noti-list"
              style="position: relative; max-width: 600px; max-height: 350px"
              [perfectScrollbar]
            >
              <ul class="menu">
                <li class="nfc-header">
                  <h5 class="mb-0">Notitications</h5>
                </li>

                <div class="col-12">
                  <mat-tab-group
                    (selectedTabChange)="toggleToAllNotifications($event)"
                  >
                    <mat-tab>
                      <ng-template mat-tab-label> Unread </ng-template>
                      <div
                        class="row not-row"
                        *ngFor="let notification of notifications"
                      >
                        <div class="col-10">
                          <li>
                            <a
                              [appNotificationRouter]="notification.type"
                              [typeId]="notification.type_id"
                              [notificationId]="notification.id"
                            >
                              <span class="table-img msg-user">
                                <i
                                  class="material-icons"
                                  [ngClass]="getIconClass(notification?.type)"
                                  ><span class="material-symbols-outlined">
                                    {{ notification?.type | iconKey }}
                                  </span></i
                                >
                              </span>
                              <span class="menu-info">
                                <span class="menu-title text-small">{{
                                  notification.title
                                }}</span>
                                <span class="menu-desc mt-2">
                                  <i
                                    class="material-icons"
                                    onClick="return false;"
                                    >access_time</i
                                  >
                                  {{
                                    notification.created_at
                                      | date : "d/M/yyyy, h:mm a"
                                  }}
                                </span>
                              </span>
                              <div *ngIf="notification?.is_read == 'false'">
                                <p>{{ notification?.is_read }}</p>
                                <span class="label-count bg-danger">New</span>
                              </div>
                            </a>
                          </li>
                        </div>
                        <div class="col-2 m-auto">
                          <span class="nfc-close">
                            <app-feather-icons
                              [icon]="'x'"
                              [class]="'user-menu-icons'"
                              (click)="readNotification(notification.id)"
                            ></app-feather-icons>
                          </span>
                        </div>
                      </div>

                      <div class="mt-4">
                        <em>
                          <h6
                            style="text-align: center; font-style: normal"
                            *ngIf="notificationEmpty"
                          >
                            You don't have any notifications
                          </h6>
                        </em>
                      </div>
                    </mat-tab>
                    <mat-tab>
                      <ng-template mat-tab-label>
                        All notifications
                      </ng-template>
                      <div class="">
                        <div class="">
                          <div class="project_widget">
                            <li>
                              <a
                                [appNotificationRouter]="readNotifications.type"
                                [typeId]="readNotifications.type_id"
                                [notificationId]="readNotifications.id"
                                *ngFor="let notifications of readNotifications"
                              >
                                <div>
                                  <span class="table-img msg-user">
                                    <i
                                      class="material-icons"
                                      [ngClass]="
                                        getIconClass(notifications?.type)
                                      "
                                      ><span class="material-symbols-outlined">
                                        {{ notifications?.type | iconKey }}
                                      </span></i
                                    >
                                  </span>
                                </div>

                                <span class="menu-info">
                                  <span class="menu-title text-small">{{
                                    notifications.title
                                  }}</span>
                                  <span class="menu-desc mt-2">
                                    <i
                                      class="material-icons"
                                      onClick="return false;"
                                      >access_time</i
                                    >
                                    {{
                                      notifications.created_at
                                        | date : "d/M/yyyy, h:mm a"
                                    }}
                                  </span>
                                </span>
                                <div *ngIf="notifications?.is_read == 'false'">
                                  <p>{{ notifications?.is_read }}</p>
                                  <span class="label-count bg-danger">New</span>
                                </div>
                                <span class="nfc-close">
                                  <app-feather-icons
                                    [icon]="'delete'"
                                    [class]="'user-menu-icons'"
                                    (click)="
                                      deleteNotification(notifications.id)
                                    "
                                  ></app-feather-icons>
                                </span>
                              </a>
                            </li>
                          </div>
                        </div>
                      </div>

                      <div class="mt-4">
                        <em>
                          <h6
                            style="text-align: center; font-style: normal"
                            *ngIf="readNotificationEmpty"
                          >
                            Notifications Empty
                          </h6>
                        </em>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </ul>
            </div>
            <mat-paginator
              *ngIf="allNotifications"
              #readNotificationsPaginator
              [length]="length"
              [pageIndex]="0"
              [pageSize]="10"
              [hidePageSize]="true"
              (page)="getNext($event)"
            >
            </mat-paginator>
            <div class="mt-3">
              <div class="container">
                <a
                  class="nfc-read-more"
                  (click)="
                    allNotifications
                      ? deleteAllReadNotification()
                      : readAllNotification()
                  "
                  style="cursor: pointer"
                >
                  {{
                    allNotifications
                      ? "Delete All Notifications"
                      : "Read All Notifications"
                  }}
                </a>
              </div>
            </div>
          </div>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile btnUserDropdown" ngbDropdown>
          <div
            class="chip dropdown-toggle"
            ngbDropdownToggle
            class="nav-notification-icons pt-0"
          >
            <img
              [src]="
                profilePic
                  ? profilePic
                  : '../../../assets/images/icons/user.png'
              "
              class="user_img"
              width="32"
              height="32"
              alt="User"
            />
            <!-- <span>{{ user.name }}</span> -->
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div
              class="noti-list"
              style="position: relative; max-width: 600px; max-height: 300px"
              [perfectScrollbar]
            >
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li *ngIf="user.dbRole == 'admin'">
                      <a
                        routerLink="admin/accounts/settings"
                        onClick="return false;"
                      >
                        <app-feather-icons
                          [icon]="'user'"
                          [class]="'user-menu-icons'"
                        ></app-feather-icons
                        >Account
                      </a>
                    </li>
                    <!-- <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox
                      </a>
                    </li> -->
                    <!-- <li
                      *ngIf="
                        user.dbRole == 'employee' ||
                        user.dbRole == 'project-manager' || user.dbRole == 'team_lead' 
                      "
                    >
                      <a
                        routerLink="/employee/account"
                        onClick="return false;"
                      >
                        <app-feather-icons
                          [icon]="'settings'"
                          [class]="'user-menu-icons'"
                        ></app-feather-icons
                        >Account
                      </a>
                    </li> -->
                    <li
                      *ngIf="
                        user.dbRole == 'employee' ||
                        user.dbRole == 'project-manager' || user.dbRole == 'team_lead' 
                      "
                    >
                      <a
                        routerLink="/employee/settings"
                        onClick="return false;"
                      >
                        <app-feather-icons
                          [icon]="'settings'"
                          [class]="'user-menu-icons'"
                        ></app-feather-icons
                        >Settings
                      </a>
                    </li>
                    <li *ngIf="user.dbRole == 'client'">
                      <a routerLink="/client/settings">
                        <app-feather-icons
                          [icon]="'settings'"
                          [class]="'user-menu-icons'"
                        ></app-feather-icons
                        >Settings
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons
                          [icon]="'log-out'"
                          [class]="'user-menu-icons'"
                        ></app-feather-icons
                        >Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<app-mat-spinner-overlay *ngIf="isLoading" [overlay]="true">
</app-mat-spinner-overlay>
