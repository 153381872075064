import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "projectStatus",
})
export class ProjectStatusPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return;
    let status = value.split("_");
    for (let i = 0; i < status.length; i++) {
      status[i] = status[i][0].toUpperCase() + status[i].substring(1);
    }

    const result = status.join(" ");

    return result;
  }
}
