import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { UserData } from "src/app/core/models/users";
import { GraphqlService } from "src/app/core/service/graphql.service";
import { FilterDialogComponent } from "../filter-dialog/filter-dialog.component";
import { SharedService } from "../../shared.service";
import { Observable, map, startWith } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSelectChange } from "@angular/material/select";
import { Taskicons } from "src/app/core/interfaces/task_icons";
import icons from "src/assets/icon/cardicon.json";

@Component({
  selector: "app-dashboard-item-dialog",
  templateUrl: "./dashboard-item-dialog.component.html",
  styleUrls: ["./dashboard-item-dialog.component.css"],
})
export class DashboardItemDialogComponent implements OnInit {
  filteredProjects: Observable<{ id: string; title: string }[]>;
  projectList: { id: string; title: string }[] = [];
  clientsList: { id: string; name: string }[] = [];
  filteredClients: Observable<{ id: string; name: string }[]>;
  usersList: { external_id: string; name: string }[] = [];
  filteredEmployees: Observable<{ external_id: string; name: string }[]>;
  teamsList: { id: string; name: string }[] = [];
  filteredTeams: Observable<{ id: string; name: string }[]>;
  icons: Taskicons[] = icons;
  dashboardDialogForm: FormGroup;
  user: UserData;
  filters = [];
  istableItemSelected: false;
  types = [];
  showFormField = false;
  tooltipText: string;
  employeesList = [];

  queryParams: {
    where?: {};
    filters?: {};
    myTasks?: {};
    myTeams?: {};
    overdueTasks?: {};
    projects?: {};
    cardData?: {};
  } = {};
  statusList = [];
  filterId: string;

  chartTypes = [
    {
      name: "WeeklyAnalysis",
      value: "WeeklyAnalysis",
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<DashboardItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private graphql: GraphqlService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private shared: SharedService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getAllStatus();
    this.getEmployees();
    this.getProjectsList();
    this.getTeams();
    this.getClients();
    this.getDashboardType();
    this.getFilter();
    this.customDashboardForm();
    this.getAllEmployees();
  }

  getAllEmployees() {
    const usersParams = {
      where: {
        role: { _in: ["BEETLER", "ADMIN", "PROJECT_MANAGER", "TEAM_LEAD"] },
        is_active: { _eq: true },
      },
    };
    this.graphql.getUsers(usersParams).subscribe(({ data }) => {
      this.employeesList = data.task_manager_users;
    });
  }

  getEmployees() {
    const queryParams = {
      where: {
        role: {
          _in: ["BEETLER", "PROJECT_MANAGER", "ADMIN", "TEAM_LEAD"],
        },
        is_active: {
          _eq: true,
        },
      },
    };

    this.graphql.getUsers(queryParams).subscribe(({ data }) => {
      this.usersList = data.task_manager_users;
      this.getFileteredEmployees();
    });
  }

  getFileteredEmployees() {
    this.filteredEmployees =
      this.dashboardDialogForm.controls.assigned_to.valueChanges.pipe(
        startWith(""),
        map((value) => this.filterEmployees(value || ""))
      );
  }

  filterEmployees(
    value: string | { external_id: string; name: string }
  ): { external_id: string; name: string }[] {
    const filteredVal =
      typeof value === "string" ? value?.toLowerCase() : value.name;
    return this.usersList.filter((option) =>
      option.name.toLowerCase().includes(filteredVal)
    );
  }

  displayEmployee(item) {
    return this.usersList?.find((user) => user.external_id === item)?.name;
  }

  getRole() {
    return this.user.dbRole;
  }

  getTeams() {
    let queryParams = {};
    if (this.getRole() === "project-manager") {
      queryParams = {
        where: {
          user_id: {
            _eq: this.user.external_id,
          },
          is_active: {
            _eq: true,
          },
        },
      };
    } else
      queryParams = {
        where: {
          is_active: {
            _eq: true,
          },
        },
      };

    this.graphql.getTeams(queryParams).subscribe((data) => {
      this.teamsList = data;
      this.getFilteredTeams();
    });
  }

  getFilteredTeams() {
    this.filteredTeams =
      this.dashboardDialogForm.controls.team_id.valueChanges.pipe(
        startWith(""),
        map((value) => this.filterTeams(value || ""))
      );
  }

  filterTeams(value: string | { id: string; name: string }): {
    id: string;
    name: string;
  }[] {
    const filteredVal =
      typeof value === "string" ? value?.toLowerCase() : value.name;
    return this.teamsList.filter((option) =>
      option.name.toLowerCase().includes(filteredVal)
    );
  }

  displayTeams(item) {
    return this.teamsList?.find((team) => team.id === item)?.name;
  }

  getClients() {
    this.graphql.getClients().subscribe(({ data }) => {
      this.clientsList = data.task_manager_clients;
      this.getFilteredClients();
    });
  }

  getFilteredClients() {
    this.filteredClients =
      this.dashboardDialogForm.controls.client_id.valueChanges.pipe(
        startWith(""),
        map((value) => this.filterClients(value || ""))
      );
  }

  filterClients(value: string | { id: string; name: string }): {
    id: string;
    name: string;
  }[] {
    const filteredVal =
      typeof value === "string" ? value?.toLowerCase() : value.name;
    return this.clientsList.filter((option) =>
      option.name.toLowerCase().includes(filteredVal)
    );
  }

  rgba2hex(rgba) {
    let a,
      rgb = rgba
        .replace(/\s/g, "")
        .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = ((rgb && rgb[4]) || "").trim(),
      hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
        : rgba;

    if (alpha !== "") {
      a = alpha;
    } else {
      a = 1;
    }
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    return "#" + hex;
  }

  public onEventLog(event: string, data: any): void {
    if (data) {
      let color = data;
      if (data.includes("rgba")) {
        color = this.rgba2hex(data);
      }
      this.dashboardDialogForm.controls["color"].setValue(color);
    }
  }

  getDashboardType() {
    this.graphql.getDashboardType().subscribe(({ data }) => {
      this.types = data.task_manager_dashboard_type;
    });
  }

  customDashboardForm() {
    this.dashboardDialogForm = this.fb.group({
      name: ["", [Validators.required]],
      user_id: [this.user.external_id],
      project_id: [null],
      priority: [null],
      status: [null],
      assigned_to: [null],
      is_closed: [false],
      action: [null],
      team_id: [null],
      client_id: [null],
      type: ["", [Validators.required]],
      filter_id: [null],
      color: [null],
      title: [null],
      created_by: [this.user.external_id],
      chart_type: [null],
      chart_user_id: [null],
      icon: [""],
    });
    // delete this.dashboardDialogForm.controls["chart_type"];
  }

  getUser() {
    this.user = <UserData>JSON.parse(localStorage.getItem("user"));
  }

  getFilter() {
    this.graphql.getFilter(this.user.external_id).subscribe((result) => {
      this.filters = result;
    });
  }

  openFilters() {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      height: "500px",
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  onSubmit() {
    const taskParams = {
      where: {
        project: {
          client_id: {
            _eq: this.user.client_id,
          },
        },
      },
      filter: {
        user_id: {
          _eq: this.user.external_id,
        },
      },
    };
    const refetchParams = this.data.params;

    if (this.dashboardDialogForm.get("filter_id").value) {
      let dialogData = {
        type: this.dashboardDialogForm.get("type")?.value,
        color: this.dashboardDialogForm.get("color")?.value,
        filter_id: this.dashboardDialogForm.get("filter_id")?.value,
        title: this.dashboardDialogForm.get("name")?.value,
        created_by: this.dashboardDialogForm.get("created_by")?.value,
        icon: this.dashboardDialogForm.get("icon")?.value,
      };

      this.graphql
        .addCustomDashboardData(dialogData, refetchParams, this.user.dbRole)
        .subscribe({
          next: ({ data }) => {
            this.shared.showNotification(
              "snackbar-success",
              "Dashboard Updated.",
              "bottom",
              "center"
            );
            this.dialogRef.close();
          },
          error: (error) => {
            console.log(error);

            if (
              error ===
              'Error: Uniqueness violation. duplicate key value violates unique constraint "dashboard_created_by_filter_id_type_key"'
            )
              this.shared.showNotification(
                "snackbar-danger",
                "Filter is already in your dashboard.",
                "bottom",
                "center"
              );
            else {
              this.shared.showNotification(
                "snackbar-danger",
                "Filter is already in your dashboard.",
                "bottom",
                "center"
              );
            }
          },
        });
    } else if (this.dashboardDialogForm.value.type === "charts") {
      let dialogData = {
        type: this.dashboardDialogForm.get("type")?.value,
        color: this.dashboardDialogForm.get("color")?.value,
        filter_id: this.dashboardDialogForm.get("filter_id")?.value,
        title: this.dashboardDialogForm.get("name")?.value,
        created_by: this.dashboardDialogForm.get("created_by")?.value,
        chart_type: this.dashboardDialogForm.get("chart_type")?.value,
        chart_user_id: this.dashboardDialogForm.get("chart_user_id")?.value,
      };

      this.graphql
        .addCustomDashboardData(dialogData, refetchParams, this.user.dbRole)
        .subscribe({
          next: ({ data }) => {
            this.shared.showNotification(
              "snackbar-success",
              "Dashboard Updated.",
              "bottom",
              "center"
            );
            this.dialogRef.close();
          },
          error: (error) => {
            console.log(error);

            this.shared.showNotification(
              "snackbar-danger",
              "Filter creation failed.",
              "bottom",
              "center"
            );
          },
        });
    } else {
      const filter = {
        object: {
          user_id: this.dashboardDialogForm.get("user_id")?.value,
          project_id: this.dashboardDialogForm.get("project_id")?.value,
          name: this.dashboardDialogForm.get("name")?.value,
          priority: this.dashboardDialogForm.get("priority")?.value,
          assigned_to: this.dashboardDialogForm.get("assigned_to")?.value,
          status: this.dashboardDialogForm.get("status")?.value,
          is_closed: this.dashboardDialogForm.get("is_closed")?.value,
          action: this.dashboardDialogForm.get("action")?.value,
          team_id: this.dashboardDialogForm.get("team_id")?.value,
          client_id: this.dashboardDialogForm.get("client_id")?.value,
        },
      };

      this.graphql.saveFilter(filter, taskParams).subscribe({
        next: (data) => {
          this.filterId = data.data.insert_task_manager_filters_one.id;
          let dialogData = {
            type: this.dashboardDialogForm.get("type")?.value,
            color: this.dashboardDialogForm.get("color")?.value,
            filter_id: this.filterId,
            title: this.dashboardDialogForm.get("name")?.value,
            created_by: this.dashboardDialogForm.get("created_by")?.value,
          };
          this.graphql
            .addCustomDashboardData(dialogData, refetchParams, this.user.dbRole)
            .subscribe({
              next: ({ data }) => {
                this.shared.showNotification(
                  "snackbar-success",
                  "DashBoard Updated",
                  "bottom",
                  "center"
                );
                this.dialogRef.close();
              },
              error: (error) => {
                console.log(error);
                this.shared.showNotification(
                  "snackbar-danger",
                  "Filter is already in your dashboard.",
                  "bottom",
                  "center"
                );
                if (
                  error ===
                  'Error: Uniqueness violation. duplicate key value violates unique constraint "dashboard_created_by_filter_id_type_key"'
                )
                  this.shared.showNotification(
                    "snackbar-danger",
                    "Filter is already in your dashboard.",
                    "bottom",
                    "center"
                  );
              },
            });
        },
      });
    }
  }

  toggleFormField() {
    this.showFormField = !this.showFormField;
  }

  openSnackBar(message: string, className: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 2000,
      panelClass: className,
    });
  }

  getProjectsList() {
    let queryParams = {};
    if (this.getRole() === "client")
      queryParams = {
        where: {
          client_id: {
            _eq: this.user.client_id,
          },
          is_active: {
            _eq: true,
          },
        },
      };
    else if (this.getRole() === "admin")
      queryParams = {
        where: {
          is_active: {
            _eq: true,
          },
        },
      };
    else
      queryParams = {
        where: {
          is_active: {
            _eq: true,
          },
          mailing_lists: { user_id: { _eq: this.user.external_id } },
        },
      };

    this.graphql.listOfProjects(queryParams).subscribe((data) => {
      this.projectList = data;
      this.getFilteredProjects();
    });
  }

  getFilteredProjects() {
    this.filteredProjects =
      this.dashboardDialogForm.controls.project_id.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterProjects(value || ""))
      );
  }

  private _filterProjects(
    value: string | { title: string; id: string }
  ): { id: string; title: string }[] {
    let filterVal;
    if (typeof value === "string") filterVal = value?.toLowerCase();
    else filterVal = value.title;

    return this.projectList.filter((option) =>
      option.title.toLowerCase().includes(filterVal)
    );
  }

  getAllStatus() {
    let queryParams = {};
    if (this.getRole() == "client") {
      queryParams = {
        where: {
          status: {
            _nin: ["Review", "Rework"],
          },
        },
      };
    }
    this.graphql.getTaskStatus(queryParams).subscribe({
      next: (data) => {
        this.statusList = data;
      },
    });
  }

  displayFn(item) {
    return this.projectList?.find((project) => project.id === item)?.title;
  }

  displayClients(item) {
    return this.clientsList?.find((team) => team.id === item)?.name;
  }

  typeSelectEvent(event: MatSelectChange) {
    if (event.value) {
      this.dialogRef.updateSize("auto", "auto");
    }
  }
}
