import { gql } from "apollo-angular";

export const Mutations = {
  addProject: gql`
    mutation addProject(
      $clientId: uuid
      $spaceId: uuid
      $status: task_manager_project_status_enum
      $title: String
      $userId: String
      $priority: String
      $description: String
      $endDate: timestamptz
      $startDate: timestamptz
      $type: task_manager_project_type_enum
    ) {
      insert_task_manager_projects(
        objects: {
          client_id: $clientId
          space_id: $spaceId
          end_date: $endDate
          start_date: $startDate
          status: $status
          title: $title
          user_id: $userId
          priority: $priority
          description: $description
          type: $type
          isAproved: true
        }
      ) {
        returning {
          id
          client {
            users {
              name
              external_id
            }
          }
        }
      }
    }
  `,

  createProject: gql`
    mutation createProject($object: task_manager_projects_insert_input! = {}) {
      insert_task_manager_projects_one(object: $object) {
        id
      }
    }
  `,

  updateProject: gql`
    mutation updateProject(
      $where: task_manager_projects_bool_exp! = {}
      $_set: task_manager_projects_set_input! = {}
    ) {
      update_task_manager_projects(_set: $_set, where: $where) {
        affected_rows
        returning {
          client {
            users {
              external_id
            }
          }
        }
      }
    }
  `,

  addMembers: gql`
    mutation addMembers($objects: [task_manager_mailing_list_insert_input!]!) {
      insert_task_manager_mailing_list(objects: $objects) {
        affected_rows
      }
    }
  `,

  updateUserData: gql`
    mutation updateUserData(
      $where: task_manager_users_bool_exp! = {}
      $_set: task_manager_users_set_input! = {}
    ) {
      update_task_manager_users(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  updateUser: gql`
    mutation updateUser(
      $userId: String
      $address: String
      $blood: String
      $dob: timestamptz
      $department: String
      $designation: String
      $education: String
      $email: String
      $name: String
      $lname: String
      $employee_id: String
      $employee_role: task_manager_employee_role_enum
      $phone_number: String
      $profile_pic_url: String
      $role: task_manager_roles_enum
    ) {
      update_task_manager_users(
        where: { external_id: { _eq: $userId } }
        _set: {
          address: $address
          blood_group: $blood
          date_of_birth: $dob
          department: $department
          designation: $designation
          education: $education
          email: $email
          lname: $lname
          employee_id: $employee_id
          employee_role: $employee_role
          name: $name
          phone_number: $phone_number
          profile_pic_url: $profile_pic_url
          role: $role
        }
      ) {
        affected_rows
      }
    }
  `,
  addClient: gql`
    mutation addclient($name: String) {
      insert_task_manager_clients(objects: { name: $name }) {
        returning {
          id
        }
      }
    }
  `,
  updateClientData: gql`
    mutation updateClient(
      $where: task_manager_clients_bool_exp! = {}
      $_set: task_manager_clients_set_input! = {}
    ) {
      update_task_manager_clients(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,
  clockIn: gql`
    mutation clockIn($object: task_manager_attendance_insert_input!) {
      insert_task_manager_attendance_one(object: $object) {
        id
      }
    }
  `,

  clockOut: gql`
    mutation clockOut(
      $_set: task_manager_attendance_set_input = {}
      $where: task_manager_attendance_bool_exp = {}
    ) {
      update_task_manager_attendance(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  startBreak: gql`
    mutation startBreak($object: task_manager_break_logs_insert_input!) {
      insert_task_manager_break_logs_one(object: $object) {
        id
      }
    }
  `,

  stopBreak: gql`
    mutation MyMutation(
      $where: task_manager_break_logs_bool_exp = {}
      $_set: task_manager_break_logs_set_input
    ) {
      update_task_manager_break_logs(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  setAttendance: gql`
    mutation MyMutation(
      $_set: task_manager_attendance_set_input = {}
      $where: task_manager_attendance_bool_exp = {}
    ) {
      update_task_manager_attendance(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  addLeave: gql`
    mutation addLeave($objects: [task_manager_attendance_insert_input!]!) {
      insert_task_manager_attendance(objects: $objects) {
        affected_rows
      }
    }
  `,

  applyLeave: gql`
    mutation applyLeave($objects: [task_manager_leaves_insert_input!]!) {
      insert_task_manager_leaves(objects: $objects) {
        affected_rows
      }
    }
  `,

  updateLeave: gql`
    mutation updateLeave(
      $_set: task_manager_leaves_set_input = {}
      $where: task_manager_leaves_bool_exp = {}
    ) {
      update_task_manager_leaves(where: $where, _set: $_set) {
        affected_rows
        returning {
          from
          to
          user_id
          type
        }
      }
    }
  `,

  addEmployeeLeaves: gql`
    mutation addEmployeeLeaves(
      $objects: [task_manager_employee_leaves_insert_input!]!
    ) {
      insert_task_manager_employee_leaves(objects: $objects) {
        affected_rows
      }
    }
  `,

  addTask: gql`
    mutation addTask(
      $description: String
      $dueDate: timestamptz
      $userId: String
      $link: String
      $priority: task_manager_priority_enum
      $projectId: uuid
      $title: String
    ) {
      insert_task_manager_tasks(
        objects: {
          description: $description
          due_date: $dueDate
          external_id: $userId
          link: $link
          priority: $priority
          project_id: $projectId
          title: $title
        }
      ) {
        returning {
          id
        }
      }
    }
  `,

  addTicketTask: gql`
    mutation addTicketTask(
      $description: String
      $dueDate: timestamptz
      $userId: String
      $link: String
      $priority: task_manager_priority_enum
      $projectId: uuid
      $title: String
      $type: task_manager_task_types_enum
      $ticket_id: uuid
    ) {
      insert_task_manager_tasks(
        objects: {
          description: $description
          due_date: $dueDate
          external_id: $userId
          link: $link
          priority: $priority
          project_id: $projectId
          title: $title
          type: $type
          ticket_id: $ticket_id
        }
      ) {
        returning {
          id
        }
      }
    }
  `,

  updateClient: gql`
    mutation updateClient(
      $userId: String
      $name: String
      $phone_number: String
      $email: String
    ) {
      update_task_manager_users(
        _set: { name: $name, phone_number: $phone_number, email: $email }
        where: { external_id: { _eq: $userId } }
      ) {
        returning {
          id
        }
      }
    }
  `,

  addComment: gql`
    mutation addComment(
      $comment: String
      $taskId: uuid
      $url: String
      $userId: String
      $commentVisibility: Boolean
    ) {
      insert_task_manager_comments(
        objects: {
          comment: $comment
          task_id: $taskId
          url: $url
          user_id: $userId
          client_visibility: $commentVisibility
        }
      ) {
        returning {
          id
          client_visibility
        }
      }
    }
  `,
  UpdateComment: gql`
    mutation UpdateComment(
      $editid: uuid!
      $newComment: String!
      $clientVisibility: Boolean!
    ) {
      update_task_manager_comments(
        where: { id: { _eq: $editid } }
        _set: { comment: $newComment, client_visibility: $clientVisibility }
      ) {
        returning {
          comment
          client_visibility
          id
          __typename
        }
        __typename
      }
    }
  `,
  editClient: gql`
    mutation updateClient(
      $userId: String
      $address: String
      $date_of_birth: timestamptz
      $name: String
      $phone_number: String
      $email: String
      $blood: String
      $profile_pic_url: String
    ) {
      update_task_manager_users(
        _set: {
          address: $address
          date_of_birth: $date_of_birth
          name: $name
          phone_number: $phone_number
          email: $email
          blood_group: $blood
          profile_pic_url: $profile_pic_url
        }
        where: { external_id: { _eq: $userId } }
      ) {
        affected_rows
      }
    }
  `,
  addPins: gql`
    mutation addPins($projectId: uuid, $userId: String) {
      insert_task_manager_project_pin_mapping(
        objects: { user_id: $userId, project_id: $projectId }
      ) {
        affected_rows
      }
    }
  `,
  deletePins: gql`
    mutation deletePins($projectId: uuid, $userId: String) {
      delete_task_manager_project_pin_mapping(
        where: { project_id: { _eq: $projectId }, user_id: { _eq: $userId } }
      ) {
        affected_rows
      }
    }
  `,
  startTaskTimer: gql`
    mutation startTaskTimer($object: task_manager_task_logs_insert_input!) {
      insert_task_manager_task_logs_one(object: $object) {
        id
        is_manual_entry
      }
    }
  `,

  updateTaskTimer: gql`
    mutation updateTaskTimer(
      $where: task_manager_task_logs_bool_exp! = {}
      $_set: task_manager_task_logs_set_input! = {}
    ) {
      update_task_manager_task_logs(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  addNewTask: gql`
    mutation addNewTask($object: task_manager_tasks_insert_input! = {}) {
      insert_task_manager_tasks_one(object: $object) {
        id
      }
    }
  `,

  updateTask: gql`
    mutation updateTask(
      $where: task_manager_tasks_bool_exp! = {}
      $_set: task_manager_tasks_set_input! = {}
    ) {
      update_task_manager_tasks(where: $where, _set: $_set) {
        returning {
          id
          external_id
          title
          assignee {
            external_id
          }
          team {
            team_members {
              user {
                external_id
              }
            }
          }
        }
      }
    }
  `,

  createTaskRequest: gql`
    mutation createTaskRequest(
      $object: task_manager_task_requests_insert_input! = {}
    ) {
      insert_task_manager_task_requests_one(object: $object) {
        id
      }
    }
  `,

  updateTaskRequest: gql`
    mutation MyMutation(
      $where: task_manager_task_requests_bool_exp! = {}
      $_set: task_manager_task_requests_set_input! = {}
    ) {
      update_task_manager_task_requests(where: $where, _set: $_set) {
        returning {
          requester {
            external_id
          }
        }
      }
    }
  `,
  createTeam: gql`
    mutation createTeam($objects: [task_manager_teams_insert_input!]!) {
      insert_task_manager_teams(objects: $objects) {
        returning {
          id
        }
      }
    }
  `,
  addTeamMebers: gql`
    mutation addTeamMebers(
      $objects: [task_manager_team_members_insert_input!]!
    ) {
      insert_task_manager_team_members(objects: $objects) {
        affected_rows
        returning {
          team {
            name
            id
          }
        }
      }
    }
  `,

  removeTeamMember: gql`
    mutation removeTeamMember(
      $where: task_manager_team_members_bool_exp! = {}
    ) {
      delete_task_manager_team_members(where: $where) {
        returning {
          user {
            external_id
            name
          }
          team {
            name
          }
        }
      }
    }
  `,

  addProjectFiles: gql`
    mutation addProjectFiles(
      $objects: [task_manager_project_files_insert_input!]!
    ) {
      insert_task_manager_project_files(objects: $objects) {
        affected_rows
      }
    }
  `,

  addTaskFiles: gql`
    mutation InsertTaskFiles(
      $objects: [task_manager_task_files_insert_input!]!
    ) {
      insert_task_manager_task_files(objects: $objects) {
        affected_rows
      }
    }
  `,

  deleteTaskFiles: gql`
    mutation DeleteTaskFiles($where: task_manager_task_files_bool_exp! = {}) {
      delete_task_manager_task_files(where: $where) {
        affected_rows
      }
    }
  `,

  deleteProjectFiles: gql`
    mutation DeleteProjectFiles(
      $where: task_manager_project_files_bool_exp! = {}
    ) {
      delete_task_manager_project_files(where: $where) {
        affected_rows
      }
    }
  `,

  deleteProjectMemberes: gql`
    mutation deleteProjectMemberes(
      $where: task_manager_mailing_list_bool_exp! = {}
    ) {
      delete_task_manager_mailing_list(where: $where) {
        affected_rows
      }
    }
  `,

  saveFilters: gql`
    mutation saveFilters($object: task_manager_filters_insert_input! = {}) {
      insert_task_manager_filters_one(object: $object) {
        id
        name
      }
    }
  `,

  deleteFilter: gql`
    mutation deleteFilter($where: task_manager_filters_bool_exp! = {}) {
      delete_task_manager_filters(where: $where) {
        affected_rows
      }
    }
  `,

  addHoliday: gql`
    mutation addHoliday($object: task_manager_holidays_insert_input! = {}) {
      insert_task_manager_holidays_one(object: $object) {
        id
      }
    }
  `,

  updateHoliday: gql`
    mutation updateHoliday(
      $where: task_manager_holidays_bool_exp! = {}
      $_set: task_manager_holidays_set_input! = {}
    ) {
      update_task_manager_holidays(_set: $_set, where: $where) {
        affected_rows
      }
    }
  `,

  deleteHoliday: gql`
    mutation deleteHoliday($where: task_manager_holidays_bool_exp! = {}) {
      delete_task_manager_holidays(where: $where) {
        affected_rows
      }
    }
  `,

  addDocument: gql`
    mutation addDocument($object: task_manager_documents_insert_input! = {}) {
      insert_task_manager_documents_one(object: $object) {
        id
      }
    }
  `,

  addDocumentsMany: gql`
    mutation addDocumentsMany(
      $objects: [task_manager_documents_insert_input!]!
    ) {
      insert_task_manager_documents(objects: $objects) {
        affected_rows
      }
    }
  `,

  deleteDocument: gql`
    mutation DeleteDocument($where: task_manager_documents_bool_exp! = {}) {
      delete_task_manager_documents(where: $where) {
        affected_rows
      }
    }
  `,

  updatePin: gql`
    mutation updatePin(
      $where: task_manager_filters_bool_exp! = {}
      $_set: task_manager_filters_set_input! = {}
    ) {
      update_task_manager_filters(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  addLog: gql`
    mutation addLog($object: task_manager_activity_log_insert_input! = {}) {
      insert_task_manager_activity_log_one(object: $object) {
        id
      }
    }
  `,

  addSpaces: gql`
    mutation addSpaces($object: task_manager_spaces_insert_input! = {}) {
      insert_task_manager_spaces_one(object: $object) {
        name
        id
      }
    }
  `,

  addExistProjectToSpace: gql`
    mutation addExistProject($updates: [task_manager_projects_updates!]!) {
      update_task_manager_projects_many(updates: $updates) {
        returning {
          id
          title
        }
      }
    }
  `,

  removeProject: gql`
    mutation removeProject($where: task_manager_spaces_bool_exp! = {}) {
      delete_task_manager_spaces(where: $where) {
        affected_rows
      }
    }
  `,

  addSpaceMembers: gql`
    mutation addSpaceMembers(
      $objects: [task_manager_space_members_insert_input!]!
    ) {
      insert_task_manager_space_members(objects: $objects) {
        affected_rows
      }
    }
  `,
  removeMemberFromSpace: gql`
    mutation removeMemberFromSpace(
      $where: task_manager_space_members_bool_exp! = {}
    ) {
      delete_task_manager_space_members(where: $where) {
        affected_rows
      }
    }
  `,

  updateProjectTypeStatus: gql`
    mutation updateProjectTypeStatus(
      $where: task_manager_project_type_status_bool_exp! = {}
      $_set: task_manager_project_type_status_set_input! = {}
    ) {
      update_task_manager_project_type_status(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  addProjectTypeStatus: gql`
    mutation addProjectTypeStatus(
      $object: task_manager_project_type_status_insert_input! = {}
    ) {
      insert_task_manager_project_type_status_one(object: $object) {
        id
      }
    }
  `,

  addProjectTypeStatusMany: gql`
    mutation addProjectTypeStatusMany(
      $objects: [task_manager_project_type_status_insert_input!]!
    ) {
      insert_task_manager_project_type_status(objects: $objects) {
        affected_rows
      }
    }
  `,

  createNewStatus: gql`
    mutation createNewStatus($object: task_manager_status_insert_input! = {}) {
      insert_task_manager_status_one(object: $object) {
        status
      }
    }
  `,

  deleteProjectTypeStatus: gql`
    mutation deleteProjectTypeStatus(
      $where: task_manager_project_type_status_bool_exp!
    ) {
      delete_task_manager_project_type_status(where: $where) {
        affected_rows
      }
    }
  `,

  addProjectType: gql`
    mutation addProjectType($object: task_manager_project_type_insert_input!) {
      insert_task_manager_project_type_one(object: $object) {
        type
      }
    }
  `,

  deleteLeave: gql`
    mutation deleteLeave($where: task_manager_leaves_bool_exp!) {
      delete_task_manager_leaves(where: $where) {
        affected_rows
      }
    }
  `,

  updateTheme: gql`
    mutation updateTheme(
      $where: task_manager_theme_settings_bool_exp! = {}
      $_set: task_manager_theme_settings_set_input! = {}
    ) {
      update_task_manager_theme_settings(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  insertTheme: gql`
    mutation insertTheme(
      $object: task_manager_theme_settings_insert_input! = {}
    ) {
      insert_task_manager_theme_settings_one(object: $object) {
        id
      }
    }
  `,

  // addNotificationMany: gql`
  //   mutation addNotification(
  //     $objects: [task_manager_notifications_insert_input!]!
  //   ) {
  //     insert_task_manager_notifications(objects: $objects) {
  //       affected_rows
  //     }
  //   }
  // `,

  addNotification: gql`
    mutation addNotification(
      $object: task_manager_notifications_insert_input! = {}
    ) {
      insert_task_manager_notifications_one(object: $object) {
        id
      }
    }
  `,

  addNotificationRecipients: gql`
    mutation addNotificationRecipients(
      $objects: [task_manager_notification_recipients_insert_input!]!
    ) {
      insert_task_manager_notification_recipients(objects: $objects) {
        affected_rows
      }
    }
  `,

  readNotification: gql`
    mutation readNotification(
      $where: task_manager_notification_recipients_bool_exp! = {}
      $_set: task_manager_notification_recipients_set_input! = {}
    ) {
      update_task_manager_notification_recipients(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  updateSpace: gql`
    mutation updateSpace(
      $where: task_manager_spaces_bool_exp! = {}
      $_set: task_manager_spaces_set_input! = {}
    ) {
      update_task_manager_spaces(where: $where, _set: $_set) {
        returning {
          name
          id
        }
      }
    }
  `,

  deleteNotification: gql`
    mutation deleteNotification(
      $where: task_manager_notification_recipients_bool_exp!
    ) {
      delete_task_manager_notification_recipients(where: $where) {
        affected_rows
      }
    }
  `,

  deleteTeam: gql`
    mutation deleteTeam($where: task_manager_teams_bool_exp! = {}) {
      delete_task_manager_teams(where: $where) {
        affected_rows
      }
    }
  `,

  updateTeam: gql`
    mutation updateTeam(
      $where: task_manager_teams_bool_exp! = {}
      $_set: task_manager_teams_set_input! = {}
    ) {
      update_task_manager_teams(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,

  createStatusSettings: gql`
    mutation createStatusSettings(
      $object: task_manager_status_settings_insert_input! = {}
    ) {
      insert_task_manager_status_settings_one(object: $object) {
        id
      }
    }
  `,

  addCommentFiles: gql`
    mutation addCommentFiles(
      $objects: [task_manager_comment_files_insert_input!]!
    ) {
      insert_task_manager_comment_files(objects: $objects) {
        affected_rows
      }
    }
  `,

  addCustomDashboardData: gql`
    mutation addCustomDashboardData(
      $objects: [task_manager_dashboard_insert_input!]!
    ) {
      insert_task_manager_dashboard(objects: $objects) {
        returning {
          color
          filter_id
          id
          title
          type
          chart_type
          chart_user_id
        }
      }
    }
  `,

  deleteSpace: gql`
    mutation deleteSpace($where: task_manager_spaces_bool_exp! = {}) {
      delete_task_manager_spaces(where: $where) {
        affected_rows
      }
    }
  `,

  removeCustomDashboardItem: gql`
    mutation removeCustomDashboardItem(
      $where: task_manager_dashboard_bool_exp! = {}
    ) {
      delete_task_manager_dashboard(where: $where) {
        affected_rows
      }
    }
  `,

  deleteUser: gql`
    mutation deleteUser($where: task_manager_users_bool_exp! = {}) {
      delete_task_manager_users(where: $where) {
        affected_rows
      }
    }
  `,

  addExtensionLimit: gql`
    mutation addExtensionLimit(
      $objects: [task_manager_task_requests_limits_insert_input!]!
    ) {
      insert_task_manager_task_requests_limits(objects: $objects) {
        returning {
          id
          limit
          created_at
          updated_at
        }
      }
    }
  `,

  createTicket: gql`
    mutation createTicket($input: task_manager_tickets_insert_input!) {
      insert_task_manager_tickets(objects: [$input]) {
        affected_rows
        returning {
          id
          title
        }
      }
    }
  `,

  closeTicket: gql`
    mutation closeTicket(
      $where: task_manager_tickets_bool_exp! = {}
      $_set: task_manager_tickets_set_input! = {}
    ) {
      update_task_manager_tickets(_set: $_set, where: $where) {
        affected_rows
      }
    }
  `,

  updateTicketStatus: gql`
    mutation updateTicketStatus(
      $where: task_manager_tickets_bool_exp! = {}
      $_set: task_manager_tickets_set_input! = {}
    ) {
      update_task_manager_tickets(_set: $_set, where: $where) {
        affected_rows
        returning {
          status
        }
      }
    }
  `,
};
