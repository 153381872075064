<div class="addContainer customize-dashboard">
  <div class="modalHeader">
    <div class="editRowModal m-auto">
      <div class="modalHeader clearfix cd--popup">
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 ms-2 me-2 font-18">
            Customize Dashboard
          </div>
        </div>
      </div>
    </div>
    <button
      mat-icon-button
      aria-label="Close dialog"
      (click)="dialogRef.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="dialog-container" mat-dialog-content>
    <form
      class="register-form m-4"
      [formGroup]="dashboardDialogForm"
      (submit)="onSubmit()"
    >
      <div class="row">
        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Choose a type</mat-label>

            <mat-select
              (selectionChange)="typeSelectEvent($event)"
              formControlName="type"
            >
              <mat-option *ngFor="let type of types" [value]="type.type">{{
                type.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="col-xl-2 col-lg-2 col-md-2 col-sm-2"
          *ngIf="['cards', 'tables'].includes(dashboardDialogForm?.value?.type)"
        >
          <button
            mat-mini-fab
            color="accent"
            type="button"
            [matTooltip]="
              showFormField
                ? 'Customize with New Filter'
                : 'Customize with Existing Filter'
            "
            (click)="toggleFormField()"
          >
            <mat-icon>{{
              showFormField ? "add_circle" : "filter_alt"
            }}</mat-icon>
          </button>
        </div>
      </div>
      <div class="row" *ngIf="dashboardDialogForm?.value?.type=='cards'">
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-0">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Icons</mat-label>
            <mat-select formControlName="icon" required>
              <mat-option *ngFor="let icon of icons" [value]="icon.name">
                <mat-icon class="status_icon">{{ icon.name }}</mat-icon>
              </mat-option>
            </mat-select>
          </mat-form-field></div>
        
      </div>
      <div class="row">
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-0"
          [hidden]="!showFormField"
        >
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Choose a Filter</mat-label>
            <mat-select formControlName="filter_id">
              <mat-option [value]="null"> Default </mat-option>
              <mat-option *ngFor="let filter of filters" [value]="filter.id">
                {{ filter.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          ['cards', 'tables', 'charts'].includes(
            dashboardDialogForm?.value?.type
          )
        "
      >
        <!-- <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
          *ngIf="dashboardDialogForm?.value?.type === 'charts'"
        >
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Title</mat-label>
            <input type="text" matInput formControlName="title" />
          </mat-form-field>
        </div> -->

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-0">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label> Color</mat-label>
            <input
              matInput
              formControlName="color"
              [value]="dashboardDialogForm.controls.color.value"
              [style.background]="dashboardDialogForm.controls.color.value"
              [cpOKButton]="true"
              [cpSaveClickOutside]="false"
              [cpOKButtonClass]="'btn btn-primary btn-xs'"
              [cpPosition]="'top-left'"
              [(colorPicker)]="dashboardDialogForm.controls.color.value"
              (colorPickerClose)="onEventLog('colorPickerClose', $event)"
            />

            <mat-error
              *ngIf="dashboardDialogForm.get('color').hasError('required')"
            >
              Color is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div
        class="row"
        *ngIf="['cards', 'tables'].includes(dashboardDialogForm?.value?.type)"
        [hidden]="showFormField"
      >
        <div class="row" *ngIf="!dashboardDialogForm?.value?.filter_id">
          <div class="col-6">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Name</mat-label>
                <input formControlName="name" matInput />
                <mat-hint>Filter name for saving</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="col-6">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Priority</mat-label>
                <mat-select formControlName="priority">
                  <mat-option value="Critical"> Critical </mat-option>
                  <mat-option value="High"> High </mat-option>
                  <mat-option value="Low"> Low </mat-option>
                  <mat-option value="Medium"> Medium </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Project</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="project_id"
                  [matAutocomplete]="auto"
                />
                <mat-icon matSuffix>arrow_drop_down</mat-icon>

                <mat-autocomplete
                  [displayWith]="displayFn.bind(this)"
                  #auto="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let option of filteredProjects | async"
                    [value]="option.id"
                  >
                    {{ option.title }}</mat-option
                  >
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="col-6">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option
                    *ngFor="let status of statusList"
                    [value]="status.status"
                  >
                    {{ status.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div
            *ngIf="getRole() !== 'client' && getRole() !== 'employee'"
            class="col-6"
          >
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Assigned To</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="assigned_to"
                  [matAutocomplete]="autoEmp"
                />

                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete
                  [displayWith]="displayEmployee.bind(this)"
                  #autoEmp="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let option of filteredEmployees | async"
                    [value]="option.external_id"
                  >
                    {{ option.name }}</mat-option
                  >
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div class="col-6">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Closed</mat-label>
                <mat-select formControlName="is_closed">
                  <mat-option value="true"> True </mat-option>
                  <mat-option value="false"> False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="getRole() !== 'client'" class="col-6">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Action</mat-label>
                <mat-select formControlName="action">
                  <mat-option value="Closed"> Closed </mat-option>
                  <mat-option value="Rejected"> Rejected</mat-option>
                  <mat-option value="Reopened"> Reopened</mat-option>
                  <mat-option value="Website_Launch">
                    Website Launch</mat-option
                  >
                  <mat-option value="Phase_2"> Phase 2</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="getRole() === 'admin' || getRole() === 'project-manager'"
            class="col-6"
          >
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Teams</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="team_id"
                  [matAutocomplete]="autoTeam"
                />
                <mat-icon matSuffix>arrow_drop_down</mat-icon>

                <mat-autocomplete
                  [displayWith]="displayTeams.bind(this)"
                  #autoTeam="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let option of filteredTeams | async"
                    [value]="option.id"
                  >
                    {{ option.name }}</mat-option
                  >
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="getRole() !== 'client'" class="col-6">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Clients</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="client_id"
                  [matAutocomplete]="autoClient"
                />
                <mat-icon matSuffix>arrow_drop_down</mat-icon>

                <mat-autocomplete
                  [displayWith]="displayClients.bind(this)"
                  #autoClient="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let option of filteredClients | async"
                    [value]="option.id"
                  >
                    {{ option.name }}</mat-option
                  >
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-0"
        *ngIf="
          user.dbRole == 'admin' &&
          dashboardDialogForm?.value?.type === 'charts'
        "
      >
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Choose an employee</mat-label>
          <mat-select formControlName="chart_user_id">
            <mat-option
              *ngFor="let employee of employeesList"
              [value]="employee.external_id"
            >
              {{ employee.name + (employee.lname ? " " + employee.lname : "") }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="dashboardDialogForm?.value?.type === 'charts'">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-0">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Choose a Chart type</mat-label>
            <mat-select formControlName="chart_type">
              <mat-option
                *ngFor="let chartType of chartTypes"
                [value]="chartType.value"
                >{{ chartType.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="example-button-row mt-2">
        <div align="end">
          <button mat-raised-button color="primary" type="submit">
            Create
          </button>
          <button
            mat-raised-button
            color="warn"
            tabindex="-1"
            (click)="dialogRef.close()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
