<h2>Change the status</h2>
<form [formGroup]="statusForm" (ngSubmit)="onSubmit()">
  <div *ngFor="let statusType of ticketStatusTypes">
    <ng-container *ngIf="shouldShowStatus(statusType)">
      <label>
        <input type="radio" formControlName="ticketStatus" [value]="statusType.value" [(ngModel)]="selectedStatus" />
        {{ statusType.type }}
      </label>
    </ng-container>
  </div>
  <button class="btn btn-success" type="submit">save</button>
</form>
