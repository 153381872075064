<!-- alert-dialog.component.html -->

<div class="alert-dialog" *ngFor="let item of alertData">
  <div class="dialog-header">
    <ng-container *ngIf="item.type === 'alert'">
      <mat-icon class="alert-icon">error_outline</mat-icon>
      <h6 class="alert-title">Alert</h6>
    </ng-container>
    <ng-container *ngIf="item.type === 'warning'">
      <mat-icon class="warning-icon">warning</mat-icon>
      <h6 class="warning-title">Warning</h6>
    </ng-container>                                                                                         
    

    <!-- <span class="close-btn" (click)="onClose()">&times;</span> -->
  </div>
  <h2>
      {{item.title}}
    </h2>
  <div class="dialog-content">
    <p [ngStyle]="{ color: item.type === 'alert' ? '#f44336' : '#000' }">
      {{ item.description }}
    </p>
  </div>
  <div class="dialog-footer">
    <button (click)="onClose()" class="mb-3">Close</button>
  </div>
</div>
