import { MatDialog } from "@angular/material/dialog";
import { Injectable } from "@angular/core";
import { DialogElementsExampleDialogComponent } from "../ui/dialog-elements-example-dialog/dialog-elements-example-dialog.component";
import { GraphqlService } from "../core/service/graphql.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { notificationDetails } from "../core/interfaces/notification";
import { recipientsDetails } from "../core/interfaces/recipients";
import { PushNotificationService } from "../core/service/push-notification.service";
import { AlertDialogComponent } from "./components/alert-dialog/alert-dialog.component";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(
    private dialog: MatDialog,
    private graphql: GraphqlService,
    private snackBar: MatSnackBar,
    private pushNotificationService: PushNotificationService
  ) {}

  openDialog() {
    this.dialog.open(DialogElementsExampleDialogComponent);
  }

  calcDifference(start: Date, end: Date) {
    let diffTime = Math.abs(start?.getTime() - end?.getTime());
    return diffTime;
  }

  getMinutes(diffTime: number) {
    const mins = Math.floor(
      Math.abs(new Date(0).valueOf() - new Date(diffTime).valueOf()) /
        (1000 * 60)
    );
    return mins;
  }

  getDate() {
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    return date;
  }

  // formatDifference(diffTime: number) {
  //   const elapsed = diffTime;
  //   const elapsedSeconds = elapsed / 1000;

  //   const seconds = Math.floor(elapsedSeconds % 60);
  //   const minutes = Math.floor(elapsedSeconds / 60) % 60;
  //   const hours = Math.floor(elapsedSeconds / (60 * 60)) % 24;

  //   return {
  //     hrs: hours,
  //     mins: minutes,
  //     secs: seconds,
  //   };
  // }

  formatDifference(diffTime: number) {
    const elapsed = diffTime;
    const elapsedSeconds = elapsed / 1000;

    const seconds = Math.floor(elapsedSeconds % 60);
    const minutes = Math.floor((elapsedSeconds / 60) % 60);
    const hours = Math.floor(elapsedSeconds / (60 * 60));

    return {
      hrs: hours,
      mins: minutes,
      secs: seconds,
    };
  }

  getDifferenceBetweenDates(from: Date, to: Date) {
    let date1 = new Date(from);
    let date2 = new Date(to);
    date1.setHours(0);
    date2.setHours(0);
    // To calculate the time difference of two dates
    let Difference_In_Time = Math.abs(date2.getTime() - date1.getTime());

    // To calculate the no. of days between two dates
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );

    return Difference_In_Days + 1;
  }

  getLastDayOfYear(year: number) {
    return new Date(year, 11, 31);
  }

  getFirstDayOfYear(year: number) {
    return new Date(year, 0, 1);
  }

  getFirstDayOfMonth() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    return new Date(y, m, 1);
  }

  getLastDayOfMonth() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    return new Date(y, m + 1, 0);
  }

  getDateRange(startDate: Date, endDate: Date) {
    let dates = [];
    while (startDate <= endDate) {
      let tempDate = new Date(startDate);

      const date =
        tempDate.getFullYear() +
        "-" +
        (tempDate.getMonth() + 1) +
        "-" +
        tempDate.getDate();

      dates.push(date);
      startDate.setDate(startDate.getDate() + 1);
    }
    return dates;
  }

  // generate a random string with given length
  generateRandomString(length: number) {
    let result = "";
    const BASE_STRING =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i)
      result += BASE_STRING[Math.floor(Math.random() * BASE_STRING.length)];
    return result;
  }

  // create activity logs
  addLog(log: string, user_id: string, task_id: string) {
    const insetParam = {
      object: {
        activity_name: log,
        user_id,
        task_id,
      },
    };

    
    this.graphql.addLog(insetParam).subscribe({
      next: (data) => {
      //  console.log("activity log added");

      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  // takes input of color and magnitude of lightness(+ve) or darkness(-ve)
  newShade(hexColor: string, magnitude: number) {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  }
  // get number of days between two dates
  getNumOfDays(date1: Date, date2: Date): number {
    //calculate time difference
    const time_difference = date2.getTime() - date1.getTime();

    //calculate days difference by dividing total milliseconds in a day
    const days_difference = time_difference / (1000 * 60 * 60 * 24);

    return Math.floor(days_difference);
  }

  showNotification(
    colorName: string,
    text: string,
    placementFrom: any,
    placementAlign: any
  ) {
    this.snackBar.open(text, "", {
      duration: 3500,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  sendNotification(
    notificationCreator: string,
    notificationTitle: string,
    notificationContent: string,
    notificationType: string,
    typeId: string,
    recipients: string[],
    isRead = false,
    isAllUsers = false
  ) {
    const notificationsParams: notificationDetails = {
      creator: notificationCreator,
      title: notificationTitle,
      is_all_users: isAllUsers,
      type: notificationType,
      is_read: isRead,
      type_id: typeId,
    };

    // const refetchParams = {
    //   where: {
    //     recipients: { recipient_id: { _eq: notificationCreator } },
    //   },
    //   isRead: {
    //     recipients: {
    //       recipient_id: { _eq: notificationCreator },
    //       is_read: { _eq: false },
    //     },
    //   },
    // };

    this.graphql.addNotification(notificationsParams).subscribe(({ data }) => {
      const notificationId = data.insert_task_manager_notifications_one.id;
      const recipientsParams: recipientsDetails[] = [
        ...recipients.map((member) => {
          return {
            recipient_id: member,
            notification_id: notificationId,
          };
        }),
      ];

      this.graphql
        .addNotificationRecipients(recipientsParams)
        .subscribe((res) => {
        //  console.log("response", res);
          this.pushNotificationService
            .sendPushNotification(
              recipients,
              notificationTitle,
              notificationContent
            )
            .subscribe({
              next: (data) => {
                // console.log(data);
              },
            });
        });
    });
  }
  // showAlert(message: string): void {
  //   // Implement code to show alert
  //   alert(message);
  // }

  // showAlert(data: any): void {
  //   this.dialog.open(AlertDialogComponent, {
  //     data: { data },
  //     width: "800px",
  //     height: "100px",
  //     position: {
  //       top: "5", // Adjust the top position as needed
  //        // Adjust the left position as needed
  //     },
  //   });
  // }


showAlert(data: any): void {
  this.dialog.open(AlertDialogComponent, {
    data: { data },
    width: "800px",
    height: "200px",
    position: {
      top: "5", // Adjust the top position as needed
      // Adjust the left position as needed
    },
  });
}}
