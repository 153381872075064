import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AwsService {
  constructor(private http: HttpClient) {}

  baseURL = environment.nodeUrl;

  uploadFiles(fileList) {
    return this.http.post<any>(`${this.baseURL}s3/upload`, fileList);
  }

  getSignedURL(key) {
    return this.http.post<string>(`${this.baseURL}s3/getSignedURL`, key);
  }

  deleteFile(key) {
    return this.http.post<any>(`${this.baseURL}s3/delete`, { key });
  }

  getFileType(type: string) {
    let fileType: string;
    switch (type) {
      case "image/png":
        fileType = "image";
        break;
      case "image/jpeg":
        fileType = "image";
        break;
      case "application/pdf":
        fileType = "doc";
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        fileType = "doc";
        break;
      case "application/msword":
        fileType = "doc";
        break;
      case "application/vnd.ms-excel":
        fileType = "doc";
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        fileType = "doc";
        break;
      case "video/mp4":
        fileType = "video";
        break;
      default:
        fileType = "doc";
        break;
    }
    return fileType;
  }

  getKey(type: string) {
    // for comment uploads key is: comment
    let key: string;
    switch (type) {
      case "image":
        key = "media/images";
        break;
      case "video":
        key = "media/videos";
        break;
      case "doc":
        key = "docs";
        break;
      // case "comment":
      //   key = "comment";
      //   break;
      default:
        key = "docs";
        break;
    }

    return key;
  }
}
