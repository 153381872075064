import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserData } from "src/app/core/models/users";
import { AuthService } from "src/app/core/service/auth.service";
import { GraphqlService } from "src/app/core/service/graphql.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-filter-dialog",
  templateUrl: "./filter-dialog.component.html",
  styleUrls: ["./filter-dialog.component.css"],
})
export class FilterDialogComponent implements OnInit {
  filterForm: FormGroup;
  user: UserData;
  filteredProjects: Observable<{ id: string; title: string }[]>;
  projectList: { id: string; title: string }[] = [];
  usersList: { external_id: string; name: string }[] = [];
  filteredEmployees: Observable<{ external_id: string; name: string }[]>;
  teamsList: { id: string; name: string }[] = [];
  filteredTeams: Observable<{ id: string; name: string }[]>;
  clientsList: { id: string; name: string }[] = [];
  filteredClients: Observable<{ id: string; name: string }[]>;
  searchQuery = {};
  statusList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    public fb: FormBuilder,
    private auth: AuthService,
    private graphql: GraphqlService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getAllStatus();
    this.getEmployees();
    this.getProjectsList();
    this.getTeams();
    this.getClients();
    this.createFilterForm();
  }

  createFilterForm() {
    this.filterForm = this.fb.group({
      name: [null],
      user_id: [this.user.external_id],
      project_id: [null],
      priority: [null],
      status: [null],
      assigned_to: [null],
      is_closed: [false],
      action: [null],
      team_id: [null],
      client_id: [null],
    });
  }

  getClients() {
    const params = {
      where: {
        isActive: {
          _eq: true,
        },
        isDeleted: {
          _eq: false,
        },
      },
    };
    this.graphql.getClients(params).subscribe(({ data }) => {
      this.clientsList = data.task_manager_clients;
      this.getFilteredClients();
    });
  }

  getAllStatus() {
    let queryParams = {};
    if (this.getRole() == "client") {
      queryParams = {
        where: {
          status: {
            _nin: ["Review", "Rework"],
          },
        },
      };
    }
    this.graphql.getTaskStatus(queryParams).subscribe({
      next: (data) => {
        this.statusList = data;
      },
    });
  }

  getFilteredClients() {
    this.filteredClients = this.filterForm.controls.client_id.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterClients(value || ""))
    );
  }

  filterClients(value: string | { id: string; name: string }): {
    id: string;
    name: string;
  }[] {
    const filteredVal =
      typeof value === "string" ? value?.toLowerCase() : value.name;
    return this.clientsList.filter((option) =>
      option.name.toLowerCase().includes(filteredVal)
    );
  }

  displayClients(item) {
    return this.clientsList?.find((team) => team.id === item)?.name;
  }

  getTeams() {
    let queryParams = {};
    if (this.getRole() === "project-manager") {
      queryParams = {
        where: {
          user_id: {
            _eq: this.user.external_id,
          },
          is_active: {
            _eq: true,
          },
        },
      };
    } else
      queryParams = {
        where: {
          is_active: {
            _eq: true,
          },
        },
      };

    this.graphql.getTeams(queryParams).subscribe((data) => {
      this.teamsList = data;
      this.getFilteredTeams();
    });
  }

  getFilteredTeams() {
    this.filteredTeams = this.filterForm.controls.team_id.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterTeams(value || ""))
    );
  }

  filterTeams(value: string | { id: string; name: string }): {
    id: string;
    name: string;
  }[] {
    const filteredVal =
      typeof value === "string" ? value?.toLowerCase() : value.name;
    return this.teamsList.filter((option) =>
      option.name.toLowerCase().includes(filteredVal)
    );
  }

  displayTeams(item) {
    return this.teamsList?.find((team) => team.id === item)?.name;
  }

  getEmployees() {
    const queryParams = {
      where: {
        role: {
          _in: ["BEETLER", "PROJECT_MANAGER", "ADMIN", "TEAM_LEAD"],
        },
        is_active: {
          _eq: true,
        },
      },
    };

    this.graphql.getUsers(queryParams).subscribe(({ data }) => {
      this.usersList = data.task_manager_users;
      this.getFileteredEmployees();
    });
  }

  getFileteredEmployees() {
    this.filteredEmployees =
      this.filterForm.controls.assigned_to.valueChanges.pipe(
        startWith(""),
        map((value) => this.filterEmployees(value || ""))
      );
  }

  filterEmployees(
    value: string | { external_id: string; name: string }
  ): { external_id: string; name: string }[] {
    const filteredVal =
      typeof value === "string" ? value?.toLowerCase() : value.name;
    return this.usersList.filter((option) =>
      option.name.toLowerCase().includes(filteredVal)
    );
  }

  displayEmployee(item) {
    return this.usersList?.find((user) => user.external_id === item)?.name;
  }

  getRole() {
    return this.user.dbRole;
  }

  getProjectsList() {
    let queryParams = {};
    if (this.getRole() === "client")
      queryParams = {
        where: {
          client_id: {
            _eq: this.user.client_id,
          },
          is_active: {
            _eq: true,
          },
        },
      };
    else if (this.getRole() === "admin")
      queryParams = {
        where: {
          is_active: {
            _eq: true,
          },
        },
      };
    else
      queryParams = {
        where: {
          is_active: {
            _eq: true,
          },
          mailing_lists: { user_id: { _eq: this.user.external_id } },
        },
      };

    this.graphql.listOfProjects(queryParams).subscribe((data) => {
      this.projectList = data;
      this.getFilteredProjects();
    });
  }

  getFilteredProjects() {
    this.filteredProjects =
      this.filterForm.controls.project_id.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterProjects(value || ""))
      );
  }

  private _filterProjects(
    value: string | { title: string; id: string }
  ): { id: string; title: string }[] {
    let filterVal;
    if (typeof value === "string") filterVal = value?.toLowerCase();
    else filterVal = value.title;

    return this.projectList.filter((option) =>
      option.title.toLowerCase().includes(filterVal)
    );
  }

  displayFn(item) {
    return this.projectList?.find((project) => project.id === item)?.title;
  }

  getUser() {
    this.user = this.auth.userData();
  }

  searchParams() {
    this.searchQuery = {
      where: {
        ...(this.filterForm.controls.project_id.value && {
          project_id: { _eq: this.filterForm.controls.project_id.value },
        }),
        ...(this.filterForm.controls.priority.value && {
          priority: { _eq: this.filterForm.controls.priority.value },
        }),
        ...(this.filterForm.controls.status.value
          ? {
              status: { _eq: this.filterForm.controls.status.value },
            }
          : {
              status: {
                _nin: ["Completed", "NotDoable", "OnHold", "IssueNotFound"],
              },
            }),
        ...(this.filterForm.controls.assigned_to.value && {
          assigned_to: { _eq: this.filterForm.controls.assigned_to.value },
        }),
        ...(this.filterForm.controls.is_closed.value && {
          is_closed: { _eq: this.filterForm.controls.is_closed.value },
        }),
        ...(this.filterForm.controls.action.value && {
          action: { _eq: this.filterForm.controls.action.value },
        }),
        ...(this.filterForm.controls.team_id.value && {
          team_id: { _eq: this.filterForm.controls.team_id.value },
        }),
        ...(this.filterForm.controls.client_id.value && {
          project: {
            client_id: { _eq: this.filterForm.controls.client_id.value },
          },
        }),
      },
      filter: {
        user_id: {
          _eq: this.user.external_id,
        },
      },
    };

    this.dialogRef.close({ data: this.searchQuery });
  }

  saveFilter() {
    const taskParams = {
      where: {
        project: {
          client_id: {
            _eq: this.user.client_id,
          },
        },
      },
      filter: {
        user_id: {
          _eq: this.user.external_id,
        },
      },
    };
    const filter = { object: this.filterForm.value };
    this.graphql.saveFilter(filter, taskParams).subscribe({
      next: (data) => {
        this.openSnackBar("Filter Saved Successfully", "snackbar-success");
      },
      error: (e) => {
        console.error(e);
        this.openSnackBar("Failed to save filter", "snackbar-danger");
      },
    });
  }

  openSnackBar(message: string, className: string) {
    this.snackBar.open(message, "Dismiss", {
      duration: 2000,
      panelClass: className,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
