import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "setLinkTarget",
})
export class SetLinkTargetPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || !value.includes("<a")) return value;
    return value.split("<a ").join('<a target="_blank"');
  }
}
