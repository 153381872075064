import {  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable, map, startWith } from "rxjs";
import { GraphqlService } from "src/app/core/service/graphql.service";
import { SharedService } from "src/app/shared/shared.service";

@Component({
  selector: "app-extension-limit",
  templateUrl: "./extension-limit.component.html",
  styleUrls: ["./extension-limit.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtensionLimitComponent implements OnInit {
  filteredEmployees: Observable<{ external_id: string; name: string }[]>;
  limitFormGroup: FormGroup;
  employeesList = [];
  constructor(
    public fb: FormBuilder,
    private graphql: GraphqlService,
    public dialogRef: MatDialogRef<ExtensionLimitComponent>,
    private cdr: ChangeDetectorRef,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
    this.limitForm();
    this.getAllEmployees();
    this.cdr.detectChanges();
  }

  limitForm() {
    this.limitFormGroup = this.fb.group({
      employeeExternalId: [""],
      extension_limit: [""],
    });
  }
  getAllEmployees() {
    const usersParams = {
      where: {
        role: { _in: ["BEETLER", "ADMIN", "PROJECT_MANAGER", "TEAM_LEAD"] },
        is_active: { _eq: true },
      },
    };
    this.graphql.getUsers(usersParams).subscribe(({ data }) => {
      this.employeesList = data.task_manager_users;
      this.getFilteredEmployees();
    });
  }

  getFilteredEmployees() {
    this.filteredEmployees =
      this.limitFormGroup.controls.employeeExternalId?.valueChanges.pipe(
        startWith(""),
        map((value) => this.filterEmployees(value || ""))
      );
  }

  filterEmployees(
    value: string | { external_id: string; name: string }
  ): { external_id: string; name: string }[] {
    const filteredVal =
      typeof value === "string" ? value?.toLowerCase() : value.name;
    if (!filteredVal) return;
    return this.employeesList.filter((option) =>
      option.name.toLowerCase().includes(filteredVal)
    );
  }

  displayEmployee(item) {
    if (!item) return;
    return this.employeesList?.find((user) => user.external_id === item)?.name;
  }

  onSubmit() {
    const selectedExternalId = this.limitFormGroup.value.employeeExternalId;
    const Params = {
      where: { external_id: { _eq: selectedExternalId } },
      _set: { extension_limit: this.limitFormGroup.value.extension_limit },
    };
    this.graphql.updateUserData(Params).subscribe((data) => {
      this.shared.showNotification(
        "snackbar-success",
        "Limit Set successfully!",
        "bottom",
        "center"
      );
      this.dialogRef.close();
    });
  }
}
