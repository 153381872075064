import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "taskClass",
})
export class TaskClassPipe implements PipeTransform {
  transform(value: string): string {
    let className: string;
    switch (value) {
      case "Completed":
        className = "label-success";
        break;
      case "NotYetStarted":
        className = "label-info";
        break;
      case "InProgress":
        className = "label-primary";
        break;
      case "OnHold":
        className = "label-default";
        break;
      case "NotDoable":
        className = "label-danger";
        break;
      case "DateExceeded":
        className = "label-warning";
        break;
      case "IssueNotFound":
        className = "label-danger";
        break;
      default:
        className = "label-primary";
        break;
    }
    return className;
  }
}
