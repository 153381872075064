<div class="container">
  <div class="row justify-content-center">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
      <h6 class="text-center mt-5">Set the maximum Extension Limit</h6>
      <div class="col-12 mt-3">
        <div class="form-group">
          <form [formGroup]="limitFormGroup" (submit)="onSubmit()">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Assign To Employee</mat-label>
              <!-- <input matInput formControlName="client" required> -->
              <input
                type="text"
                matInput
                [matAutocomplete]="autoEmp"
                formControlName="employeeExternalId"
              />
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              <mat-autocomplete
                [displayWith]="displayEmployee.bind(this)"
                #autoEmp="matAutocomplete"
              >
                <mat-option
                  *ngFor="let employee of filteredEmployees | async"
                  [value]="employee.external_id"
                >
                  {{
                    employee.name + (employee.lname ? " " + employee.lname : "")
                  }}
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="
                  limitFormGroup.get('extension_limit').hasError('required')
                "
              >
                Please select an Employee
              </mat-error>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Extension Limit</mat-label>
              <input
                matInput
                id="extensionLimit"
                class="form-control"
                type="number"
                formControlName="extension_limit"
                min="0"
                max="20"
                required
              />
            </mat-form-field>
            <!-- <div
              style="
                font-size: 10px;
                color: #007bff;
                font-style: italic;
                margin-top: 5px;
              "
            >
              
              The value added here is applicable for all tasks.
            </div> -->
            <button
              mat-raised-button
              color="primary"
              class="bottom-right-button"
              type="submit"
            >
              Set
            </button>
            <!-- Added class for styling -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
