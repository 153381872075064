import { environment } from "./../environments/environment";
import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  split,
} from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { onError } from "@apollo/client/link/error";
import { AuthService } from "./core/service/auth.service";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

const uri = environment.graphql; // <-- add the URL of the GraphQL server here

export function createApollo(
  httpLink: HttpLink,
  authService: AuthService
): ApolloClientOptions<any> {
  const http = httpLink.create({ uri });

  const wsLink = new WebSocketLink({
    uri: `wss://${environment.HASURA_INSTANCE_URL}/v1/graphql`,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ extensions, message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          extensions
        );
        if (extensions.code == "invalid-jwt") {
          // const refreshToken = authService.returnRefresh();

          // authService.refreshToken(refreshToken).subscribe((data) => {
          //   // console.log(data);
          //   console.log("refreshing token");

          //   localStorage.removeItem("accessToken");
          //   localStorage.removeItem("refreshToken");

          //   localStorage.setItem("accessToken", data.accessToken);
          //   localStorage.setItem("refreshToken", data.refreshToken);
          // });

          // Do stuff for handle invalid JWT
          authService.logout();
        }
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    http
  );

  return {
    link: ApolloLink.from([errorLink, link]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphQLModule {}
