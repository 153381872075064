<section class="content">
  <div class="content-block">
    <div class="block-header">
      <!-- breadcrumb -->
      <app-breadcrumb
        [title]="' Pending Time Extension Requests'"
        [items]="breadcrumb_urls"
        [active_item]="' Pending Time Extension Requests'"
      >
      </app-breadcrumb>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="body">
            <div class="responsive_table">
              <div class="materialTableHeader">
                <div class="row">
                  <div class="col-8">
                    <ul class="header-buttons-left ml-0">
                      <li class="dropdown">
                        <!-- <h2>
                          <strong> </strong>
                        </h2> -->
                      </li>
                      <li class="dropdown m-l-20">
                        <label for="search-input"
                          ><i class="material-icons search-icon"
                            >search</i
                          ></label
                        >
                        <input
                          (input)="search($event)"
                          placeholder="Search"
                          type="text"
                          #filter
                          class="browser-default search-field"
                          aria-label="Search box"
                        />
                      </li>
                      <!-- <li>
                        <div class="m-l-10" matTooltip="ADD">
                          <button
                            mat-mini-fab
                            color="primary"
                            (click)="addNew()"
                          >
                            <mat-icon class="col-white">add</mat-icon>
                          </button>
                        </div>
                      </li> -->
                      <li>
                        <div class="m-l-10" matTooltip="REFRESH">
                          <button
                            mat-mini-fab
                            color="primary"
                            class="cta--gradient"
                            (click)="refresh()"
                          >
                            <mat-icon class="col-white">refresh</mat-icon>
                          </button>
                        </div>
                      </li>
                      <li *ngIf="user.dbRole === 'admin'">
                        <div class="m-l-10" matTooltip="Set Extension Limit">
                          <button
                            mat-mini-fab
                            color="primary"
                            (click)="openExtensionLimit()"
                          >
                            <mat-icon class="col-white">add</mat-icon>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-4" *ngIf="user.dbRole === 'admin'">
                    <ul class="header-buttons">
                      <!-- <li class="d-flex align-items-center">
                        <h6 class="mr-2 mb-0">Current Extension Limit:</h6>
                        <span
                          class="ms-2"
                          style="
                            color: green;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >{{ currentExtensionLimit }}</span
                        >
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>

              <!--task requests in cards -->
              <div class="container-fluid mb-3 bg-light">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-4 col-12 mt-3 mb-2"
                    *ngFor="let titles of dataSource"
                  >
                    <div class="card p-3 mt-2 cards mb-0 pb-0">
                      <div
                        class="d-flex justify-content-between c-header tr-header mb-2"
                      >
                        <div class="d-flex flex-row align-items-center">
                          <div class="ms-2 t-details mt-0">
                            <h6>
                              <span>{{ titles.task.title | uppercase }}</span>
                            </h6>
                          </div>
                        </div>
                        <small
                          class="heading mt-0 mb-2"
                          *ngIf="titles.status == 'approved'"
                        >
                          <h6>
                            <span
                              class="badge badge-pill badge-primary col-green"
                            >
                              {{ titles.status }}</span
                            >
                          </h6>
                        </small>

                        <small
                          class="heading mt-0 mb-2"
                          *ngIf="titles.status == 'rejected'"
                        >
                          <h6>
                            <span
                              class="badge badge-pill badge-primary col-red"
                            >
                              {{ titles.status }}</span
                            >
                          </h6>
                        </small>

                        <small
                          class="heading mt-0 mb-2"
                          *ngIf="titles.status == 'pending'"
                        >
                          <h6>
                            <span
                              class="badge badge-pill badge-primary col-blue"
                            >
                              {{ titles.status }}</span
                            >
                          </h6>
                        </small>
                      </div>

                      <div class="d-flex justify-content-between p-md-1">
                        <div class="d-flex flex-row">
                          <div class="align-self-center">
                            <div class="icon--tr">
                              <mat-icon class="account-circle"
                                >account_circle</mat-icon
                              >
                            </div>
                          </div>
                          <div class="ms-2">
                            <p class="mb-1">Requested by</p>
                            <h6>
                              {{ titles.requester.name }}
                              {{ titles.requester.lname }}
                              <h6 class="req-dtitle"></h6>
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class="row tr-request">
                        <div class="d-flex justify-content-between">
                          <div>
                            <p class="r-title mb-0">REQUEST</p>
                            <span class="req-date mb-3">{{
                              titles.created_at | date : "dd/MM/yyyy"
                            }}</span>
                          </div>
                          <div>
                            <p class="r-title mb-0 border rounded p-1 bg-light" style="font-size: 10px;">
                              Attempt:
                              <span class="text-success">
                                {{ getAttemptNumber(titles.requester.extension_limit,titles.available_limits) }}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <p class="text-primary">
                            {{ titles.request ? titles.request : "No Request" }}
                          </p>
                        </div>

                        <!-- <div class="d-flex flex-column">
                          <p class="r-title mb-0">REQUEST</p>
                          <span class="req-date mb-3">
                            {{ titles.created_at | date : "dd/MM/yyyy" }}</span
                          >
                          <p class="r-title mb-0">Attempt:</p>
                          <span class="req-date mb-3">
                            {{ getAttemptNumber(titles.available_limits) }}</span
                          >
                          <p class="text-primary">
                            {{ titles.request ? titles.request : "No Request" }}
                          </p>
                        </div> -->
                      </div>

                      <div class="row tr-response">
                        <p class="r-title mb-0">RESPONSE</p>
                        <span
                          class="res-date mb-1"
                          *ngIf="
                            titles.status == 'rejected' ||
                            titles.status == 'approved'
                          "
                        >
                          {{ titles.updated_at | date : "dd/MM/yyyy" }}</span
                        >

                        <p class="text-dark">
                          <span style="color: green">{{
                            titles.response ? titles.response : "No Response"
                          }}</span>
                        </p>
                      </div>

                      <div class="row">
                        <!-- <div class="col-6 mt-3">
                          <div class="card date-card bg-light text-center">
                            <h6 class="">Request date</h6>
                            <h6 class="dates">
                              {{ titles.created_at | date : "dd/MM/yyyy" }}
                            </h6>
                          </div>
                        </div>
                        <div class="col-6 mt-3">
                          <div class="card date-card bg-light text-center">
                            <h6 class="">Response date</h6>

                            <h6 class="dates">
                              {{ titles.updated_at | date : "dd/MM/yyyy" }}
                            </h6>
                          </div>
                        </div> -->
                      </div>

                      <div
                        *ngIf="
                          user.dbRole === 'admin' ||
                          user.dbRole === 'project-manager'
                        "
                        class="action-buttons justify-content-center p-4"
                      >
                        <button
                          *ngIf="
                            titles.status !== 'rejected' &&
                            titles.status !== 'approved'
                          "
                          (click)="$event.stopPropagation()"
                          (click)="sendResponse(titles)"
                          type="button"
                          class="btn btn-success cta--secondary"
                          [disabled]="
                            titles.status == 'rejected' ||
                            titles.status == 'approved'
                          "
                        >
                          Respond
                        </button>

                        <button
                          (click)="redirectToRequestedTask(titles.task.id)"
                          type="button"
                          class="btn mx-2 cta--primary"
                        >
                          View Task
                        </button>

                        <mat-row
                          *matRowDef="let row; columns: displayedColumns"
                          (click)="editCall(row)"
                          [style.cursor]="'pointer'"
                          matRipple
                        >
                        </mat-row>
                      </div>

                      <!-- <div class="mt-3"> <span class="text1">32 Applied <span
                                                                class="text2">of 50
                                                                capacity</span></span> </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- Loading spinner -->
              <div *ngIf="isLoading" class="tbl-spinner">
                <mat-progress-spinner
                  color="primary"
                  [diameter]="40"
                  mode="indeterminate"
                >
                </mat-progress-spinner>
              </div>

              <div
                *ngIf="!isLoading"
                class="no-results"
                [style.display]="leavesLength == 0 ? '' : 'none'"
              >
                No results
              </div>
              <mat-paginator
                #paginator
                [length]="length"
                [pageIndex]="0"
                [pageSize]="12"
                [pageSizeOptions]="[6, 12, 24, 99]"
                (page)="getNext($event)"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
