import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../service/auth.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    // let currentUser = this.authenticationService.currentUserValue;
    let accessToken = localStorage.getItem("accessToken");

    let role = localStorage.getItem("dbRole");
    if (accessToken && role) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          "x-hasura-role": role,
        },
      });
    } else {
      this.authenticationService.logout();
    }
    return next.handle(request);
  }
}
