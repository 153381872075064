import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subscription, interval } from "rxjs";
import { environment } from "src/environments/environment";
import { UserData } from "../models/users";
import { User } from "../models/user";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;


  user: UserData;
  private tokenExpiryHandleSubscription: Subscription;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    // this.currentUser = this.currentUserSubject.asObservable();

    const source = interval(1000 * 60 * 5); // Refresh token every 14 minutes
    this.tokenExpiryHandleSubscription = source.subscribe(() => {
      this.refreshToken(this.returnRefresh()).subscribe((data) => {
        console.log("refreshing token");

        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
      });
    });
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }



  login(data) {
    return this.http.post<any>(`${environment.nodeUrl}auth/login`, data);
  }

  loggedIn() {
    return localStorage.getItem("accessToken");
  }
  loggedUser() {
    this.user = <UserData>JSON.parse(localStorage.getItem("user"));
    return this.user.dbRole;
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
    // this.router.navigate(["/authentication/signin"]);
    return of({ success: false });
  }

  userData() {
    return <UserData>JSON.parse(localStorage.getItem("user")!);
  }

  returnRefresh() {
    return localStorage.getItem("refreshToken");
  }

  tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  refreshToken(refreshToken) {
    return this.http.post<any>(`${environment.nodeUrl}auth/refresh`, {
      refreshToken,
    });
  }
  addEmployee(data: any) {
    return this.http.post<any>(`${environment.nodeUrl}auth/signup`, data);
  }

  addClient(data: any) {
    return this.http.post<any>(`${environment.nodeUrl}auth/addClient`, data);
  }

  changePassword(data: any) {
    return this.http.post<any>(
      `${environment.nodeUrl}auth/changePassword`,
      data
    );
  }

  resetPassword(email: string) {
    return this.http.post(`${environment.nodeUrl}auth/forgot-pwd`, {
      email,
    });
  }

  submitNewPassword(otp: string, newpassword: string) {
    return this.http.post(`${environment.nodeUrl}auth/newpassword`, {
      newpassword,
      otp,
    });
  }
}
