import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "attendanceStatus",
})
export class AttendanceStatusPipe implements PipeTransform {
  transform(value: string): string {
    let stat: string;
    switch (value) {
      case "COMP_LEAVE":
        stat = "Compensation Leave";
        break;
      case "MATERNITY_LEAVE":
        stat = "Maternity Leave";
        break;
      case "VACATION":
        stat = "Vacation";
        break;
      case "SICK_LEAVE":
        stat = "Sick Leave";
        break;
      case "CASUAL_LEAVE":
        stat = "Casual Leave";
        break;
      case "WFH":
        stat = "Work From Home";
        break;
      case "PUBLIC_HOLIDAY":
        stat = "Public Holiday";
        break;
      case "LOA":
        stat = "Leave of Absence";
        break;
      case "PATERNITY_LEAVE":
        stat = "Paternity Leave";
        break;
      case "WORKING":
        stat = "Working";
        break;
      case "ABSENT":
        stat = "Absent";
        break;
      default:
        break;
    }
    return stat;
  }
}
