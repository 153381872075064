import { AuthService } from "../service/auth.service";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error({ error });

        let errorMessage = "";

        if (error.error instanceof ErrorEvent) {
          // client-side error

          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error

          errorMessage = `Error: ${error.error.message}`;;
        }

        return throwError(errorMessage);
      })
    );
  }
}
