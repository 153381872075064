import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { stringify } from "querystring";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class MutationsRouteService {
  constructor(private http: HttpClient) {}
  performMutation(
    url: string,
    tableName: string,
    data: any,
    userData: any
  ): Observable<any> {
    const requestBody = {
      url: url,
      tableName: tableName,
      data: data,
      userData: userData,
    };
    return this.http.post(`${environment.nodeUrl}operation/${url}`, requestBody);
  }
  mutations(
    url: string,
    tableName: string,
    taskData: any,
    userData: any
  ): Observable<any> {
    return this.performMutation(url, tableName, taskData, userData);
  }
}





















