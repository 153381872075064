import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-prompt-dialog",
  templateUrl: "./prompt-dialog.component.html",
  styleUrls: ["./prompt-dialog.component.css"],
})
export class PromptDialogComponent implements OnInit {
  dialogTitle: string;
  content: string;
  submitButtonTitle: string;
  cancelButtonTitle: string;
  icon:string;
  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      content: string;
      submitButton: string;
      cancelButton: string;
      icon:string;
    }
  ) {
    this.dialogTitle = data.title;
    this.content = data.content;
    this.submitButtonTitle = data.submitButton;
    this.cancelButtonTitle = data.cancelButton;
    this.icon=data.icon;
  }

  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close("cancel");
    location.reload()
  }
  submit() {
    this.dialogRef.close("success");
    
  }
  
}
