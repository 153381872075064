import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { Mutations } from "src/app/API/mutations.graphql";
import { Queries } from "src/app/API/queries.graphql";
import { Subscriptions } from "src/app/API/subscriptions.graphql";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class GraphqlService {
  constructor(private apollo: Apollo) {}

  getProjects(variables = {}) {
    return this.apollo.watchQuery<any>({
      query: Queries.getProjects,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getProjectsCounts(spaceId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getProjectsCounts,
      variables: {
        spaceId,
      },
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getEmployees(variables = {}) {
    return this.apollo.watchQuery<any>({
      query: Queries.getEmployees,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getClients(variables = {}) {
    return this.apollo.watchQuery<any>({
      query: Queries.getClients,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getUser(userId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getUser,
      variables: {
        userId,
      },
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  addProject(
    clientId: string,
    spaceId: string,
    status: string,
    title: string,
    userId: string,
    priority: string,
    description: string,
    endDate: string,
    startDate: string,
    type: string
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addProject,
      variables: {
        clientId,
        spaceId,
        status,
        title,
        userId,
        priority,
        description,
        endDate,
        startDate,
        type,
      },
      fetchPolicy: "network-only",
    });
  }

  createProject(variables = {}, projectParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createProject,
      variables,
      refetchQueries: [
        {
          query: Queries.getProjects,
          variables: projectParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  // log the attendance
  clockIn(object, attendanceParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.clockIn,
      variables: {
        object,
      },
      refetchQueries: [
        {
          query: Queries.getEmployeeDashboard,
          variables: attendanceParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  // log clock out
  clockOut(variables, attendanceParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.clockOut,
      variables,
      refetchQueries: [
        {
          query: Queries.getEmployeeDashboard,
          variables: attendanceParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  getUsers(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getUsers,
      variables,
    });
  }

  addMembers(objects: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addMembers,
      variables: {
        objects,
      },
    });
  }

  getProjectTypes(variables = {}) {
    return this.apollo.watchQuery<any>({
      query: Queries.getProjectTypes,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  updateUser(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateUser,
      variables,
    });
  }

  updateUserData(variables = {}, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateUserData,
      variables,
      refetchQueries: [
        Object.keys(refetchParams).length > 0 && {
          query: Queries.getClientUsers,
          variables: refetchParams,
        },
      ],
    });
  }

  addClient(name: string, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addClient,
      variables: {
        name,
      },
      refetchQueries: [
        {
          query: Queries.getClients,
          variables: refetchParams,
        },
      ],
    });
  }

  getClientUsers(variables = {}) {
    return this.apollo.watchQuery<any>({
      query: Queries.getClientUsers,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  // get break data
  getBreak(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getBreak,
      variables,
    });
  }

  // start break
  startBreak(object, attendanceParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.startBreak,
      variables: {
        object,
      },
      refetchQueries: [
        {
          query: Queries.getEmployeeDashboard,
          variables: attendanceParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  // stop break
  stopBreak(variables, attendanceParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.stopBreak,
      variables,
      refetchQueries: [
        {
          query: Queries.getEmployeeDashboard,
          variables: attendanceParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  // set time worked and break
  setAttendance(variables) {
    return this.apollo.mutate<any>({
      mutation: Mutations.setAttendance,
      variables,
    });
  }

  // get user attedance
  fetchAttendanceData(variables) {
    return this.apollo.watchQuery<any>({
      query: Queries.fetchAttendanceData,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  // apply leave
  applyLeave(objects) {
    return this.apollo.mutate<any>({
      mutation: Mutations.applyLeave,
      variables: {
        objects,
      },
    });
  }

  // apply leave
  addLeave(objects) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addLeave,
      variables: {
        objects,
      },
    });
  }

  // get attendance status enum values
  getAttendanceStatus() {
    return this.apollo.query<any>({
      query: Queries.getAttendanceStatus,
    });
  }

  fetchEmployeeLeaves(user_id: string) {
    return this.apollo.query<any>({
      query: Queries.fetchEmployeeLeavesCount,
      variables: {
        user_id,
      },
    });
  }

  addEmployeeLeaves(objects) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addEmployeeLeaves,
      variables: {
        objects,
      },
    });
  }

  getLeaves(variables) {
    return this.apollo.watchQuery<any>({
      query: Queries.getLeaves,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  updateLeave(variables, refetchVariables) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateLeave,
      variables,
      refetchQueries: [
        {
          query: Queries.getLeaves,
          variables: refetchVariables,
        },
      ],
    });
  }

  getPins(userId: string, projectId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getPins,
      variables: {
        userId,
        projectId,
      },
      fetchPolicy: "network-only",
    }).valueChanges;
  }
  addPins(projectId: string, userId: string, dashboardPinParams) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addPins,
      variables: {
        projectId,
        userId,
      },
      refetchQueries: [
        {
          query: Queries.getPins,
          variables: {
            userId,
            projectId,
          },
          fetchPolicy: "network-only",
        },
        {
          query: Queries.getClientDashboard,
          variables: dashboardPinParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }
  deletePins(projectId: string, userId: string, dashboardPinParams) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deletePins,
      variables: {
        projectId,
        userId,
      },
      refetchQueries: [
        {
          query: Queries.getPins,
          variables: {
            userId,
            projectId,
          },
          fetchPolicy: "network-only",
        },
        {
          query: Queries.getClientDashboard,
          variables: dashboardPinParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }
  getLeavesTakenCount(
    user_id: string,
    first_day: Date,
    last_day: Date,
    first_day_month: Date,
    last_day_month: Date
  ) {
    return this.apollo
      .query<any>({
        query: Queries.getLeavesTakenCount,
        fetchPolicy: "network-only",
        variables: {
          user_id,
          last_day,
          first_day,
          first_day_month,
          last_day_month,
        },
      })
      .pipe(
        map(({ data }) => {
          return {
            casualLeaves: data?.casualLeaves.aggregate?.sum.days
              ? data?.casualLeaves.aggregate?.sum.days
              : 0,
            compLeaves: data?.compLeaves.aggregate?.sum.days
              ? data?.compLeaves.aggregate?.sum.days
              : 0,
            LOA: data?.loa?.aggregate.sum.days
              ? data?.loa?.aggregate.sum.days
              : 0,
            maternityLeaves: data?.maternityLeaves?.aggregate.sum.days
              ? data?.maternityLeaves?.aggregate.sum.days
              : 0,
            paternityLeaves: data?.paternityLeaves?.aggregate.sum.days
              ? data?.paternityLeaves?.aggregate.sum.days
              : 0,
            sickLeaves: data?.sickLeaves?.aggregate.sum.days
              ? data?.sickLeaves?.aggregate.sum.days
              : 0,
            vacations: data?.vacations?.aggregate.sum.days
              ? data?.vacations?.aggregate.sum.days
              : 0,
            wfh: data?.wfh?.aggregate.sum.days
              ? data?.wfh?.aggregate.sum.days
              : 0,
          };
        })
      );
  }

  getProject(projectId: string) {
    return this.apollo.query<any>({
      query: Queries.getProject,
      variables: {
        projectId,
      },
      fetchPolicy: "network-only",
    });
  }

  getProjectDetails(projectId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getProjectDetails,
      variables: {
        projectId,
      },
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  updateProject(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateProject,
      variables,
    });
  }

  removeProjectFromSpace(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateProject,
      variables,
      refetchQueries: [
        {
          query: Queries.getSpaces,

          fetchPolicy: "network-only",
        },
      ],
    });
  }

  updateClient(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateClient,
      variables,
      fetchPolicy: "network-only",
    });
  }
  getAllProjects(variables) {
    return this.apollo.watchQuery<any>({
      query: Queries.getAllProjects,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getClientCounts(clientId: string, userId: string) {
    return this.apollo.query<any>({
      query: Queries.getClientCounts,
      variables: {
        clientId,
        userId,
      },
      fetchPolicy: "network-only",
    });
  }

  getClientTasks(variables) {
    return this.apollo.watchQuery<any>({
      query: Queries.getClientTasks,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  addTask(variables = {}, taskParams = {}) {
    if (Object.keys(taskParams).length === 0)
      return this.apollo.mutate<any>({
        mutation: Mutations.addTask,
        variables,
        fetchPolicy: "network-only",
      });
    else
      return this.apollo.mutate<any>({
        mutation: Mutations.addTask,
        variables,
        fetchPolicy: "network-only",
        refetchQueries: [
          {
            query: Queries.getClientTasks,
            variables: taskParams,
            fetchPolicy: "network-only",
          },
        ],
      });
  }

  addTicketTask(variables = {}, taskParams = {}) {
    if (Object.keys(taskParams).length === 0)
      return this.apollo.mutate<any>({
        mutation: Mutations.addTicketTask,
        variables,
        fetchPolicy: "network-only",
      });
    else
      return this.apollo.mutate<any>({
        mutation: Mutations.addTicketTask,
        variables,
        fetchPolicy: "network-only",
        refetchQueries: [
          {
            query: Queries.getClientTasks,
            variables: taskParams,
            fetchPolicy: "network-only",
          },
        ],
      });
  }

  getProjectList(clientId: string) {
    return this.apollo.query<any>({
      query: Queries.getProjectList,
      variables: {
        clientId,
      },
      fetchPolicy: "network-only",
    });
  }

  getTask(taskId: string, userId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getTask,
      variables: {
        taskId,
        userId,
      },
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getClientTask(taskId: string, userId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getClientTask,
      variables: {
        taskId,
        userId,
      },
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  editClient(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.editClient,
      variables,
      fetchPolicy: "network-only",
    });
  }

  addComment(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addComment,
      variables,
    });
  }

  UpdateComment(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.UpdateComment,
      variables,
    });
  }

  getEmployeeProjects(variables = {}) {
    return this.apollo.watchQuery<any>({
      query: Queries.getEmployeeProjects,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getMyTasks(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getMyTasks,
      variables,
      fetchPolicy: "network-only",
    });
  }

  startTaskTimer(object = {}, taskId: string, userId: string) {
    return this.apollo.mutate<any>({
      mutation: Mutations.startTaskTimer,
      variables: {
        object,
      },
      refetchQueries: [
        {
          query: Queries.getTask,
          variables: {
            taskId,
            userId,
          },
        },
      ],
    });
  }

  updateTaskTimer(variables, taskId: string, userId: string) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateTaskTimer,
      variables,
      refetchQueries: [
        {
          query: Queries.getTask,
          variables: {
            taskId,
            userId,
          },
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  getPinnedProjectData(projectId) {
    return this.apollo.query<any>({
      query: Queries.getPinnedProjectData,
      variables: {
        projectId,
      },
    });
  }

  getClientDashboard(variables) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getClientDashboard,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return {
            tableData: data.tableData,
            cardData: data.cardData,
            pinMapping: data.task_manager_project_pin_mapping,
            projects: data.projects,
            myTasks: data.myTasks,
            onHold: data.onHold,
            closedTasks: data.closedTasks,
          };
        })
      );
  }

  getEmployeeRoles() {
    return this.apollo.query<any>({
      query: Queries.getEmployeeRoles,
    });
  }

  getAdminTasks(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getAdminTasks,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return {
            data: data.task_manager_tasks,
            length: data.totalTasks.aggregate.count,
            filters: data.filters,
          };
        })
      );
  }

  getAdminTask(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getAdminTask,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(map(({ data }) => data.task_manager_tasks[0]));
  }

  getTaskStatus(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getTaskStatus,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(map(({ data }) => data.task_manager_status));
  }

  listOfProjects(variables) {
    return this.apollo
      .query<any>({
        query: Queries.listOfProjects,
        variables,
        fetchPolicy: "network-only",
      })
      .pipe(map(({ data }) => data.task_manager_projects));
  }

  addNewTask(variables) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addNewTask,
      variables,
    });
  }

  updateTask(variables, refetchVarsEmp = {}, refetchVarsAdmin = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateTask,
      variables,
      refetchQueries: [
        Object.keys(refetchVarsEmp).length > 0 && {
          query: Queries.getTask,
          variables: refetchVarsEmp,
          fetchPolicy: "network-only",
        },
        Object.keys(refetchVarsAdmin).length > 0 && {
          query: Queries.getAdminTask,
          variables: refetchVarsAdmin,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  getAdminDashboard(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getAdminDashboard,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return {
            clients: data.clients.aggregate.count,
            employees: data.employees.aggregate.count,
            projects: data.projects.aggregate.count,
            todaysAttendance: data.todaysAttendance.aggregate.count,
            myTasks: data.myTasks,
            overdueTasks: data.overdueTasks,
            taskRequests: data.taskRequests,
            leavesData: data.task_manager_leaves,
            projectsList: data.projectsList,
            pinnedFilters: data.pinnedFilters,
            cardData: data.cardData,
            tableData: data.tableData,
            chartData: data.chartData,
          };
        })
      );
  }

  getEmployeeDashboard(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getEmployeeDashboard,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return {
            myTasks: data.myTasks,
            myTeams: data.myTeams,
            tasks_count: data.task_manager_tasks_aggregate.aggregate.count,
            overdueTasks_count: data.overdueTasks_count.aggregate.count,
            projects_count: data.projects_count.aggregate.count,
            attendanceData: data.task_manager_attendance,
            pinnedFilters: data.pinnedFilters,
            cardData: data.cardData,
            tableData: data.tableData,
            chartData: data.chartData,
          };
        })
      );
  }

  getTaskLog(variables) {
    return this.apollo.query<any>({
      query: Queries.getTaskLog,
      variables,
      fetchPolicy: "network-only",
    });
  }

  getTaskRequests(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getTaskRequest,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return {
            data: data.task_manager_task_requests,
            length: data.requestLength.aggregate.count,
          };
        })
      );
  }

  createTaskRequest(variables) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createTaskRequest,
      variables,
    });
  }

  updateTaskRequest(variables) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateTaskRequest,
      variables,
      refetchQueries: [{ query: Queries.getTaskRequest }],
      fetchPolicy: "network-only",
    });
  }

  getEmployeeLeaves(
    yearFirst: string,
    yearLast: string,
    monthFirst: string,
    limit: number,
    offset: number
  ) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getEmployeeLeaves,
        variables: {
          yearFirst,
          yearLast,
          monthFirst,
          limit,
          offset,
        },
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const currentYear = new Date().getFullYear();

          const length = data.totalLength.aggregate.count;
          const mapData = data.task_manager_users.map((leave) => {
            const joiningDate = new Date(leave.joined_at);
            if (
              joiningDate.getFullYear() === currentYear &&
              leave.casual_leave &&
              leave.sick_leave
            ) {
              return {
                user: {
                  id: leave.external_id,
                  name: leave.lname
                    ? leave.name + " " + leave.lname
                    : leave.name,
                  casual_leave: leave.casual_leave,
                  sick_leave: leave.sick_leave,
                },
                CL: leave.casual_leave - leave.CL.aggregate.sum.days,
                SL: leave.sick_leave - leave.SL.aggregate.sum.days,
                WFH: environment.leave.WFH - leave.WFH.aggregate.sum.days,
                Rejected: leave.rejected.aggregate.count,
                used: leave.CL.aggregate.sum.days + leave.SL.aggregate.sum.days,
                totalLeave: leave.casual_leave + leave.sick_leave,
              };
            } else {
              return {
                user: {
                  id: leave.external_id,
                  name: leave.lname
                    ? leave.name + " " + leave.lname
                    : leave.name,
                },
                CL: environment.leave.CL - leave.CL.aggregate.sum.days,
                SL: environment.leave.SL - leave.SL.aggregate.sum.days,
                WFH: environment.leave.WFH - leave.WFH.aggregate.sum.days,
                Rejected: leave.rejected.aggregate.count,
                used: leave.CL.aggregate.sum.days + leave.SL.aggregate.sum.days,
                totalLeave: 12,
              };
            }
          });
          return { leavesData: mapData, length };
        })
      );
  }

  createTeam(objects: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createTeam,
      variables: {
        objects,
      },
    });
  }

  getTeams(variables) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getTeams,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(map(({ data }) => data.task_manager_teams));
  }

  getTeamMembers(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getTeamMembers,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return {
            members: data.task_manager_team_members,
            totalMembers:
              data.task_manager_team_members_aggregate.aggregate.count,
          };
        })
      );
  }

  addTeamMembers(objects: any, memberVars = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addTeamMebers,
      variables: {
        objects,
      },
      refetchQueries: [
        { query: Queries.getTeamMembers, variables: memberVars },
      ],
    });
  }

  removeTeamMember(variables, memberVars = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.removeTeamMember,
      variables,
      refetchQueries: [
        {
          query: Queries.getTeamMembers,
          variables: memberVars,
        },
      ],
    });
  }

  addProjectFiles(objects: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addProjectFiles,
      variables: {
        objects,
      },
    });
  }

  getProjectFile(variables: any) {
    return this.apollo
      .query<any>({
        query: Queries.getProjectFile,
        variables,
      })
      .pipe(map(({ data }) => data.task_manager_project_files[0]));
  }

  addTaskFiles(objects: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addTaskFiles,
      variables: {
        objects,
      },
    });
  }

  getTaskFile(variables: any) {
    return this.apollo
      .query<any>({
        query: Queries.getTaskFile,
        variables,
      })
      .pipe(map(({ data }) => data.task_manager_task_files[0]));
  }

  updateClientData(variables: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateClientData,
      variables,
      refetchQueries: [
        { query: Queries.getClients, fetchPolicy: "network-only" },
      ],
    });
  }

  deleteProjectMemberes(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteProjectMemberes,
      variables,
    });
  }

  saveFilter(variables = {}, clientTaskVars = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.saveFilters,
      variables,
      refetchQueries: [
        {
          query: Queries.getClientTasks,
          variables: clientTaskVars,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  deleteFilter(variables = {}, clientTaskVars = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteFilter,
      variables,
      refetchQueries: [
        {
          query: Queries.getClientTasks,
          variables: clientTaskVars,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  // deleteFilter(variables = {}, clientTaskVars = {}, role) {
  //   let refetchQueries = [];
  //   if (role === "admin") {
  //     refetchQueries.push({
  //       query: Queries.getAdminTasks,
  //       variables: clientTaskVars,
  //     });
  //   } else if (role === "client") {
  //     refetchQueries.push({
  //       query: Queries.getClientTasks,
  //       variables: clientTaskVars,
  //     });
  //   } else if (role === "employee") {
  //     refetchQueries.push({
  //       query: Queries.getMyTasks,
  //       variables: clientTaskVars,
  //     });
  //   } else if (role === "project-manager") {
  //     refetchQueries.push({
  //       query: Queries.getMyTasks,
  //       variables: clientTaskVars,
  //     });
  //     return this.apollo.mutate<any>({
  //       mutation: Mutations.deleteFilter,
  //       variables,
  //       refetchQueries,
  //     });
  //   }
  // }

  getAllDocuments(variables = {}) {
    return this.apollo.watchQuery<any>({
      query: Queries.getDocuments,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  addHoliday(variables = {}, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addHoliday,
      variables,
      refetchQueries: [
        {
          query: Queries.getHolidays,
          variables: refetchParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  getHolidays(variables = {}) {
    return this.apollo.watchQuery<any>({
      query: Queries.getHolidays,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  updateHolidays(variables = {}, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateHoliday,
      variables,
      refetchQueries: [
        {
          query: Queries.getHolidays,
          variables: refetchParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  deleteHoliday(variables = {}, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteHoliday,
      variables,
      refetchQueries: [
        {
          query: Queries.getHolidays,
          variables: refetchParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  getFilter(userId: string) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getFilter,
        variables: {
          userId,
        },
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.task_manager_filters;
        })
      );
  }

  addDocument(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addDocument,
      variables,
    });
  }

  addDocumentsMany(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addDocumentsMany,
      variables,
    });
  }

  deleteTaskFiles(variables = {}, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteTaskFiles,
      variables,
      refetchQueries: [
        {
          query: Queries.getAdminTask,
          variables: refetchParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  deleteProjectFiles(variables = {}, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteProjectFiles,
      variables,
      refetchQueries: [
        {
          query: Queries.getProjectDetails,
          variables: refetchParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  deleteDocument(variables = {}, userId: string = "", refetchDocsParams = {}) {
    if (userId)
      return this.apollo.mutate<any>({
        mutation: Mutations.deleteDocument,
        variables,
        refetchQueries: [
          {
            query: Queries.getUser,
            variables: {
              userId,
            },
            fetchPolicy: "network-only",
          },
        ],
      });
    else if (refetchDocsParams)
      return this.apollo.mutate<any>({
        mutation: Mutations.deleteDocument,
        variables,
        refetchQueries: [
          {
            query: Queries.getDocuments,
            variables: refetchDocsParams,
            fetchPolicy: "network-only",
          },
        ],
      });
  }

  updatePin(variables, userId: string, dashboardParams, role: string) {
    let dashboardQuery;
    if (role === "admin") {
      dashboardQuery = Queries.getAdminDashboard;
    } else if (role === "client") dashboardQuery = Queries.getClientDashboard;
    else dashboardQuery = Queries.getEmployeeDashboard;

    return this.apollo.mutate<any>({
      mutation: Mutations.updatePin,
      variables,
      refetchQueries: [
        {
          query: Queries.getFilter,
          variables: {
            userId,
          },
          fetchPolicy: "network-only",
        },
        {
          query: dashboardQuery,
          variables: dashboardParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  getTaskForDasboardPin(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getTaskForDasboardPin,
      variables,
      fetchPolicy: "network-only",
    });
  }

  addLog(variables) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addLog,
      variables,
    });
  }

  getSpaces(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getSpaces,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return {
            spaces: data.task_manager_spaces,
            totalspaces: data.task_manager_spaces_aggregate.aggregate.count,
          };
        })
      );
  }

  addSpaces(variables = {}, refetchVariables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addSpaces,
      variables,
      refetchQueries: [
        {
          query: Queries.getSpaces,
          variables: refetchVariables,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  addExistProjectToSpace(updates: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addExistProjectToSpace,
      variables: {
        updates,
      },
      refetchQueries: [
        {
          query: Queries.getSpaces,

          fetchPolicy: "network-only",
        },
      ],
    });
  }

  getSpaceMembers(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getSpaceMembers,
        variables,

        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return {
            members: data.task_manager_space_members,
            totalMembers:
              data.task_manager_space_members_aggregate.aggregate.count,
          };
        })
      );
  }

  addMembersToSpace(
    objects: any,
    refetchMembersVariables = {},
    refetchSpaceParams = {}
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addSpaceMembers,
      variables: {
        objects,
      },
      refetchQueries: [
        Object.keys(refetchMembersVariables).length > 0 && {
          query: Queries.getSpaceMembers,
          variables: refetchMembersVariables,
        },
        Object.keys(refetchSpaceParams).length > 0 && {
          query: Queries.getSpaces,
          variables: refetchSpaceParams,
        },
      ],
    });
  }

  removeMemberFromSpace(variables, memberVars = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.removeMemberFromSpace,
      variables,
      refetchQueries: [
        {
          query: Queries.getSpaceMembers,
          variables: memberVars,
        },
      ],
    });
  }

  fetchAttendanceWithUserData(variables) {
    return this.apollo.query<any>({
      query: Queries.fetchAttendanceWithUserData,
      variables,
      fetchPolicy: "network-only",
    });
  }

  getStatusOfType(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getStatusOfType,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => data.task_manager_project_type_status)
      );
  }

  updateProjectTypeStatus(variables, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateProjectTypeStatus,
      variables,
      refetchQueries: [
        { query: Queries.getStatusOfType, variables: refetchParams },
      ],
    });
  }

  createNewStatus(object) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createNewStatus,
      variables: {
        object,
      },
    });
  }

  addProjectTypeStatus(object, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addProjectTypeStatus,
      variables: {
        object,
      },
      refetchQueries: [
        { query: Queries.getStatusOfType, variables: refetchParams },
      ],
    });
  }

  addProjectTypesStatusMany(objects) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addProjectTypeStatusMany,
      variables: {
        objects,
      },
    });
  }

  deleteProjectTypeStatus(variables, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteProjectTypeStatus,
      variables,
      refetchQueries: [
        { query: Queries.getStatusOfType, variables: refetchParams },
      ],
    });
  }

  addProjectType(object, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addProjectType,
      variables: { object },
      refetchQueries: [
        { query: Queries.getProjectTypes, variables: refetchParams },
      ],
    });
  }

  deleteLeave(variables, refetchVars) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteLeave,
      variables,
      refetchQueries: [{ query: Queries.getLeaves, variables: refetchVars }],
    });
  }

  updateTheme(variables) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateTheme,
      variables,
    });
  }

  insertTheme(object) {
    return this.apollo.mutate<any>({
      mutation: Mutations.insertTheme,
      variables: { object },
    });
  }

  getTheme(variables) {
    return this.apollo.query<any>({
      query: Queries.getTheme,
      variables,
      fetchPolicy: "cache-first",
    });
  }

  addNotification(object) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addNotification,
      variables: { object },
    });
  }

  addNotificationRecipients(objects) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addNotificationRecipients,
      variables: { objects },
    });
  }

  getNotification(variables) {
    return this.apollo.watchQuery<any>({
      query: Queries.getNotification,
      variables,

      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getTaskLogForReport(variables) {
    return this.apollo.watchQuery<any>({
      query: Queries.getTaskLogForReport,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  readNotification(variables, refetchVars = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.readNotification,
      variables,
      refetchQueries: [
        { query: Queries.getNotification, variables: refetchVars },
      ],
    });
  }

  getNotificationsSubscription(variables) {
    return this.apollo.subscribe<any>({
      query: Subscriptions.getNotificationsSubscription,
      variables,
    });
  }

  getCommentsByTaskIdSubscription(variables) {
    return this.apollo.subscribe<any>({
      query: Subscriptions.getCommentsByTaskIdSubscription,
      variables,
    });
  }

  fetchAttendanceAndLeavesForReport(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.fetchAttendanceAndLeavesForReport,
      variables,
      fetchPolicy: "network-only",
    });
  }

  updateSpace(variables) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateSpace,
      variables,
    });
  }

  deleteNotification(variables, refetchVars) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteNotification,
      variables,
      refetchQueries: [
        { query: Queries.getNotification, variables: refetchVars },
      ],
    });
  }

  getTasksForProjectPage(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getTasksForProjectPage,
      variables,
      fetchPolicy: "network-only",
    });
  }

  deleteTeam(variables, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteTeam,
      variables,
      refetchQueries: [{ query: Queries.getTeams, variables: refetchParams }],
    });
  }

  getTeam(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getTeam,
      variables,
    });
  }

  updateTeam(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateTeam,
      variables,
    });
  }

  createStatusSettings(object) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createStatusSettings,
      variables: { object },
    });
  }

  getAllStatusFromProjectType(variables = {}) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getAllStatusFromProjectType,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.task_manager_project_type_status.map((stat) => {
            return stat.statusSettings.status_settings_status;
          });
        })
      );
  }

  addCommentFiles(objects) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addCommentFiles,
      variables: { objects },
    });
  }

  getCommentFile(variables) {
    return this.apollo.query<any>({
      query: Queries.getCommentFile,
      variables,
    });
  }

  getUsersLeaves(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getUsersLeaves,
      variables,
    });
  }

  getDashboardType(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getDashboardType,
      variables,
    });
  }

  addCustomDashboardData(objects, refetchParams = {}, role) {
    let refetchQueries = [];

    if (role === "admin") {
      refetchQueries.push({
        query: Queries.getAdminDashboard,
        variables: refetchParams,
      });
    } else if (role === "client") {
      refetchQueries.push({
        query: Queries.getClientDashboard,
        variables: refetchParams,
      });
    } else if (role === "employee") {
      refetchQueries.push({
        query: Queries.getEmployeeDashboard,
        variables: refetchParams,
      });
    } else if (role === "project-manager") {
      refetchQueries.push({
        query: Queries.getEmployeeDashboard,
        variables: refetchParams,
      });
    }

    return this.apollo.mutate<any>({
      mutation: Mutations.addCustomDashboardData,
      variables: { objects },
      refetchQueries,
    });
  }

  getFilteredTaskCount(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getFilteredTaskCount,
      variables,
    });
  }

  deleteSpace(variables = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteSpace,
      variables,
    });
  }

  removeCustomDashboardItem(variables, refetchParams = {}, role) {
    let refetchQueries = [];

    if (role === "admin") {
      refetchQueries.push({
        query: Queries.getAdminDashboard,
        variables: refetchParams,
      });
    } else if (role === "client") {
      refetchQueries.push({
        query: Queries.getClientDashboard,
        variables: refetchParams,
      });
    } else if (role === "employee") {
      refetchQueries.push({
        query: Queries.getEmployeeDashboard,
        variables: refetchParams,
      });
    } else if (role === "project-manager") {
      refetchQueries.push({
        query: Queries.getEmployeeDashboard,
        variables: refetchParams,
      });
    }

    return this.apollo.mutate<any>({
      mutation: Mutations.removeCustomDashboardItem,
      variables,
      refetchQueries,
    });
  }

  getTaskLogsForCharts(variables) {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getTaskLogsForCharts,
        variables,
        fetchPolicy: "network-only",
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.task_manager_task_logs;
        })
      );
  }

  deleteEmployee(variables, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.deleteUser,
      variables,
      refetchQueries: [
        { query: Queries.getEmployees, variables: refetchParams },
      ],
    });
  }

  getTodayAttendanceData(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getTodayAttendanceData,
      variables,
    });
  }

  addExtensionLimit(objects) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addExtensionLimit,
      variables: { objects },
    });
  }

  loadUniversalTasks(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.loadUniversalTasks,
      variables,
    });
  }

  getAttendanceIds(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getAttendanceIds,
      variables,
    });
  }

  createTicket(ticketInput) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createTicket,
      variables: { input: ticketInput }, // Correct variable name 'input'
    });
  }

  getAlertsAndWarning(variables = {}) {
    return this.apollo.query<any>({
      query: Queries.getAlertsAndWarning,
      variables,
    });
  }

  closeTicket(variables = {}, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.closeTicket,
      variables: variables, // Corrected: Move variables here
      refetchQueries: [
        {
          query: Queries.getAlertsAndWarning,
          variables: refetchParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }

  updateTicketStatus(variables = {}, refetchParams = {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateTicketStatus,
      variables: variables,
      refetchQueries: [
        {
          query: Queries.getAlertsAndWarning,
          variables: refetchParams,
          fetchPolicy: "network-only",
        },
      ],
    });
  }
  getLeavesData(variables) {
    return this.apollo.watchQuery<any>({
      query: Queries.getLeavesData,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  // getTasksByProject(variables = {}) {
  //   return this.apollo.query<any>({
  //     query: Queries.getTasksByProject,
  //     variables,
  //   });
  // }
}
