import { Role } from "./../../core/models/role";
import { UserData } from "./../../core/models/users";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/config/config.service";
import { AuthService } from "src/app/core/service/auth.service";
import { LanguageService } from "src/app/core/service/language.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { AwsService } from "src/app/core/service/aws.service";
import { GraphqlService } from "src/app/core/service/graphql.service";
import { Subscription, interval } from "rxjs";
import { SharedService } from "src/app/shared/shared.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { TicketsDialogComponent } from "src/app/shared/components/tickets-dialog/tickets-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";

const document: any = window.document;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  public config: any = {};
  readNotifications = [];
  allNotifications = false;
  readNotificationSubscription: Subscription;

  userImg: string;
  homePage: string;
  isNavbarCollapsed = true;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  userRole: any;
  // user: any;
  user: UserData;
  profilePic: string;
  // notificationId: string;
  notificationEmpty = false;
  readNotificationEmpty = false;
  readNotificationsPaginator: MatPaginator;
  length: any;
  isLoading = false;
  alerts = [];
  existsInMailingList = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private awsService: AwsService,
    private graphql: GraphqlService,
    public dialog: MatDialog,
    private shared: SharedService,
    private snackBar: MatSnackBar
  ) {
    super();
  }
  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
  ];
  notifications: any[] = [];
  notificationCount: number;

  ngOnInit() {
    this.user = <UserData>JSON.parse(localStorage.getItem("user"));
    this.getNotification();
    this.getReadNotifications();
    this.notificationSub();
    this.getProfilePic();
    if (this.user.role == "SUPER_ADMIN" || this.user.role == "ADMIN") {
      this.userRole = Role.Admin;
    } else if (
      this.user.role == "STUDYMEDICIAN" ||
      this.user.role === "CLIENT"
    ) {
      this.userRole = Role.Client;
    } else if (this.user.role == "BEETLER") {
      this.userRole = Role.Employee;
    } else if (this.user.role == "PROJECT_MANAGER") {
      this.userRole = Role.PM;
    } else if (this.user.role == "TEAM_LEAD") {
      this.userRole = Role.team_lead;
    } else {
      this.userRole = Role.Admin;
    }
    this.getConfig();
    // this.config = this.configService.configData;
    // const userRole = this.authService.currentUserValue.role;
    // this.userImg = this.authService.currentUserValue.img;

    if (this.userRole === "Admin") {
      this.homePage = "admin/dashboard";
    } else if (this.userRole === "Client") {
      this.homePage = "client/dashboard";
    } else if (
      this.userRole === "Employee" ||
      this.userRole === "PM" ||
      this.userRole === "Team Lead"
    ) {
      this.homePage = "employee/dashboard";
    } else {
      this.homePage = "admin/dashboard";
    }

    this.langStoreValue = localStorage.getItem("lang");
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    // this.getAlertsAndWarning();
    // this.getNotificationCount();
    interval(2 * 60 * 60 * 1000).subscribe(() => {
      this.getAlertsAndWarning(); // Call the function again after one minute
    });
  }

  getConfig() {
    this.configService.getConfigData().subscribe((data) => {
      this.config = data;
    });
  }

  getProfilePic() {
    const key = { key: this.user.profile_pic_url, isProfile: true };
    if (!key.key) return;
    this.awsService.getSignedURL(key).subscribe((data) => {
      this.profilePic = data;
    });
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(
        this.document.body,
        this.config?.layout.variant
      );
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, this.config?.layout.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "menu_" + this.config?.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "logo-" + this.config?.layout.logo_bg_color
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config?.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }

  // web socket connection for notification Subscription
  notificationSub() {
    const notificationParams = {
      where: {
        recipients: {
          recipient_id: { _eq: this.user.external_id },
          is_read: { _eq: false },
        },
      },
    };
    this.graphql.getNotificationsSubscription(notificationParams).subscribe({
      next: ({ data }) => {
        this.notifications = data.task_manager_notifications;
        if (this.notifications.length === 0) this.notificationEmpty = true;
        this.notificationCount = this.notifications.length;
      },
    });
  }

  // for initial render in case web socket fails
  getNotification() {
    const notificationParams = {
      where: {
        recipients: {
          recipient_id: { _eq: this.user.external_id },
          is_read: { _eq: false },
        },
      },
    };

    this.graphql.getNotification(notificationParams).subscribe(({ data }) => {
      this.notifications = data.task_manager_notifications;

      this.notificationCount = this.notifications.length;

      if (this.notifications.length === 0) this.notificationEmpty = true;
    });
  }

  getReadNotifications(limit = 10, offset = 0) {
    const Params = {
      limit,
      offset,
      where: {
        recipients: {
          recipient_id: { _eq: this.user.external_id },
          is_read: { _eq: true },
        },
      },
    };

    this.readNotificationSubscription = this.graphql
      .getNotification(Params)
      .subscribe(({ data }) => {
        this.readNotifications = data.task_manager_notifications;
        this.length = data.task_manager_notifications_aggregate.aggregate.count;
        if (this.length === 0) this.readNotificationEmpty = true;
        else this.readNotificationEmpty = false;
      });
  }

  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }

  readAllNotification() {
    if (!this.user.external_id) {
      return;
    }
    const readAllParams = {
      where: {
        recipient_id: { _eq: this.user.external_id },
        is_read: { _eq: false },
      },
      _set: {
        is_read: true,
      },
    };
    const refetchParams = {
      limit: 10,
      offset: 0,
      where: {
        recipients: {
          recipient_id: { _eq: this.user.external_id },
          is_read: { _eq: true },
        },
      },
    };

    this.graphql.readNotification(readAllParams, refetchParams).subscribe({
      next: ({ data }) => {},
    });
  }

  getIconClass(type: string): string {
    switch (type) {
      case "task":
        return "text-primary";
      case "space":
        return "text-dark";
      case "hr":
        return "text-warning";
      case "project":
        return "text-success";
      case "leave":
        return "text-danger";
      case "employee":
        return "text-dark";
      case "team":
        return "text-dark";
      case "report":
        return "text-dark";
      case "attendance":
        return "text-secondary";

      default:
        return "";
    }
  }

  deleteNotification(notificationId: string) {
    this.isLoading = true;
    if (!notificationId) {
      return;
    }
    const deleteParams = {
      where: {
        recipient_id: { _eq: this.user.external_id },
        notification_id: { _eq: notificationId },
        is_read: { _eq: true },
      },
    };

    const refetchParams = {
      limit: 10,
      offset: 0,
      where: {
        recipients: {
          recipient_id: { _eq: this.user.external_id },
          is_read: { _eq: true },
        },
      },
    };
    this.graphql
      .deleteNotification(deleteParams, refetchParams)
      .subscribe((data) => {
        this.isLoading = false;
      });
  }

  deleteAllReadNotification() {
    this.isLoading = true;
    if (!this.user.external_id) {
      return;
    }
    const deleteParams = {
      where: {
        recipient_id: { _eq: this.user.external_id },
        is_read: { _eq: true },
      },
    };
    const refetchParams = {
      limit: 10,
      offset: 0,
      where: {
        recipients: {
          recipient_id: { _eq: this.user.external_id },
          is_read: { _eq: true },
        },
      },
    };
    this.graphql.deleteNotification(deleteParams, refetchParams).subscribe({
      next: (data) => {
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  toggleToAllNotifications(data: MatTabChangeEvent) {
    if (data.index === 1) {
      this.allNotifications = true;
    } else this.allNotifications = false;
  }

  getNext(event: any) {
    const offset = event.pageSize * event.pageIndex;
    const limit = event.pageSize;
    this.getReadNotifications(limit, offset);
  }

  readNotification(notificationId: string) {
    if (!notificationId) {
      return;
    }
    const setParams = {
      where: {
        notification_id: {
          _eq: notificationId,
        },
        recipient_id: { _eq: this.user.external_id },
      },
      _set: {
        is_read: true,
      },
    };

    const refetchParams = {
      where: {
        recipients: {
          recipient_id: { _eq: this.user.external_id },
          is_read: { _eq: true },
        },
      },
    };

    this.graphql
      .readNotification(setParams, refetchParams)
      .subscribe({ next: ({ data }) => {} });
  }

  openTicketDialog() {
    const dialogRef = this.dialog.open(TicketsDialogComponent, {
      height: "550px",
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  // getAlertsAndWarning() {
  //   const params = {
  //     where: {
  //       status: { _nin: ["closed"] },
  //     },
  //   };

  //   this.graphql.getAlertsAndWarning(params).subscribe((data) => {

  //     console.log("alerts",data);

  //     const alertsAndWarnings = data.data.task_manager_tickets;
  //     const alerts = alertsAndWarnings.filter(
  //       (alert) => alert.type === "alert"
  //     );
  //     const warnings = alertsAndWarnings.filter(
  //       (alert) => alert.type === "warning"
  //     );

  //     // Define a delay for each notification
  //     let delay = 2000; // Initial delay of 2 seconds

  //     // Display alerts after initial delay
  //     alerts.forEach((alert) => {
  //       setTimeout(() => {
  //         this.showNotification(
  //           alert.type === "alert" ? "snackbar-danger" : "snackbar-secondary",
  //           `${alert.type === "alert" ? "Alert!" : "Warning!"}-${alert.title}`,
  //           "top",
  //           "center",
  //           true,
  //           alert.type
  //         );
  //       }, delay);
  //       delay += 3000; // Increase delay for the next notification
  //     });

  //     // Display warnings after delay
  //     warnings.forEach((warning) => {
  //       setTimeout(() => {
  //         this.showNotification(
  //           warning.type === "warning"
  //             ? "snackbar-secondary"
  //             : "snackbar-warning",
  //           `${warning.type === "alert" ? "Warning!" : "Alert!"}-${
  //             warning.title
  //           }`,
  //           "top",
  //           "center",
  //           true,
  //           warning.type
  //         );
  //       }, delay);
  //       delay += 3000; // Increase delay for the next notification
  //     });

  //     console.log(alertsAndWarnings);
  //   });
  // }



  getAlertsAndWarning() {
    const params = {
      where: {
        status: { _nin: ["closed"] },
      },
    };

    this.graphql.getAlertsAndWarning(params).subscribe((data) => {

      const alertsAndWarnings = data.data.task_manager_tickets;
      const userExternalId = this.user.external_id;

      const userAlerts = alertsAndWarnings.filter(
        (alert) =>
          alert.type === "alert" &&
          alert.project.mailing_lists?.some(
            (mailingList) => mailingList.user.external_id === userExternalId
          )
      );

      const userWarnings = alertsAndWarnings.filter(
        (alert) =>
          alert.type === "warning" &&
          alert.project.mailing_lists?.some(
            (mailingList) => mailingList.user.external_id === userExternalId
          )
      );

      let delay = 2000; // Initial delay of 2 seconds

      userAlerts.forEach((alert) => {
        setTimeout(() => {
          this.showNotification(
            "snackbar-danger",
            `Alert! - ${alert.title}`,
            "top",
            "center",
            true,
            alert.type
          );
        }, delay);
        delay += 3000; // Increase delay for the next notification
      });

      // Display user warnings
      userWarnings.forEach((warning) => {
        setTimeout(() => {
          this.showNotification(
            "snackbar-secondary",
            `Warning! - ${warning.title}`,
            "top",
            "center",
            true,
            warning.type
          );
        }, delay);
        delay += 3000; // Increase delay for the next notification
      });

      console.log(alertsAndWarnings);
    });
  }

  private showNotification(
    panelClass: string,
    message: string,
    verticalPosition: any,
    horizontalPosition: any,
    action: boolean,
    alertType: string // Add alertType parameter
  ): void {
    const snackBarRef = this.snackBar.open(
      message,
      action ? "Close" : undefined,
      {
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition,
        panelClass: panelClass,
      }
    );

    if (alertType === "alert") {
      this.playSound();
    }

    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  playSound() {
    const audio = new Audio("assets/audio/alertnew.wav");
    audio.play();
  }
}
