import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  constructor(private http: HttpClient) {}

  sendPushNotification(
    recipients: string[],
    title: string,
    content: string,
    
  ) {
   // console.log(recipients, title, content);

    return this.http.post<any>(`${environment.nodeUrl}os/send-push`, {
      recipients,
      title,
      content,
    });
  }
}
