import { Pipe, PipeTransform } from "@angular/core";
import { SharedService } from "../shared.service";

@Pipe({
  name: "formatTime",
})
export class FormatTimePipe implements PipeTransform {
  constructor(private shared: SharedService) {}

  transform(value: number): string {
    if (value === 0) return null;
    const formatedTime = this.shared.formatDifference(value);
    return `${formatedTime.hrs} hr : ${formatedTime.mins} min : ${formatedTime.secs} sec`;
  }
}
