import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: "app-ticket-info",
  templateUrl: "./ticket-info.component.html",
  styleUrls: ["./ticket-info.component.css"],
})
export class TicketInfoComponent implements OnInit {
  selectedItem=[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: { item: any }) {}

  ngOnInit(): void {
    console.log(this.data.item);
    this.selectedItem = [this.data.item];
  }
}
