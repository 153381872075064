<div class="settingSidebar" [ngClass]="isOpenSidebar ? 'showSettingPanel' : ''">
  <a
    href="javascript:void(0)"
    class="settingPanelToggle"
    (click)="toggleRightSidebar()"
  >
    <app-feather-icons
      [icon]="'settings'"
      [class]="'setting-sidebar-icon'"
    ></app-feather-icons>
  </a>
  <div
    class="settingSidebar-body ps-container ps-theme-default"
    [ngStyle]="{
      position: 'relative',
      'max-height': maxHeight + 'px',
      'max-width': maxWidth + 'px'
    }"
    [perfectScrollbar]
  >
    <div class="fade show active">
      <div class="setting-panel-header">Setting Panel</div>
      <div class="p-15 border-bottom">
        <h6 class="font-medium m-b-10">Select Layout</h6>
        <div class="flex flex-wrap hiddenradio">
          <div class="flex flex-col">
            <label>
              <input
                type="radio"
                name="value"
                value="light"
                [checked]="isDarTheme == false ? true : false"
                (click)="lightThemeBtnClick()"
              />
              <img src="assets/images/light.png" />
            </label>
            <div class="mt-1 text-md text-center">Light</div>
          </div>
          <div class="flex flex-col mt-3">
            <label>
              <input
                type="radio"
                name="value"
                value="dark"
                [checked]="isDarTheme == true ? true : false"
                (click)="darkThemeBtnClick()"
              />
              <img src="assets/images/dark.png" />
            </label>
            <div class="mt-1 text-md text-center">Dark</div>
          </div>
        </div>
      </div>
      <div class="rightSetting">
        <h6 class="font-medium m-b-10">Sidebar Menu Color</h6>
        <mat-button-toggle-group
          class="mt-2"
          [value]="isDarkSidebar ? 'dark' : 'light'"
        >
          <mat-button-toggle (click)="lightSidebarBtnClick()" value="light"
            >Light</mat-button-toggle
          >
          <mat-button-toggle (click)="darkSidebarBtnClick()" value="dark"
            >Dark</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="p-15 border-bottom d-none">
        <h6 class="font-medium m-b-10">Color Theme</h6>
        <div class="theme-setting-options">
          <ul class="choose-theme list-unstyled mb-0">
            <li
              data-theme="white"
              [ngClass]="{ active: selectedBgColor === 'white' }"
              (click)="selectTheme('white')"
            >
              <div class="white"></div>
            </li>
            <li
              data-theme="black"
              [ngClass]="{ active: selectedBgColor === 'black' }"
              (click)="selectTheme('black')"
            >
              <div class="black"></div>
            </li>
            <li
              data-theme="purple"
              [ngClass]="{ active: selectedBgColor === 'purple' }"
              (click)="selectTheme('purple')"
            >
              <div class="purple"></div>
            </li>
            <li
              data-theme="orange"
              [ngClass]="{ active: selectedBgColor === 'orange' }"
              (click)="selectTheme('orange')"
            >
              <div class="orange"></div>
            </li>
            <li
              data-theme="cyan"
              [ngClass]="{ active: selectedBgColor === 'cyan' }"
              (click)="selectTheme('cyan')"
            >
              <div class="cyan"></div>
            </li>
            <li
              data-theme="green"
              [ngClass]="{ active: selectedBgColor === 'green' }"
              (click)="selectTheme('green')"
            >
              <div class="green"></div>
            </li>
            <li
              data-theme="blue"
              [ngClass]="{ active: selectedBgColor === 'blue' }"
              (click)="selectTheme('blue')"
            >
              <div class="blue"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="rightSetting">
        <h6 class="font-medium m-b-10">RTL Layout</h6>
        <mat-slide-toggle
          class="mt-2"
          [checked]="isRtl"
          (change)="switchDirection($event)"
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
