<div class="file-drop-area">
  <button mat-raised-button color="primary">Choose file</button>
  <span> or drag and drop file here </span>
  <input
    class="file-input"
    type="file"
    [multiple]="isMultiple"
    [accept]="accept"
    #fileInput
  />
  <mat-error class="mt-2" *ngIf="fileSizeExceeded">
    Cannot upload files with size greater than 5MB.
  </mat-error>
  <div class="file-list file-upload-wrap">
    <div *ngFor="let file of fileList; let i = index" class="file-container">
      <button type="button" (click)="removeFile(i)" mat-mini-fab>
        <mat-icon>delete</mat-icon>
      </button>
      <small>{{ file.name }}</small>
    </div>

    <!-- <button
      *ngIf="fileList?.length > 0"
      class="upload-btn"
      mat-raised-button
      color="accent"
      type="button"
      (click)="uploadFiles()"
    >
      Upload
    </button> -->
  </div>
</div>
<app-mat-spinner-overlay
  *ngIf="isLoading"
  [overlay]="true"
></app-mat-spinner-overlay>
