import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SharedService } from "../../shared.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-alert-dialog",
  templateUrl: "./alert-dialog.component.html",
  styleUrls: ["./alert-dialog.component.css"],
})
export class AlertDialogComponent implements OnInit {
  alertData: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shared: SharedService
  ) {
    this.alertData.push(data.data);
    const hasAlertType = this.alertData.some((item) => item.type === "alert");
    if (hasAlertType) {
      this.playSound();
    }
  }

  ngOnInit(): void {}

  createEmergencyAlert(): void {
    const alertMessage = "Emergency Alert: [Your Message]";
    this.shared.showAlert(alertMessage);
  }
  onClose(): void {
    this.dialogRef.close();
  }

  playSound() {
    const audio = new Audio("assets/audio/alert.mp3");
    audio.play();
  }
}
