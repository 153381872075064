import { gql } from "apollo-angular";

export const Subscriptions = {
  getNotificationsSubscription: gql`
    subscription getNotificationsSubscription(
      $where: task_manager_notifications_bool_exp! = {}
    ) {
      task_manager_notifications(
        where: $where
        order_by: { created_at: desc }
      ) {
        id
        creator
        created_at
        content
        is_all_users
        title
        type
        updated_at
        type_id
      }
    }
  `,

  getCommentsByTaskIdSubscription: gql`
    subscription getCommentsByTaskIdSubscription(
      $where: task_manager_comments_bool_exp! = {}
    ) {
      task_manager_comments(where: $where, order_by: { created_at: desc }) {
        user {
          name
          external_id
          profile_pic_url
        }
        comment
        client_visibility
        id
        user_id
        created_at
        url
        files(order_by: { created_at: asc }) {
          id
          title
          type
          url
        }
      }
    }
  `,
};
