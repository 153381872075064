import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../service/auth.service";

@Injectable({
  providedIn: "root",
})
export class EmployeeGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(["/authentication/signin"]);
      return false;
    } else {
      if (
        this.authService.loggedUser() == "employee" ||
        this.authService.loggedUser() == "project-manager"||
        this.authService.loggedUser() == "team_lead"
      ) {
        return true;
      }
      this.router.navigate([`/employee/dashboard`]);

      return false;
    }
  }
  constructor(private authService: AuthService, private router: Router) {}
}
