import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserData } from "src/app/core/models/users";
import { GraphqlService } from "src/app/core/service/graphql.service";
import { SharedService } from "../../shared.service";
import { MatSnackBar } from "@angular/material/snack-bar";

export interface TicketStatusTypes {
  type: string;
  value: string;
}
@Component({
  selector: "app-ticket-status-dialog",
  templateUrl: "./ticket-status-dialog.component.html",
  styleUrls: ["./ticket-status-dialog.component.sass"],
})
export class TicketStatusDialogComponent implements OnInit {
  ticketStatusTypes: TicketStatusTypes[] = [
    { type: "Open", value: "open" },
    { type: "In Progress", value: "in_progress" },
    { type: "Resolved", value: "resolved" },
    { type: "Closed", value: "closed" },
  ];
  selectedStatus: string;
  statusForm: FormGroup;

  user: UserData;
  constructor(
    public dialogRef: MatDialogRef<TicketStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private graphql: GraphqlService,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.user = <UserData>JSON.parse(localStorage.getItem("user"));
    console.log(this.data);
    this.selectedStatus = this.data.status;

    this.statusForm = this.formBuilder.group({
      ticketStatus: [this.selectedStatus, Validators.required],
    });
  }

  onSubmit() {
    const selectedStatus = this.statusForm.get("ticketStatus").value;
    const params = {
      where: {
        id: {
          _eq: this.data.id,
        },
      },
      _set: {
        status: selectedStatus,
      },
    };

    const refetchParams = {
      where: {
        is_closed: { _eq: false },
      },
    };
    this.graphql.updateTicketStatus(params, refetchParams).subscribe((data) => {
      this.dialogRef.close("success");
      this.shared.showNotification(
        "snackbar-success",
        "Ticket Status changed successfully!!",
        "bottom",
        "center"
      );
    });
  }

  showNotification(
    colorName: string,
    text: string,
    placementFrom: any,
    placementAlign: any
  ) {
    this.snackBar.open(text, "", {
      duration: 3500,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  shouldShowStatus(statusType: any): boolean {
    if (this.user.dbRole == "employee" && statusType.value === "closed") {
      return false;
    }
    return true;
  }
}
